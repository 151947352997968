import { Link } from 'react-router-dom';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg';
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";
import { FormControl, TextField } from '@mui/material';
import B2BApiService from '../../B2bApiService';
import * as Constants from './../../constants'
import EmailIcon from "@mui/icons-material/Email";
import { Toast } from 'primereact/toast';
import Loading from '../../components/reusableElementsComponents/Loading';
import Button from '../../components/reusableElementsComponents/Button';
import { InputAdornment } from '@mui/material';
import MobileAuthHeader from '../../components/layoutComponents/authComponents/MobileAuthHeader';
import DesktopAuthDecoration from '../../components/layoutComponents/authComponents/DesktopAuthDecoration';


function RecoverPassword() {
    let apiObject = new B2BApiService();
    const myToast = useRef(null);
    const showToast = (severityValue, summaryValue, detailValue) => {
        myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }
    const [email, setEmail] = useState('')
    const [disable, setDisable] = useState(true)
    const data = useSelector(state => state.contentfulData.all['Company recover password page']);

    if (data !== undefined) {
        let images = data.images;
        let content = data.fields;

        const onSubmit = () => {
            if (email) {
                apiObject.EmailConfirmation({ email: email }).then(response => {
                    if (response.httpStatus === 200) {
                        showToast('success', 'Success Message', 'A confirmation mail has been sent successfully.')
                        setTimeout(() => {
                            window.location.href = "/"
                        }, 2000)
                    }
                })
            }

        }

        const handleBlur = () => {
            let valid = Constants.VALID_EMAIL.test(email)
            if (valid) {
                setDisable(false)
            }
            else {
                setDisable(true)
            }
        }

        return (
            <div className="row auth-page-wrapper p-0 m-0">
                <img className='company-logo' src={mazharLogo} alt="Apex Analytics" />
                <MobileAuthHeader logo={true} secondaryTitle={content.titleMobile} subTitle={content.subTitleMobile} />
                <div className="col-12 col-lg-6 login-form">
                    <div className="d-none d-lg-block">
                        <h3 className="title semi-bold">{content.titleDesktop}</h3>
                        <p className="grey s">{content.subTitleDesktop}</p>
                    </div>
                    <Toast ref={myToast} />

                    <div className="section">
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                placeholder="Enter Email..."
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="on"
                                value={email}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        {disable && email.length !== 0 &&
                            <div>
                                <p style={{ color: "red" }}>Please Enter a Valid E-mail!</p>

                            </div>

                        }
                        <div className="authButtons" style={{ display: "flex", marginTop: "30px" }}>
                            <Button btnstyle={'mazhrBtn default-green white w-50'} type="submit" onClick={onSubmit} disabled={disable}>
                                <p className='m-0 light'>{content.recoverPasswordButton}</p>
                            </Button>
                            <Link className="mazhrBtn mazhrBtn light-green w-50" to="/">

                                <p className='m-0 light'>{content.logBackInButton}</p>
                            </Link>
                        </div>

                    </div>

                </div>
                <DesktopAuthDecoration illustration={images.recoverPasswordIllustration} />
            </div >
        )
    } else {
        return <Loading />
    }




}

export default RecoverPassword;
