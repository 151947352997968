import React, { useEffect, useState, useRef } from 'react';
import * as Constants from './../../constants'
import Button from '../../components/reusableElementsComponents/Button';
import { InputAdornment } from '@mui/material';
import { Toast } from 'primereact/toast';
import LockIcon from '@mui/icons-material/Lock';
import { FormControl, TextField } from '@mui/material';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg'
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";
import B2BApiService from '../../B2bApiService';
// import HubspotForm from "../../components/reusableElementsComponents/HubspotForm";
// import * as Constants from '../../constants'

function SetPassword() {
    const api = new B2BApiService();
    const myToast = useRef(null);
    const showToast = (severityValue, summaryValue, detailValue) => {
        myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false);
    const [token, setToken] = useState("")

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const accessToken = query.get('key')
        setToken(accessToken)
    }, [])

    useEffect(() => {
        if (password.length !== 0) {
            if (password === confirmPassword) {
                setDisabled(false)
            }
            else {
                setDisabled(true)
            }
        }
        else {
            setDisabled(true)
        }

    }, [password, confirmPassword])

    const handleBlur = () => {
        let valid = Constants.VALID_PASSWORD.test(password)
        // eslint-disable-next-line
        let validReconfirm = Constants.VALID_PASSWORD.test(confirmPassword)
        if (valid) {
            setError(false)
        }
        else {
            setError(true)
        }
    }

    const handleClick = () => {

        api.SetNewPassword({ key: token, password: password }).then(response => {
            if (response.httpStatus === 200) {
                showToast('success', 'Success Message', 'A confirmation mail has been sent successfully.')
                setTimeout(() => {
                    window.location.href = "/"
                }, 2000)
            }
        })

    }

    return (
        <div className="help-center center-content">
            <img style={{ height: "100px", width: "120px" }} className='company-logo' src={mazharLogo} alt="Apex Analytics" />
            <br />
            <h3>Set Your New Password</h3>
            <br />
            <div style={{ width: "600px", background: "#fff", padding: "30px", borderRadius: "5px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <InputText style={{ justifyContent: "center", width: "50%" }} placeholder="Enter New Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <InputText style={{ justifyContent: "center", width: "50%" }} placeholder="Confirm New Password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div> */}
                <Toast ref={myToast} />
                <FormControl style={{ width: "100%" }}>
                    <TextField
                        placeholder="Enter Password..."
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="on"
                        value={password}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                {error &&
                    <div>
                        <p style={{ color: "red" }}>
                            Password must contain at least 8 characters, a capital letter, a number and a special symbol
                        </p>
                    </div>
                }
                <br />
                <FormControl style={{ width: "100%" }}>
                    <TextField
                        placeholder="Confirm Password..."
                        id="passwordconfirmation"
                        name="password"
                        type="password"
                        autoComplete="on"
                        value={confirmPassword}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </FormControl>
                {/* {error &&
                    <div>
                        <p style={{ color: "red" }}>
                            Password must contain at least 8 characters, a capital letter, a number and a special symbol
                        </p>
                    </div>
                } */}
                {disabled && confirmPassword.length !== 0 &&
                    < div >
                        <p style={{ color: "red" }}>
                            Both Passwords Must Match
                        </p>
                    </div>
                }
                <br />

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button btnstyle={disabled ? 'mazhrBtn default upper mar-25 top' : 'mazhrBtn default-green upper mar-25 top'} onClick={handleClick} disabled={disabled} >Submit</Button>
                </div>

            </div>

        </div >
    )
}
export default SetPassword
