import * as Constants from "./../../../constants";

//import greenLogoNoText from '../../../assets/images/logos/greenLogoNoText.svg';
import greenLogoNoText from "../../../assets/images/logos/apex_analytics_green_logo.svg";
import groupOfPeople from '../../../assets/images/icons/groupOfPeople.svg';
import suitcase from '../../../assets/images/icons/suitcase.svg';
import search from '../../../assets/images/icons/search.svg';
import speechBubble from '../../../assets/images/icons/speechBubble.svg';
import question from '../../../assets/images/icons/question.svg';
import calculator from '../../../assets/images/icons/calculator.svg';
import cog from '../../../assets/images/icons/cog.svg';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import ThreePIcon from '@mui/icons-material/ThreeP';

const processedMenu = (fullMenu, userRole) => {
    const hiddenSubmenus = [];
    const hiddenMenuItems = [];
    let MenuTitleChange = {};
    let processedMenu = [];

    if (userRole === Constants.USER_ROLE_SUPERVISOR) {
        // Determine which submenus should be hidden based on the user's role
        hiddenSubmenus.push('Teams', 'Open positions', 'Talent Analytics');
        hiddenMenuItems.push('Talents', 'Recruitment');
        MenuTitleChange = {
            "Team Management": "Analytics"
        }

        processedMenu = fullMenu.filter((menuItem) => {
            // Check if the menu item title should be hidden based on the user's role
            if (hiddenMenuItems.includes(menuItem.title)) {
                return false; // Exclude the menu item
            }

            if(Object.keys(MenuTitleChange).includes(menuItem.title)){
                menuItem.title = MenuTitleChange[menuItem.title] // Change the menu item title
            }

            // Filter out submenus that should be hidden based on the user's role
            if (menuItem.submenu) {
                menuItem.submenu = menuItem.submenu.filter((submenuItem) => {
                    return !hiddenSubmenus.includes(submenuItem.title);
                });
            }

            // Include the menu item if it's not hidden
            return true;
        });
    } else {
        // For other user roles: No changes to the original full menu
        processedMenu = fullMenu;
    }

    return processedMenu;
}


const PrimaryMenuWithHiddenItems = [
    {
        enabled: true,
        title: 'Team Management',
        icon: <img className='navIcon' src={groupOfPeople} alt="team audit" />,
        submenu: [
            {
                title: 'Teams',
                path: '/team-review',
            },
            {
                title: 'Team Analytics',
                path: '/team-analytics',
            },
            // {
            //     title: 'Sales team review',
            //     path: '/sales-team-review'
            // }
        ]
    },
    {
        enabled: true,
        title: 'Recruitment',
        icon: <img className='navIcon' src={suitcase} alt="open positions" />,
        submenu: [
            {
                title: 'Open positions',
                path: '/open-positions'
            },
            {
                title: 'Talent Analytics',
                path: '/candidate-analytics'
            }
        ]
    },
    {
        enabled: true,
        title: 'Talents',
        path: '/talents',
        icon: <img className='navIcon' src={search} alt="search" />,
        submenu: [
            {
                title: 'Talents',
                path: '/talents',
            }
        ]
    },
    {
        enabled: true,
        title: 'Group Analyzer',
        path: '/team-simulator',
        icon: <JoinInnerOutlinedIcon/>,
        submenu: [
            {
                title: 'Group Analyzer',
                path: '/team-simulator',
            }
        ]
    },
    {
        enabled: false,
        title: 'Competency Model',
        path: '/competency-model',
        icon: <Diversity2Icon/>,
        submenu: [
            {
                title: 'Competency Model',
                path: '/competency-model',
            }
        ]
    },
    {
        enabled: false,
        title: 'Messages',
        path: '/messages',
        icon: <img className='navIcon' src={speechBubble} alt="messages" />,
        submenu: [
            {
                title: 'Messages',
                path: '/messages',
            }
        ]
    }
]

export const PrimaryMenu = (userRole) => processedMenu(PrimaryMenuWithHiddenItems, userRole);

export const SecondaryMenu = [
    {
        enabled: false,
        title: 'Billing',
        path: '/billing',
        icon: <img className='navIcon' src={calculator} alt="billing" />,
    },
    {
        enabled: false,
        title: 'Assistance',
        path: '/assistance',
        icon: <ThreePIcon
            sx={{
                color: 'white', // Icon fill color
                '& path': {
                    stroke: 'black', // Border color
                    strokeWidth: 1.25,  // Border thickness
                }
            }}
        />,
    },
    {
        enabled: true,
        title: 'Help Center',
        path: '/faq-page',
        icon: <img className='navIcon' src={question} alt="FAQ" />,
    },
    {
        enabled: true,
        title: 'Settings',
        path: '/settings',
        icon: <img className='navIcon' src={cog} alt="settings" />,
    }
]

export const MobilePrimaryMenu = (userRole) => processedMenu([
    {
        title: 'Talent pool',
        path: '/team-review',
        icon: <img className='navIcon' src={groupOfPeople} alt="open positions" />,
    },
    {
        title: 'Open positions',
        path: '/open-positions',
        icon: <img className='navIcon' src={suitcase} alt="open positions" />,
    },
    {
        title: 'Dashboard',
        path: 'dashboard/:companyId',
        icon: <img className='navIcon' style={{height: '100%', width: '55px'}} src={greenLogoNoText} alt="Apex Analytics" />,
    },
    {
        title: 'Talents',
        path: '/talents',
        icon: <img className='navIcon' src={search} alt="search" />,
    },
    {
        title: 'Group Analyzer',
        path: '/team-simulator',
        icon: <JoinInnerOutlinedIcon/>
    },
], userRole);

export const MobileSecondaryMenu = [
    // {
    //     title: 'Messages',
    //     path: '/messages',
    //     icon: <img className='navIcon' src={speechBubble} alt="messages" />,
    //     message: false
    // },
    // {
    //     title: 'Billing',
    //     path: '/billing',
    //     icon: <img className='navIcon' src={calculator} alt="billing" />,
    // },
    {
        title: 'Assistance',
        path: '/assistance',
        icon: <ThreePIcon
            sx={{
                color: 'white', // Icon fill color
                '& path': {
                    stroke: 'black', // Border color
                    strokeWidth: 1.25,  // Border thickness
                }
            }}
        />,
    },
    {
        title: 'Help Center',
        path: '/help-center',
        icon: <img className='navIcon' src={question} alt="FAQ" />,
    },
    {
        title: 'Settings',
        path: '/settings',
        icon: <img className='navIcon' src={cog} alt="settings" />,
    }
]
