
function Dropdown({ dropdownTemplate, id, defaultValue, refs }) {

    const renderFields = ( dropdownTemplate ) => {
        return dropdownTemplate.map((dropdownField, index) => {
            let {title} = dropdownField;

            return (
                <option value={title} key={index}>
                    {title}
                </option>
            )
        })
    }

    return (
        <div className = 'dropdownWrapper'>
            {
                defaultValue !== undefined &&
                <select {...refs} id={id}
                        defaultValue={defaultValue} >
                    { renderFields(dropdownTemplate) }
                </select>
            }
        </div>
    )
}

export default Dropdown;
