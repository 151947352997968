import React from 'react';
import ComingSoon from '../errorPage/ComingSoon';
import comingSoonImg from '../../assets/images/illustrations/coming-soon-team-analytics.svg';
import HubspotForm from "../../components/reusableElementsComponents/HubspotForm";
function SalesTeamReview() {
  return (
    <ComingSoon
        heading={'Coming soon: Team Analytics and Sales Team Analytics'}
        content={'Mazhr Team Analytics helps you understand your team/organization dynamics based on the competencies, values and motivational factors of the individuals. \n' +
        '\n' +
        'Individuals create a profile and take the Mazhr assessments powered by AON. This takes them 20-30 min.The employees get immediate feedback on their results. Mazhr provides you valuable data-based information to improve performance and leadership across the whole organization.'}
        ilustration={comingSoonImg}
        videoLink={'https://www.youtube.com/watch?v=YqC6LOHquqM'}
        feedbackormHeading={'Help us by giving feedback on what you would like to see in this page for your team data!'}
        feedbackForm={<HubspotForm target={'feedback-form'} portalId={'25210125'} formId={'2112a77b-fc4b-46aa-8da7-afb96677e54c'}/>}
    />
  )
}

export default SalesTeamReview
