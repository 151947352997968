import { ActionTypes } from '../constants/action-types';

const INITIAL_STATE = {
  all: {},
};

function analyticsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.FETCH_CONTENTFUL_ANALYTICS_DATA:
      let data = {};
      action.payload.data.items?.forEach((item) => {
        if (item) {
          data[
            item.sys?.contentType?.sys?.id || item.fields?.pageTitleDoNotEdituse
          ] = item.fields;
        }
      });

      return { ...state, all: data };

    default:
      return state;
  }
}

export default analyticsDataReducer;
