import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from '../../components/reusableElementsComponents/Button';
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";


function TabsMenu({ template, bottomNav, center,activeKey,tabActiveKey }) {

    let { tabs } = template;
    const [key, setKey] = useState(0);
    useEffect(()=> {
        setKey(tabActiveKey)
    },[tabActiveKey])
    return (
    <>
      <Tabs
        fill
        activeKey={tabActiveKey}
        onSelect={(k) => {
          if (activeKey) {
            activeKey(k);
          }
        }}
        className={center ? "center" : ""}
      >
        {tabs?.map((tab, index) => {
          return (
            <Tab key={index} eventKey={index} title={tab?.sectionTitle}>
              {tab?.component}
            </Tab>
          );
        })}
      </Tabs>
      {bottomNav &&
                <div className="nav-buttons">
                    {(parseInt(key) + 1) !== 1 &&
                        <Button btnstyle={'link grey'} type='button' onClick={() => setKey((parseInt(key)) - 1)}>
                            <BsArrowLeft /><span className='mar-5 left'>Previous</span>
                        </Button>
                    }

                    {(parseInt(key) + 1) !== 1 && (parseInt(key) + 1) !== tabs.length &&
                        <p className='m-0 grey'>...</p>
                    }

                    {(parseInt(key) + 1) !== tabs.length &&
                        <Button btnstyle={'link grey'} type='button' onClick={() => setKey((parseInt(key)) + 1)}>
                            <span className='mar-5 right'>Next</span> <BsArrowRight />
                        </Button>
                    }
                </div>
            }
    </>
      );
}

export default TabsMenu



