import { useState, useEffect, useMemo } from 'react';
import {Checkbox} from '../reusableElementsComponents/Checkbox';
import ExplanationTooltip from '../reusableElementsComponents/ExplanationTooltip';
import B2BApiService from "../../B2bApiService";
import { Switch } from '@mui/material';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import {TextField} from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import * as Constants from '../../constants'
import {useSelector} from "react-redux";


function NewPositionAssessments ({ data, handleData }) {
    const contentfulData = useSelector(state => state.contentfulData.all['General definations']?.fields);
    const invalidInstruments = [111, 357, 315]
    const [closedPool, setClosedPool] = useState(data.is_closed);
    const [emailList, setEmailList] = useState(data.email_list);
    const [candidateEmailError, setCandidateEmailError] = useState(false)
    const [candidateEmailMessage, setCandidateEmailMessage] = useState('Press enter to add email address')
    const [assessments, setAssessments] = useState([]);
    const [updateAssessments, setUpdateAssessments] = useState([]);
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const doEmailDelete = (toDelEmail) => {
        const newEmailList = [];
        emailList.forEach((email) => {
            if(toDelEmail !== email){
                newEmailList.push(email)
            }
        })
        setEmailList(newEmailList);
    }

    const handleChange =  (e, value) => {
        if (e.target.checked) {
            value.isChecked = true;
            setUpdateAssessments([...updateAssessments]);
        } else {
            value.isChecked = false;
            setUpdateAssessments([...updateAssessments]);
        }
    }


    useEffect(() => {
        const checkeAssesmentIds = updateAssessments.filter(val => val.isChecked).map(checked => checked.id);
        handleData({ checkeAssesmentIds, closedPool, emailList });
    },[emailList,closedPool,updateAssessments, handleData])

    useEffect(() => {
        if (!assessments.length) {
            b2bServiceObj.LoadAssessments(setAssessments);
        }
        if (data.assessments && assessments.length) {
            let newAssessment = [...assessments];
            newAssessment.map((val) => {
                val['isChecked'] = data.assessments.includes(val.id);
                return val;
            });
            setUpdateAssessments([...newAssessment]);
        }
        else {
            setUpdateAssessments([...assessments]);
        }
        setClosedPool(data.is_closed);
        setEmailList(data.email_list)

    }, [b2bServiceObj, assessments, data]);

    return (
        <div className="assessments-wrapper section">

            <p className='mar-20 bottom m bold'>
                Assessments
                <ExplanationTooltip>
                    <p>{contentfulData?.openPositionAssessmentsInfo}</p>
                </ExplanationTooltip>
            </p>
            <div className='assessment-card-wrapper'>
            {updateAssessments &&
                updateAssessments.map((assessment, index) => {
                    if (!invalidInstruments.includes(assessment.instrument)) {
                        return (
                            !assessment['is-custom-test'] && assessment['instrument'] !== 111 &&
                            <div key={index} className='assessment-card'>
                                <Checkbox isChecked={assessment.isChecked} onChange={(e) => { handleChange(e, assessment) }} />
                                <p className='m m-10 left'>
                                    {assessment.instrument === 102 && 'Personality and Behaviour'}
                                    {assessment.instrument === 201 && 'Values and Motivation'}
                                    {assessment.instrument === 302 && 'Verbal Reasoning'}
                                    {assessment.instrument === 301 && 'Numerical Reasoning'}
                                    {assessment.instrument === 344 && 'Logical Reasoning'}
                                    {assessment.instrument === 370 && 'English'}
                                    {assessment.instrument === 390 && 'Adept'}
                                    {assessment.instrument === 357 && assessment.name}
                                    {assessment.instrument === 315 && assessment.name}
                                    <ExplanationTooltip>
                                        <p>
                                            {assessment.instrument === 102 && contentfulData?.personalityAssessmentInfo}
                                            {assessment.instrument === 201 && contentfulData?.motivationAssessmentInfo}
                                            {assessment.instrument === 302 && contentfulData?.verbalReasoningAssessmentInfo}
                                            {assessment.instrument === 301 && contentfulData?.numericalReasoningAssessmentInfo}
                                            {assessment.instrument === 344 && contentfulData?.logicalReasoningAssessmentInfo}
                                            {assessment.instrument === 370 && contentfulData?.englishAssessmentInfo}
                                            {assessment.instrument === 390 && contentfulData?.adeptAssessmentInfo}
                                            {assessment.instrument === 357 && assessment.name}
                                            {assessment.instrument === 315 && assessment.name}
                                        </p>
                                    </ExplanationTooltip>
                                </p>
                            </div>
                        )
                    } else {
                        return ""
                    }
                })
            }
            </div>
            <br/>
            <br/>
            <div className="poolToggler">
                <p className='m-0 bold'>
                    {String('Open assessments to selected candidates only')}
                    <ExplanationTooltip>
                        <p>{contentfulData.closedPoolAssessmentInfo}</p>
                    </ExplanationTooltip>
                </p>
                <Switch
                    checked={closedPool}
                    onChange={(e) => { setClosedPool(e.target.checked) }}
                    name='switch'
                    inputProps={{ 'aria-label': 'test switch' }}
                />
                {/* {closedPool ? <p className='m-0'>{String('Closed Pool')}</p> : <p className='m-0'>{String('Open Pool')}</p>} */}
            </div>
            {closedPool &&
            <div className="description">
                <p className='m-0 bold'>Email List of candidates</p>
                <TextField
                    variant="standard"
                    id="candidate_email"
                    label="New email address"
                    name="candidate_email"
                    type="text"
                    error={candidateEmailError}
                    helperText={candidateEmailMessage}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EmailIcon/>
                            </InputAdornment>
                        ),
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            if(Constants.VALID_EMAIL.test(e.target.value)){
                                const list = [...emailList];
                                list.push(e.target.value)
                                setEmailList(list)
                                document.getElementById('candidate_email').value = ''
                            }else{
                                setCandidateEmailError(true)
                                setCandidateEmailMessage('Invalid email address')
                            }

                        }
                    }}
                />
                <br/>
                <br/>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}
                    component="ul"
                >
                    {emailList && emailList.map((email, index) => {
                        return (
                            <Chip
                                key={index}
                                label={email}
                                onDelete={()=>{doEmailDelete(email)}}
                            />
                        )
                    })}
                    {(!emailList || emailList.length === 0) && <p className="m-0 bold">Selected candidate list is empty</p>}
                </Paper>
            </div>
            }
        </div>
    )
}

export default NewPositionAssessments

