import {useEffect, useState} from 'react';
import ExplanationTooltip from '../reusableElementsComponents/ExplanationTooltip';
import {Switch, TextField} from '@mui/material';
import Assessments from '../reusableElementsComponents/Assessments';
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import * as Constants from "../../constants";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import {useSelector} from "react-redux";


function NewPositionAssessments({data}) {

    const contentfulData = useSelector(state => state.contentfulData.all['General definations']?.fields);
    const [closedPool, setClosedPool] = useState(data.is_closed);
    const [assessmentsData] = useState(data.assessments);
    const [candidateEmailError, setCandidateEmailError] = useState(false)
    const [candidateEmailMessage, setCandidateEmailMessage] = useState('Press enter to add email address')
    const [emailList, setEmailList] = useState(data.email_list);

    const doEmailDelete = (toDelEmail) => {
        const newEmailList = [];
        emailList.forEach((email) => {
            if(toDelEmail !== email){
                newEmailList.push(email)
            }
        })
        setEmailList(newEmailList);
    }

    useEffect(() => {
        data.is_closed = closedPool;
        data.email_list = emailList;
        data.assessments = assessmentsData;
    },[emailList, closedPool, assessmentsData, data])

    return (
        <div className="assessments-wrapper">

            <h5 className='mar-20 bottom'>
                Assessments
                <ExplanationTooltip>
                    <p>{contentfulData?.openPositionAssessmentsInfo}</p>
                </ExplanationTooltip>
            </h5>

            <Assessments data={data}/>

            <div className="poolToggler">
                <p className='m-0'>
                    {String('Open assessments to selected candidates only')}
                    <ExplanationTooltip>
                        <p>{contentfulData?.closedPoolAssessmentInfo}</p>
                    </ExplanationTooltip>
                </p>
              <Switch
                  checked={closedPool}
                  onChange={(e) => { setClosedPool(e.target.checked)}}
                  name='switch'
                  inputProps={{'aria-label': 'test switch'}}
              />
            </div>

            {closedPool &&
            <div className="description">
                <p className='m-0 bold'>Email List of candidates</p>
                <TextField
                    variant="standard"
                    id="candidate_email"
                    label="New email address"
                    name="candidate_email"
                    type="text"
                    error={candidateEmailError}
                    helperText={candidateEmailMessage}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EmailIcon/>
                            </InputAdornment>
                        ),
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            if(Constants.VALID_EMAIL.test(e.target.value)){
                                const list = [...emailList];
                                list.push(e.target.value)
                                setEmailList(list)
                                document.getElementById('candidate_email').value = ''
                            }else{
                                setCandidateEmailError(true)
                                setCandidateEmailMessage('Invalid email address')
                            }

                        }
                    }}
                />
                <br/>
                <br/>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}
                    component="ul"
                >
                    {emailList && emailList.map((email, index) => {
                        return (
                            <Chip
                                key={index}
                                label={email}
                                onDelete={()=>{doEmailDelete(email)}}
                            />
                        )
                    })}
                    {(!emailList || emailList.length === 0) && <p className="m-0 bold">Selected candidate list is empty</p>}
                </Paper>
            </div>
            }
        </div>
    )
}

export default NewPositionAssessments

