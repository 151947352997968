import React, {useState, useMemo, useEffect} from "react";

import TeamReviewEdit from "./TeamReviewEdit";
import TeamList from "./TeamList";
import InviteTeamModal from "../teamReviewComponents/InviteTeamModal";

import filterAZ from '../../assets/images/icons/filterAZ.svg';
import Button from "../reusableElementsComponents/Button";
import B2BApiService from "../../B2bApiService";
import {useSelector} from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as Constants from '../../constants'
import * as Utils from '../../Utilities'

function TeamReviewLayoutDesktop() {
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    let [teamsData, setTeamsData] = useState([]);
    let [selectedTeamHash, setSelectedTeamHash] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const onTeamSelect = (hash) => {
        setSelectedTeamHash(hash);
    }

    let cachedSelectedTeamData = useSelector(state => state.teamData.data);

    useEffect(() => {
        b2bServiceObj.LoadTeamsWithAllData(setTeamsData)
    }, [modalShow, b2bServiceObj]);

    useEffect(() => {
        b2bServiceObj.LoadTeamsWithAllData(setTeamsData)
    }, [b2bServiceObj]);

    useEffect(() => {
        if (teamsData[0] && teamsData[0].hash && selectedTeamHash === "") {
            setSelectedTeamHash(teamsData[0].hash);
        }
    }, [b2bServiceObj, teamsData, selectedTeamHash])

    useEffect(() => {
        setSelectedTeamHash("")
    },[modalShow])

    return (
        <div className="team-review-desktop">
            <div className="add-team">
                { Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <Button btnstyle={'mazhrBtn default-green'} onClick={() => setModalShow(true)} id="add-new-team">
                    <AddCircleOutlineIcon/> &nbsp;Add new team
                </Button>}
                { Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <InviteTeamModal modalShow={modalShow} modalHide={setModalShow}/>}
            </div>
            <div className="teams-wrapper m-0">
                <div className="teams-filters">
                    <div className="filter p-0 teams">
                        <p className="filter-title p-0 xs bold">Teams</p>
                        <img src={filterAZ} alt="filter a-z"/>
                    </div>
                    <div className="filter p-0 team-members">
                        <p className="filter-title p-0 xs bold">Team members</p>
                        <img src={filterAZ} alt="filter a-z"/>
                    </div>
                    <div className="spacer"/>
                </div>

                <div className="teams p-0">
                    {
                        cachedSelectedTeamData &&
                        <TeamList
                            teamsData={teamsData}
                            selectedTeamHash={selectedTeamHash}
                            onSelectedFunction={onTeamSelect}
                        />
                    }
                    <div className="edit-field">
                        {selectedTeamHash && <TeamReviewEdit selectedTeamHash={selectedTeamHash}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamReviewLayoutDesktop;
