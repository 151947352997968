import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from '../reusableElementsComponents/Button';
import { ToastContainer, toast } from 'react-toastify';
import TabsMenu from "../reusableElementsComponents/TabsMenu";
import TeamReviewGeneralEdit from './TeamReviewGeneralEdit';
import TeamReviewAssessmentsEdit from './TeamReviewAssessmentsEdit';
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, {useEffect, useMemo, useState} from "react";
import { useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import B2BApiService from "../../B2bApiService";
import SaveIcon from "@mui/icons-material/Save";
import * as Constants from "../../constants";
import * as Utils from '../../Utilities'

function TeamReviewEdit({selectedTeamHash, formValidate}) {
    let selectedTeamData = useSelector(state => state.teamData.data);
    let [teamData, setTeamData] = useState(null);
    let [teamDataEditedFlag, setTeamDataEditedFlag] = useState(false);

    const navigate = useNavigate();

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);


    useEffect(() => {
        b2bServiceObj.LoadTeamByHash(selectedTeamHash, (data) => {
            setTeamData(data)
        } )
    }, [b2bServiceObj, selectedTeamHash])

    let generalData = {
        title: teamData?.title,
        description: teamData?.description,
        industry : teamData?.industry,
        members : teamData?.members
    };

    /*let emailData = {
        invite_team_member_email: teamData?.invite_team_member_email,
    };*/

    let assessmentData = teamData?.assessments;

    const doSave = () => {
        const updateTeamToast = toast.loading("Updating...");
        let teamToBeSaved = {
            hash : selectedTeamData.hash,
            title : generalData.title,
            industry_id : generalData.industry.id,
            description : generalData.description,
            email_list : generalData.members.map(member => member.email),
            assessments : assessmentData
        }
        b2bServiceObj.UpdateTeam(teamToBeSaved, (status, response) => {
            if (status && response.ok && response.httpStatus === 200) {
                toast.update(updateTeamToast, {
                    render: "Updated successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
                setTeamData(response.json.data[0])
                setTeamDataEditedFlag(false)
            }else{
                toast.update(updateTeamToast, {
                    render: "Unable to make update",
                    type: "error",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }

        })
    }

    let template = {
        tabs: [
            {
                component: <TeamReviewGeneralEdit
                    data={teamData}
                    updateData = {setTeamData}
                    formValidate={formValidate}
                    teamHash={selectedTeamData?.hash}
                    dataEditedFlag={teamDataEditedFlag}
                    setDataEditedFlag={setTeamDataEditedFlag}
                />,
                sectionTitle: 'general'
            },
            /*{
                component: <EmailTemplatesEdit data={emailData}/>,
                sectionTitle: 'email templates'
            },*/
            {
                component: <TeamReviewAssessmentsEdit data={assessmentData}/>,
                sectionTitle: 'assessments'
            }
        ]
    }
    return (
        <>
            {<div className="p-0 headers-btn d-flex" style={{marginBottom:"30px"}}>
                <ToastContainer />
                <Button
                    btnstyle={'mazhrBtn light-green'}
                    onClick={()=> {
                        navigate('/talents/' + teamData.hash + Constants.URL_WITH_HASH_FOR_TEAM_CHECK)
                        localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SEARCH_TYPE, Constants.SEARCH_TYPE_TARGET_POSITION)
                    }
                    }>
                    <VisibilityIcon/> &nbsp; View Members
                </Button>
                &nbsp;&nbsp;&nbsp;
                {Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR &&  <Button btnstyle={`mazhrBtn default-green ${teamDataEditedFlag ? 'blinking-border-button' : ''}` } onClick={doSave}>
                    <SaveIcon/> &nbsp; Save
                </Button>}

            </div>}
            {<TabsMenu template={template} />}
        </>
    )
}

export default TeamReviewEdit;
