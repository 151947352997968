import ExplanationTooltip from '../reusableElementsComponents/ExplanationTooltip';
import Assessments from '../reusableElementsComponents/Assessments';
import {useSelector} from "react-redux";

function TeamReviewAssessments({data}) {

    const contentfulData = useSelector(state => state.contentfulData?.all['General definations']?.fields);
    return (
        <div className="assessments-wrapper section">
            <p className='mar-20 bottom m bold'>
                Assessments
                <ExplanationTooltip>
                    <p>{contentfulData?.teamAssessmentsInfo}</p>
                </ExplanationTooltip>
            </p>
            <Assessments data={data} />
        </div>
    )
}

export default TeamReviewAssessments

