import React from 'react'

function PageLayout({children}) {
  return (
    <div className="page-wrapper">
        <div className='desktop-layout'>
            {children}
        </div> 
    </div>
  )
}

export default PageLayout


