import React, {useState, useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import {Box, Checkbox, MenuItem, FormControlLabel} from "@mui/material";
import {Select} from '../formComponents/Inputs';
import B2BApiService from '../../B2bApiService';
import Chip from "@mui/material/Chip";
import StrengthsRadarChart from "../reusableElementsComponents/StrengthsRadarChart";
import TeamSimulatorVerticalCharts from './teamSimulatorVerticalChart';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import * as Constants from "../../constants";


const TeamSimulator = ({modalVisbility, setModalVisibility}) => {

    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const [poolOptions, setPoolOptions] = useState([])
    const [salesOption, setSalesOption] = useState('Competency')
    const idList = [];
    const [selectedPool, setSelectedPool] = useState({})
    const [teamOptions, setTeamOptions] = useState([])
    const [selectedTeam, setSelectedTeam] = useState({})
    const [poolUserList, setPoolUserList] = useState([])
    const [teamUserList, setTeamUserList] = useState([])
    const [tempPoolArray, setTempPoolArray] = useState([]);
    const [tempTeamArray, setTempTeamArray] = useState([]);
    const [poolListKey, setPoolListKey] = useState(0);
    const [teamListKey, setTeamListKey] = useState(0);
    const [modaldownState, setModalDownState] = useState();

    useEffect(() => {
        setTeamListKey(teamListKey + 1);
        setTempTeamArray([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamUserList]);

    let optionsSalesDropdown = [
        {id: 1, title: "Competency"},
        {id: 2, title: "Attitude"}
    ]

    useEffect(() => {
        setPoolListKey(poolListKey + 1);
        setTempPoolArray([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poolUserList]);

    const [tempPoolObject, setTempPoolObject] = useState({
        title: "",
        hash: "",
    })
    const [tempTeamObject, setTempTeamObject] = useState({
        title: "",
        hash: "",
    })
    const [selectedChips, setSelectedChips] = useState([])
    let poolHash = ""
    let teamHash = ""
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    },[]);
    useEffect(() => {
        b2bServiceObj.LoadPoolsWithAllData((setPoolOptions))
        b2bServiceObj.LoadTeamsWithAllData((setTeamOptions))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [b2bServiceObj])

    const handlePoolCheck = (e, obj, index) => {
        const object = {
            title: tempPoolObject.title,
            hash: tempPoolObject.hash,
            poolUserId: obj.id,
            subTitle: obj.first,
            isChecked: e.target.checked
        }
        if (e.target.checked) {
            setTempPoolArray([...tempPoolArray, object]);
        } else {
            setTempPoolArray(tempPoolArray.filter((o) => o.poolUserId !== obj.id));
        }
    }

    const setModalDownStateFunction = () => {
        if (modaldownState === undefined) {
            setModalDownState(true)
        } else if (modaldownState === true) {
            setModalDownState(false)
        } else if (modaldownState === false) {
            setModalDownState(true)
        }
    }

    const handleTeamCheck = (e, obj, index) => {
        const object = {
            title: tempTeamObject.title,
            hash: tempTeamObject.hash,
            teamUserId: obj.id,
            subTitle: obj.first,
            isChecked: e.target.checked
        }
        if (e.target.checked) {
            setTempTeamArray([...tempTeamArray, object]);
        } else {
            setTempTeamArray(tempTeamArray.filter((o) => o.teamUserId !== obj.id));
        }
    }

    const onSelectPool = (e) => {
        for (var i = 0; i < poolOptions.length; i++) {
            setPoolUserList([])
            if (poolOptions[i].title === e.target.value) {
                setSelectedPool(poolOptions[i])
                poolHash = poolOptions[i].hash
                setTempPoolObject({
                    title: poolOptions[i].title,
                    hash: poolOptions[i].hash,
                    subTitleId: "",
                    subTitle: "",
                    isChecked: false
                })
                b2bServiceObj.SearchTalents(
                    setPoolUserList,
                    Constants.USER_FILTER_TYPE_ONLY_POOLS,
                    [poolHash],
                    []
                )
            }
        }

    }

    const onSelectTeam = (e) => {
        for (var i = 0; i < teamOptions.length; i++) {
            setTeamUserList([])
            if (teamOptions[i].title === e.target.value) {
                setSelectedTeam(teamOptions[i])
                teamHash = teamOptions[i].hash
                setTempTeamObject({
                    title: teamOptions[i].title,
                    hash: teamOptions[i].hash,
                    subTitleId: "",
                    subTitle: "",
                    isChecked: false
                })
                b2bServiceObj.SearchTalents(
                    setTeamUserList,
                    Constants.USER_FILTER_TYPE_ONLY_TEAMS,
                    [],
                    [teamHash]
                )
            }
        }
    }

    const handleArrowClickPool = () => {
        const result = tempPoolArray.filter(obj1 => {
            return selectedChips.some(obj2 => {
                return obj1.title === obj2.title && obj1.poolUserId === obj2.poolUserId;
            });
        });
        if (result.length !== 0) {
            result.map((o) => {
                let value = tempPoolArray.indexOf(o)
                tempPoolArray.splice(value, 1)
                return true
            })
        }
        tempPoolArray.map((obj) => {
            selectedChips.push(obj)
            return true
        })
        setSelectedChips([...selectedChips])
        setTempPoolArray([...tempPoolArray, ...result])
    }

    const salesOptionChange = (e) => {
        setSalesOption(e.target.value)
    }

    const handleArrowClickTeam = () => {
        const result = tempTeamArray.filter(obj1 => {
            return selectedChips.some(obj2 => {
                return obj1.title === obj2.title && obj1.teamUserId === obj2.teamUserId;
            });
        });
        if (result.length !== 0) {
            result.map((o) => {
                let value = tempTeamArray.indexOf(o)
                tempTeamArray.splice(value, 1)
                return true
            })
        }
        tempTeamArray.map((obj) => {
            selectedChips.push(obj)
            return true
        })
        setSelectedChips([...selectedChips])
        setTempTeamArray([...tempTeamArray, ...result])
    }

    const handleDeleteChip = (object, index) => {
        setTempPoolArray([...tempPoolArray])
        setTempTeamArray([...tempTeamArray])
        selectedChips.splice(index, 1)
        setSelectedChips([...selectedChips])
    }

    const [motivationsLabels, setMotivationsLabels] = useState([])
    const [competenciesLabels, setCompetenciesLabels] = useState([])
    const [employeesMotivationsData, setEmployeesMotivationsData ] = useState([])
    const [employeesCompetenciesData, setEmployeesCompetenciesData ] = useState([])
    const [candidatesMotivationsData, setCandidatesMotivationsData ] = useState([])
    const [candidatesCompetenciesData, setCandidatesCompetenciesData ] = useState([])

    const [salesChartData, setSalesChartData] = useState({
        labels: contentfulData?.salesStrengthsLabels,
        datasets: [
            {
                label: 'Competence',
                backgroundColor: 'rgba(53, 196, 220, 0.2)',
                borderColor: 'rgb(53, 196, 220)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: []
            },
            {
                label: 'Attitude',
                backgroundColor: 'rgba(53, 196, 220, 0.2)',
                borderColor: 'rgb(53, 196, 220)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: []
            }
        ]
    });

    const [teamsChartData, setTeamsChartData] = useState({
        labels: contentfulData?.teamStrengthsLabels,
        datasets: [
            {
                label: 'Team Strengths',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: []
            }
        ]
    });

    const [lightOptionsTeams] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom'
            }
        },
        scales: {
            r: {
                min: 0,
                max: 9,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                    circular: true
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });

    const [lightOptionsSales] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                    circular: true
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });

    function formulateBarChartDataForMotivations(data) {
        const employees = data.employees;
        const candidates = data.candidates;

        // Extracting top and bottom 4 values from employees
        const sortedEmployees = [...employees].sort((a, b) => a.average - b.average);
        const top4Employees = sortedEmployees.slice(-5);
        const bottom4Employees = sortedEmployees.slice(0, 5);

        // Extracting the column names from top and bottom 4 employees
        const columnNames = [...top4Employees, ...bottom4Employees].map((employee) => employee.column_name);

        // Retrieving the values from candidates for the corresponding column names
        const candidatesValues = columnNames.map((columnName) => {
            const candidate = candidates.find((c) => c.column_name === columnName);
            return candidate ? candidate.average : null;
        });
        return {top4Employees, bottom4Employees, columnNames, candidatesValues};
    }

    function formulateBarChartDataForCompetencies(data) {
        const employees = data.employees;
        const candidates = data.candidates;

        // Extracting top and bottom 4 values from candidates
        const sortedCandidates = [...candidates].sort((a, b) => a.average - b.average);
        const top4Candidates = sortedCandidates.slice(-5);
        const bottom4Candidates = sortedCandidates.slice(0, 5);

        // Extracting the column names from top and bottom 4 candidates
        const columnNames = [...top4Candidates, ...bottom4Candidates].map((candidate) => candidate.column_name);

        // Retrieving the values from employees for the corresponding column names
        const employeesValues = columnNames.map((columnName) => {
            const employee = employees.find((e) => e.column_name === columnName);
            return employee ? employee.average : null;
        });

        return { top4Candidates, bottom4Candidates, columnNames, employeesValues };
    }


    useEffect(() => {

        let candidates = [];
        let employees = [];

        for (let i = 0; i < selectedChips.length; i++) {
            if (selectedChips[i].poolUserId) {
                candidates.push(selectedChips[i].poolUserId);
            }
            if (selectedChips[i].teamUserId) {
                employees.push(selectedChips[i].teamUserId);
            }
        }

        if(candidates.length > 0 || employees.length > 0){
            b2bServiceObj.GetTeamSimulatorData({candidates: candidates, employees: employees}, (data) => {
                // Transforming team_strengths data
                const teamStrengths = {
                    labels: [],
                    dataset: []
                };

                // Sample array of objects with key-value pairs
                function randomColorWithOpacity(opacity) {
                    const r = Math.floor(Math.random() * 256);
                    const g = Math.floor(Math.random() * 256);
                    const b = Math.floor(Math.random() * 256);
                    return `rgba(${r},${g},${b},${opacity})`;
                }


                const keysToExclude = ['user_id'];

                data.team_strengths.forEach((item) => {
                    const datasetRow = [];
                    Object.keys(item).forEach((key) => {
                        if (!keysToExclude.includes(key)) {
                            if (!teamStrengths.labels.includes(key)) {
                                teamStrengths.labels.push(key);
                            }
                            if (key !== 'user_id') {
                                datasetRow.push(item[key]);
                            }
                        }
                    });
                    if (datasetRow.length > 0) {
                        teamStrengths.dataset.push(datasetRow);
                    }
                });

                const datasets = [];

            teamStrengths.dataset.forEach((datasetArray, index) => {
                let reqId = data.team_strengths[index].user_id;

                const matchingChips = selectedChips.filter(chip => {
                    return chip?.poolUserId === reqId || chip?.teamUserId === reqId;
                });

                const reqTitle = matchingChips.map(chip => chip?.subTitle);
                const pointBackgroundColor = randomColorWithOpacity(1); // Random color with opacity 1 for pointBackgroundColor
                const backgroundColor = pointBackgroundColor.replace(/[^,]+(?=\))/, '0.2'); // Adjust opacity for backgroundColor
                datasets.push({
                    label: reqTitle,
                    backgroundColor: backgroundColor,
                    borderColor: pointBackgroundColor,
                    pointBackgroundColor: pointBackgroundColor,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: pointBackgroundColor,
                    data: datasetArray
                });
            });
            setTeamsChartData({
                labels: contentfulData?.teamStrengthsLabels,
                datasets: datasets
            });

                const salesStrengths = {
                    Competency: {
                        labels: [],
                        dataset: []
                    },
                    Attitude: {
                        labels: [],
                        dataset: []
                    }
                };

                data.sales_strengths.forEach((item) => {
                    Object.keys(item).forEach((key) => {
                        if (typeof item[key] === 'object' && key === 'Competency') {
                            const datasetRowCompetence = [];
                            Object.keys(item[key]).forEach((subKey) => {
                                datasetRowCompetence.push(item[key][subKey]);
                            });
                            salesStrengths.Competency.dataset.push(datasetRowCompetence); // Push to competency dataset
                        }
                        if (typeof item[key] === 'object' && key === 'Attitude') {
                            const datasetRowAttitude = [];
                            Object.keys(item[key]).forEach((subKey) => {
                                datasetRowAttitude.push(item[key][subKey]);
                            });
                            salesStrengths.Attitude.dataset.push(datasetRowAttitude); // Push to attitude dataset
                        }
                        else if (typeof item[key] !== 'object') {
                            let val = item[key]
                            idList.push(val);
                            // setIdList(prevIdList => [...prevIdList, item[key]])
                        }
                    });
                });

                const salesDatasets = [];
                Object.keys(salesStrengths).forEach((strengthKey) => {
                    if(salesOption === 'Competency' && strengthKey === 'Competency') {
                        salesStrengths?.Competency?.dataset?.forEach((datasetArray, index) => {
                            let reqId = idList[index]

                           const matchingChips = selectedChips.filter(chip => {
                                return chip?.poolUserId === reqId || chip?.teamUserId === reqId;
                            }
                            );
                            const reqTitle = matchingChips.map(chip => chip?.subTitle);
                            const pointBackgroundColor = randomColorWithOpacity(1); // Random color with opacity 1 for pointBackgroundColor
                            const backgroundColor = pointBackgroundColor.replace(/[^,]+(?=\))/, '0.2'); // Adjust opacity for backgroundColor

                            salesDatasets.push({
                                label: reqTitle,
                                backgroundColor: backgroundColor,
                                borderColor: pointBackgroundColor,
                                pointBackgroundColor: pointBackgroundColor,
                                pointBorderColor: '#fff',
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: pointBackgroundColor,
                                data: datasetArray
                            });
                        });
                    }
                    else if (salesOption === 'Attitude' && strengthKey === 'Attitude'){
                        salesStrengths?.Attitude?.dataset?.forEach((datasetArray, index) => {
                            let reqId = idList[index];

                           const matchingChips = selectedChips.filter(chip => {
                                return chip?.poolUserId === reqId || chip?.teamUserId === reqId;
                            });
                            const reqTitle = matchingChips.map(chip => chip?.subTitle);

                            const pointBackgroundColor = randomColorWithOpacity(1); // Random color with opacity 1 for pointBackgroundColor
                            const backgroundColor = pointBackgroundColor.replace(/[^,]+(?=\))/, '0.2'); // Adjust opacity for backgroundColor

                            salesDatasets.push({
                                label: reqTitle,
                                backgroundColor: backgroundColor,
                                borderColor: pointBackgroundColor,
                                pointBackgroundColor: pointBackgroundColor,
                                pointBorderColor: '#fff',
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: pointBackgroundColor,
                                data: datasetArray
                            });
                        });
                    }
                });
                setSalesChartData({
                    labels: contentfulData?.salesStrengthsLabels,
                    datasets: salesDatasets
                })


                if(Object.keys(data.motivations).length > 0){
                    const {top4Employees, bottom4Employees, columnNames, candidatesValues} = formulateBarChartDataForMotivations(data.motivations);

                    setMotivationsLabels(columnNames)

                    const mergedValues = [...top4Employees, ...bottom4Employees].map((employee) => employee.average);

                    setEmployeesMotivationsData(mergedValues)

                    setCandidatesMotivationsData(candidatesValues)
                }

                if(data.competencies) {
                    if(Object.keys(data.competencies).length > 0){
                        const {top4Candidates, bottom4Candidates, columnNames, employeesValues} = formulateBarChartDataForCompetencies(data.competencies);

                        setCompetenciesLabels(columnNames)

                        const mergedValues = [...top4Candidates, ...bottom4Candidates].map((candidate) => candidate.average);

                        setEmployeesCompetenciesData(employeesValues)

                        setCandidatesCompetenciesData(mergedValues)
                    }
                }
            })
        }

        // eslint-disable-next-line
    }, [selectedChips, b2bServiceObj, contentfulData, setTeamsChartData, salesOption])

    return (
        <div className={`modal-123 ${modaldownState === undefined ? 'modal-visible-initial' : modaldownState ? 'modal-visible-animated' : 'modal-visible'}`}>
            <div className='modal-revamp-class'>
            <div className='main-div-team-simulator'>
                <div className='bg-div'>
                    <div className='front-div'>
                        <div className='dropdown-div'>
                            <div className="select-pool-dropdown">
                                <Select
                                    label="Select open position"
                                    option={selectedPool.title}
                                    placeholder="Select open position"
                                    handleChange={(e) => onSelectPool(e)}
                                    options={poolOptions}
                                />
                            </div>
                            <div className="select-team-dropdown">
                                <Select
                                    label="Select Team"
                                    option={selectedTeam.title}
                                    handleChange={(e) => onSelectTeam(e)}
                                    placeholder="Select Team"
                                    options={teamOptions}
                                />
                            </div>

                        </div>
                        <div className='box-main-div'>
                            {poolUserList.length > 0 && <div className="box-div for-candidates">
                                <Box>
                                    <ul key={poolListKey} className='user-list-box'>
                                        {poolUserList.length !== 0 && poolUserList.map((obj, index) => {
                                            let candName = "Anonymous"
                                            if(obj.first !== "Anonymous") {
                                                candName = obj.first.toLowerCase() + " " + obj.last?.toLowerCase();
                                            }
                                            return (
                                                <MenuItem>
                                                    <FormControlLabel
                                                        label={candName}
                                                        control={<Checkbox/>}
                                                        onChange={(e) => handlePoolCheck(e, obj, index)}
                                                        sx={{
                                                            '& .MuiFormControlLabel-label' : {
                                                                textTransform: "capitalize"
                                                            }
                                                        }}
                                                        inputProps={{'aria-label': 'controlled'}}>
                                                    </FormControlLabel></MenuItem>
                                            )
                                        })}
                                    </ul>
                                </Box>
                            </div>}
                            {teamUserList.length > 0 && <div className='box-div for-employees'>
                                <Box key={teamListKey}>
                                    <ul className='user-list-box'>
                                        {teamUserList.length !== 0 && teamUserList.map((obj, index) => {
                                            let candName = "Anonymous"
                                            if(obj.first !== "Anonymous") {
                                                candName = obj.first.toLowerCase() + " " + obj.last?.toLowerCase();
                                            }

                                                return (
                                                    <MenuItem>
                                                        <FormControlLabel
                                                            label={candName}
                                                            control={<Checkbox/>}
                                                            onChange={(e) => handleTeamCheck(e, obj, index)}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label' : {
                                                                    textTransform: "capitalize"
                                                                }
                                                            }}
                                                            inputProps={{'aria-label': 'controlled'}}>
                                                        </FormControlLabel>
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </ul>
                                </Box>
                            </div>}
                        </div>
                    </div>

                    {(poolUserList.length > 0 || teamUserList.length > 0) && <div className='middle-div'>
                        <button onClick={() => {
                            handleArrowClickPool();
                            handleArrowClickTeam()
                        }} className="mazhrBtn default-green"><DoubleArrowOutlinedIcon style={{fontSize:"30px"}}></DoubleArrowOutlinedIcon></button>
                    </div>}
                    <div className='secondary-div'>
                        <div className='cancel-button-div'>
                                <CancelOutlinedIcon className='modal-close-bttn' onClick={setModalDownStateFunction}></CancelOutlinedIcon>
                        </div>

                        <div className='box-main-div'>
                            {selectedChips.length !== 0 &&
                            <div className='box-div-secondary'>
                                {selectedChips.length !== 0 &&
                                selectedChips.map((object, index) => {
                                    return (
                                        <>
                                            <Chip
                                                style={{
                                                    margin: "5px 10px",
                                                    backgroundColor: object.poolUserId ? "#FFC247" : object.teamUserId ? "#00B0A0" : "#FFC247",
                                                    color: "#ffffff",
                                                    borderRadius: "10px"
                                                }}
                                                sx={{
                                                    '& .MuiChip-deleteIcon': {
                                                        color: '#ffffff', // sets the icon color
                                                        backgroundColor: object.poolUserId ? "#FFC247" : object.teamUserId ? "#00B0A0" : "#FFC247",
                                                        marginLeft:"auto"
                                                    },
                                                    '& .MuiChip-label' : {
                                                        textTransform: "capitalize"
                                                    }
                                                }}
                                                onDelete={() => handleDeleteChip(object, index)}
                                                label={`${object.subTitle.toLowerCase()} from ${object.title}`}/>
                                        </>

                                    )
                                })}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {selectedChips.length > 0 && <div className='charts-div-background'>
                <div className='charts-div'>
                    <div className='radar-charts-div mt-5'>
                        <StrengthsRadarChart
                            title="Team Strengths"
                            data={teamsChartData}
                            options={lightOptionsTeams}
                        />
                    </div>
                    <div className='radar-charts-div mt-2' style={{flexDirection: "column"}}>
                        <div style={{alignSelf:'flex-end'}}>
                            <Select
                                options = {optionsSalesDropdown}
                                handleChange={(e)=> {salesOptionChange(e)}}
                                option={salesOption}
                            />
                        </div>

                        <StrengthsRadarChart
                            title="Sales Strengths"
                            data={salesChartData}
                            options={lightOptionsSales}
                        />
                    </div>
                </div>
                <div className='charts-div'>
                    <TeamSimulatorVerticalCharts
                        labels={motivationsLabels}
                        employeesData={employeesMotivationsData}
                        candidatesData={candidatesMotivationsData}
                        chartTitle='Values & Motivation Comparison (Employee Focused)'
                    />
                </div>
                <div className='charts-div'>
                    <TeamSimulatorVerticalCharts
                        labels={competenciesLabels}
                        employeesData={employeesCompetenciesData}
                        candidatesData={candidatesCompetenciesData}
                        chartTitle='Work Style Preferences Comparison (Candidate Focused)'
                    />
                </div>
            </div>}
            </div>
            <div className='button-div'>
                <button className="team-simulator-modal-button" onClick={setModalDownStateFunction}>Team
                    Simulator {modaldownState === true ? <span><KeyboardDoubleArrowUpIcon/></span> : <span><KeyboardDoubleArrowDownIcon/></span>}</button>
            </div>
        </div>
    );
}

export default TeamSimulator;
