import { useSelector } from 'react-redux';
import React, {useMemo, useState} from "react";
import ExplanationTooltip from "../reusableElementsComponents/ExplanationTooltip";
import Accordion from "react-bootstrap/Accordion";
import ScoreLine from "../reusableElementsComponents/ScoreLine";
import * as PropTypes from "prop-types";
import B2BApiService from "../../B2bApiService";
import {toast, ToastContainer} from "react-toastify";

function CandidateMotivationFactors(props) {
    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const sendAssessmentReminder = () => {
        const updateTeamToast = toast.loading(
            "Sending...",
            {containerId: 'candidate-motivation-factors'}
            );
        b2bServiceObj.SendAssessmentReminder(props.data.info.id, (status, response) => {
            if (status && response.ok && response.httpStatus === 200) {
                toast.update(updateTeamToast, {
                    render: "Reminder email sent",
                    type: "success",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }else{
                toast.update(updateTeamToast, {
                    render: "Unable to reminder email",
                    type: "error",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }

        })
    }

    const sortMotivationPoints = () => {
        contentfulData?.strongestMotivationalFactors.map((object) => {
            return object.value = props.data.motivation_points[object.id]
        })
        return contentfulData?.strongestMotivationalFactors.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
    }

    const showMoreMotivation = () => {
        setMotivationVisible(props.data.competence_points.length);

        document.getElementById('showMoreMotivation').classList.remove('show', 'flex');
        document.getElementById('showMoreMotivation').classList.add('hide');
        document.getElementById('showLessMotivation').classList.add('show', 'flex');
    }

    const showLessMotivation = () => {
        setMotivationVisible(3);
        document.getElementById('showLessMotivation').classList.remove('show', 'flex');
        document.getElementById('showLessMotivation').classList.add('hide');
        document.getElementById('showMoreMotivation').classList.add('show', 'flex');
    }

    const [motivationVisible, setMotivationVisible] = useState(3);

    return <div className="motivation" id="boxStyleMot">
        <ToastContainer containerId="candidate-motivation-factors"/>
        <div className="title">
            <p className='m-0'>
                {contentfulData?.motivationsSectionTitle}
                <ExplanationTooltip>
                    {contentfulData?.motivationFactorsHelpLink}
                </ExplanationTooltip>
            </p>
            <p className='xs grey'>{contentfulData?.verifiedByAon}</p>
        </div>

        <div className="stats">
            <Accordion>
                {props.data.motivation_points.length > 0 ? props.data.motivation_points.slice(0, motivationVisible).map((point, index) => (
                        sortMotivationPoints().map((pointCopy, index2) => {
                            if (index === index2) {
                                return (

                                    <Accordion.Item eventKey={index} key={index}>
                                        {index === 0 &&
                                        <Accordion.Header>
                                            <div className="accordion-header-wrapper">
                                                <p className='mar-5 right s'>{pointCopy.title}</p>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between"
                                                    }}>
                                                                        <span style={{
                                                                            fontSize: "10px",
                                                                            color: "grey"
                                                                        }}>Low</span>
                                                        <span style={{
                                                            fontSize: "10px",
                                                            color: "grey"
                                                        }}>Average</span>
                                                        <span style={{
                                                            fontSize: "10px",
                                                            color: "grey"
                                                        }}>High</span>
                                                    </div>
                                                    <ScoreLine val={pointCopy.value}/>
                                                </div>
                                            </div>
                                        </Accordion.Header>}
                                        {index !== 0 &&
                                        <Accordion.Header>
                                            <div className="accordion-header-wrapper">
                                                <p className='mar-5 right s'>{pointCopy.title}</p>
                                                <ScoreLine val={pointCopy.value}/>
                                            </div>
                                        </Accordion.Header>
                                        }
                                        <Accordion.Body>
                                            <div className="description yes">
                                                <div className="bar yes"></div>
                                                {pointCopy.description_yes}
                                            </div>
                                            <div className="description no">
                                                <div className="bar no"></div>
                                                {pointCopy.description_no}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            } else return ''
                        })
                    ))
                    :
                    <div className="sendToTests">
                        <p>No motivational factors at the moment</p>
                        <button className="mazhrBtn default-pink" onClick={sendAssessmentReminder}>Send assessments reminder</button>
                    </div>
                }
            </Accordion>
        </div>

        {props.data.motivation_points.length > 0 &&
        <>
            <button id="showMoreMotivation" className='show-more-link link'
                    onClick={showMoreMotivation}><p className="xs bold">Show more</p></button>
            <button id="showLessMotivation" className='show-more-link link hide'
                    onClick={showLessMotivation}><p className="xs bold">Show less</p></button>
        </>
        }
    </div>;
}

CandidateMotivationFactors.propTypes = {
    data: PropTypes.any,
};


export default CandidateMotivationFactors;
