import {useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import ExplanationTooltip from '../reusableElementsComponents/ExplanationTooltip';
import RecruitmentProcess from './RecruitmentProcess';
import {Select, Text, TextArea} from '../formComponents/Inputs';
import B2BApiService from "../../B2bApiService";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function NewPositionGeneral({data, formValidate}) {
    const contentfulData = useSelector(state => state.contentfulData.all['open positions']?.fields);

    let [jobIndustries, setJobIndustries] = useState([]);
    let [selectedJobProfiles, setSelectedJobProfiles] = useState([]);
    const [deadline, setDeadline] = useState(data.deadline);
    let [newPositionFormData, setNewPositionFormData] = useState(data);
    const [selectedTargetProfile, setSelectedTargetProfile] = useState(data.target_profile_id);
    const [selectedDepartment, setSelectedDepartment] = useState(data?.industry_id);
    const [recruitmentProcessData, _setRecruitmentProcess] = useState(data.recruitment_process)
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const setRecruitmentProcessData = (process) => {
        data['recruitment_process'] = process
        _setRecruitmentProcess(process);
    };

    const handleTitleChange = (e) => {
        data['title'] = e.target.value
        setNewPositionFormData({...newPositionFormData, 'title': e.target.value});
    }

    const handleDescriptionChange = (e) => {
        data['description'] = e.target.value
        setNewPositionFormData({...newPositionFormData, 'description': e.target.value});
    }

    const handleSelectTargetProfile = (e, id) => {
        selectedJobProfiles.map((object)=> {
            if(object.title === e.target.value) {
                var id = object.id
                if (e && id) {
                    setSelectedTargetProfile(data['target_profile_id'] = e.target.value)
                    for (let i = 0; i < selectedJobProfiles.length; i++) {
                        if (selectedJobProfiles[i].title === e.target.value) {
                            handleChange(selectedJobProfiles[i].id.toString(), 'target_profile_id')
                        }
                    }
                }
            }
            return true;
        })
    }

    const handleDateChange = (date) => {
        setDeadline(date);
        handleChange(date.$d, 'deadline');
    }

    const handleChange = (value, key) => {
        data[key] = value
        setNewPositionFormData({...newPositionFormData, [key]: value});
    }


    const handleSelectIndustry = (e) => {
        jobIndustries.map((obj)=> {
            if(obj.title === e.target.value) {
                var id = obj.id
                if (e && id) {
                    setSelectedDepartment(data['industry_id'] = e.target.value)

                    for (let i = 0; i < jobIndustries.length; i++) {
                        if (jobIndustries[i].title === e.target.value) {

                            b2bServiceObj.LoadTargetProfilesByIndustry(jobIndustries[i], setSelectedJobProfiles);
                            handleChange(jobIndustries[i].id.toString(), 'industry_id')
                            return true;
                        }
                    }
                }
            }
            return true;
        })

    }

    useEffect(() => {
        b2bServiceObj.LoadIndustriesList(setJobIndustries);
    }, [b2bServiceObj]);


    return (
        <>
            <div className="titleSection section">
                <div className="title">
                    <div className="field-label">{contentfulData?.newPositionTitle}</div>
                    <Text
                        title=""
                        required={true}
                        fullWidth={true}
                        doValidation={formValidate}
                        errorContent={"Please enter position title"}
                        handleChange={handleTitleChange}
                    />
                </div>

                <div className="date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="field-label">{contentfulData?.deadline}</div>
                        <DatePicker
                            label=""
                            value={deadline}
                            onChange={date =>  handleDateChange(date)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="departmentSection section">
                <div className="department">
                    <div className="field-label">{contentfulData?.department}</div>
                    {jobIndustries.length > 0 &&
                    <Select
                        id={'industry_id'}
                        label='Job Departments'
                        options={jobIndustries}
                        doValidation={formValidate}
                        errorContent={"Please select department"}
                        handleChange={handleSelectIndustry}
                        option={selectedDepartment ? selectedDepartment : ''}
                        defaultValue={"Select department"}
                    />
                    }
                </div>
                <br/>
                { selectedDepartment &&
                   <div className="targetProfile">
                       <div className="field-label">
                           {contentfulData?.targetProfile}
                           <ExplanationTooltip>
                               <p>{contentfulData?.targetProfileExplanation}</p>
                           </ExplanationTooltip>
                       </div>
                   <Select
                       id={'target_profile'}
                       label="Target Profiles"
                       options={selectedJobProfiles}
                       doValidation={formValidate}
                       errorContent={contentfulData?.targetProfileError}
                       handleChange={handleSelectTargetProfile}
                       option={selectedTargetProfile ? selectedTargetProfile : ''}
                       defaultValue={"Select target profile"}
                   />
                    </div>
                }

            </div>

            <br/>
            <div className="description">
                <div className="field-label">{contentfulData?.jobDescription} (<span className="italic"> {contentfulData?.visibleToCandidate}. </span>)</div>
                <TextArea
                    title=""
                    required={false}
                    fullWidth={true}
                    handleChange={handleDescriptionChange}
                />
            </div>

            <div className="recruitment-process section">
                <div className="field-label">{contentfulData?.hiringProcess} (<span className="italic"> {contentfulData?.visibleToCandidate}. </span>)</div>
                {<RecruitmentProcess recruitmentProcess={recruitmentProcessData} onChangeFunc={setRecruitmentProcessData}/>}
            </div>
        </>
    )
}

export default NewPositionGeneral






