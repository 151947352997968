// src/analytics.js
import ReactGA4 from 'react-ga4';

const trackingId = 'G-J01DYB49MX'; // Replace with your Google Analytics 4 tracking ID

const initGA4 = () => {
    ReactGA4.initialize(trackingId);
};

const logPageView = () => {
    ReactGA4.send('page_view');
};

const logLogin = () => {
    ReactGA4.send('login');
};

const logSignup = () => {
    ReactGA4.send('signup');
};



const logEvent = (eventName, eventParams) => {
    ReactGA4.event(eventName, eventParams);
};

export { initGA4, logPageView, logLogin, logSignup, logEvent }
