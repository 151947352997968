import React from 'react'

const BubbleRadarChart = ({ data, strengths }) => {
    const chartSize = 450; // Chart size
    const radius = chartSize / 2 - 50; // Radius of the radar, with padding for names
    const numAxes = strengths.length; // Number of axes (team strengths)
    const maxValue = 9; // Maximum value in the grid (always from 0 to 9)

    // Function to calculate the position based on the angle and strength value
    const getPosition = (strengthIndex, value) => {
        const angle = (Math.PI * 2 * strengthIndex) / numAxes; // Calculate angle for the axis
        const distanceFromCenter = (radius / maxValue) * value; // Distance based on value (scale 0-9)
        const x = chartSize / 2 + distanceFromCenter * Math.cos(angle); // X position
        const y = chartSize / 2 - distanceFromCenter * Math.sin(angle); // Y position (SVG Y-axis is flipped)
        return { x, y };
    };

    // Function to count occurrences of each strength value
    const getBubbleData = () => {
        const bubbleData = Array.from({ length: numAxes }, () => ({}));

        data.forEach((member) => {
            member.forEach((strengthValue, index) => {
                if (!bubbleData[index][strengthValue]) {
                    bubbleData[index][strengthValue] = 0;
                }
                bubbleData[index][strengthValue]++;
            });
        });

        return bubbleData;
    };

    const bubbleData = getBubbleData();

    // Function to get transparent shades of #3498db (blue) based on value (1-9)
    const getBlueShade = (value) => {
        const lightness = 100 - (value / maxValue) * 50; // Adjusts lightness from 100% to 50%
        return `hsla(204, 70%, ${lightness}%, 0.7)`; // 0.7 is transparency for all values.
    };

    // Find the max value for each axis to control the sector shading
    const getMaxValues = () => {
        const maxValues = Array(numAxes).fill(0);
        data.forEach((member) => {
            member.forEach((strengthValue, index) => {
                maxValues[index] = Math.max(maxValues[index], strengthValue);
            });
        });
        return maxValues;
    };

    const maxValues = getMaxValues();

    // Function to generate the path for each curved sector
    const getArcPath = (axisIndex, value) => {
        const startAngle = (Math.PI * 2 * axisIndex) / numAxes;
        const endAngle = (Math.PI * 2 * (axisIndex + 1)) / numAxes;
        const innerRadius = (radius / maxValue) * value;
        const outerRadius = (radius / maxValue) * (value + 1);

        // Outer arc start and end points
        const startXOuter = chartSize / 2 + outerRadius * Math.cos(startAngle);
        const startYOuter = chartSize / 2 - outerRadius * Math.sin(startAngle);

        const endXOuter = chartSize / 2 + outerRadius * Math.cos(endAngle);
        const endYOuter = chartSize / 2 - outerRadius * Math.sin(endAngle);

        // Inner arc start and end points
        const startXInner = chartSize / 2 + innerRadius * Math.cos(startAngle);
        const startYInner = chartSize / 2 - innerRadius * Math.sin(startAngle);

        const endXInner = chartSize / 2 + innerRadius * Math.cos(endAngle);
        const endYInner = chartSize / 2 - innerRadius * Math.sin(endAngle);

        // Arc commands:
        // A rx ry x-axis-rotation large-arc-flag sweep-flag x y
        // sweep-flag = 1 ensures the arc is drawn clockwise
        return `
            M ${startXInner},${startYInner}
            L ${startXOuter},${startYOuter}
            A ${outerRadius},${outerRadius} 0 0 0 ${endXOuter},${endYOuter}
            L ${endXInner},${endYInner}
            A ${innerRadius},${innerRadius} 0 0 1 ${startXInner},${startYInner}
        `;
    };

    return (
            <svg width={'500'} height={chartSize} style={{margin: '0 20px 0 20px',display: 'inline-block'}}>
            {/* Draw circular grid (from 0 to 9) */}
            {[...Array(maxValue + 1)].map((_, i) => {
                const r = (radius / maxValue) * i;
                return (
                    <circle
                        key={i}
                        cx={chartSize / 2}
                        cy={chartSize / 2}
                        r={r}
                        stroke="#ddd"
                        fill="none"
                    />
                );
            })}

            {/* Add scale text (0 to 9) on the grid */}
            {[...Array(maxValue + 1)].map((_, i) => {
                const r = (radius / maxValue) * i;
                return (
                    <g key={i}>
                        {/* Top position */}
                        <text
                            x={chartSize / 2}
                            y={chartSize / 2 - r - 5} // Slightly above the grid circle
                            textAnchor="middle"
                            fill="#333"
                            fontSize="10"
                        >
                            {i}
                        </text>
                        {/* Right position */}
                        <text
                            x={chartSize / 2 + r + 5} // Slightly right of the grid circle
                            y={chartSize / 2}
                            textAnchor="middle"
                            fill="#333"
                            fontSize="10"
                        >
                            {i}
                        </text>
                        {/* Bottom position */}
                        <text
                            x={chartSize / 2}
                            y={chartSize / 2 + r + 15} // Slightly below the grid circle
                            textAnchor="middle"
                            fill="#333"
                            fontSize="10"
                        >
                            {i}
                        </text>
                        {/* Left position */}
                        <text
                            x={chartSize / 2 - r - 10} // Slightly left of the grid circle
                            y={chartSize / 2}
                            textAnchor="middle"
                            fill="#333"
                            fontSize="10"
                        >
                            {i}
                        </text>
                    </g>
                );
            })}

            {/* Draw radar axes */}
            {strengths.map((strength, index) => {
                const { x, y } = getPosition(index, maxValue); // Position at maximum value (9)
                return (
                    <g key={strength}>
                        {/* Draw the axis line */}
                        <line
                            x1={chartSize / 2}
                            y1={chartSize / 2}
                            x2={x}
                            y2={y}
                            stroke="#ccc"
                            strokeWidth="1"
                        />
                        {/* Display the team strength name with some padding */}
                        <text
                            x={x}
                            y={y}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fontSize="12"
                            fontWeight="bold"
                            dx={x > chartSize / 2 ? 35 : -10} // Add horizontal padding
                            dy={y > chartSize / 2 ? 25 : -15} // Add vertical padding
                            fill="#333"
                        >
                            {strength}
                        </text>
                    </g>
                );
            })}

            {/* Draw curved blue sectors up to the maximum value for each axis */}
            {strengths.map((_, axisIndex) => {
                return [...Array(maxValues[axisIndex])].map((_, value) => {
                    const arcPath = getArcPath(axisIndex, value); // Get path for the curved sector
                    return (
                        <path
                            key={`${axisIndex}-${value}`}
                            d={arcPath}
                            fill={getBlueShade(value + 1)}
                            stroke="none"
                        />
                    );
                });
            })}

            {/* Draw bubbles */}
            {bubbleData.map((axisData, axisIndex) =>
                Object.keys(axisData).map((value) => {
                    const count = axisData[value]; // How many members have this strength value
                    const { x, y } = getPosition(axisIndex, value); // Position of the bubble
                    const bubbleRadius = count * 5; // Size of the bubble based on the count
                    return (
                        <g key={`${axisIndex}-${value}`}>
                            <circle cx={x} cy={y} r={bubbleRadius} fill="rgba(255, 99, 132, 0.6)" />
                            <text
                                x={x}
                                y={y}
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill="white"
                                fontSize="12"
                            >
                                {count}
                            </text>
                        </g>
                    );
                })
            )}
        </svg>
    );
};

export default BubbleRadarChart
