import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg';
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";
import InputError from '../../components/formComponents/InputError';

import Loading from '../../components/reusableElementsComponents/Loading';
import Button from '../../components/reusableElementsComponents/Button';

import MazhrDD from '../../components/formComponents/MazhrDD';

import { useNavigate } from "react-router-dom";

import MobileAuthHeader from '../../components/layoutComponents/authComponents/MobileAuthHeader';
import DesktopAuthDecoration from '../../components/layoutComponents/authComponents/DesktopAuthDecoration';

import B2BApi from "../../B2bApi";
import B2BApiService from "../../B2bApiService";
import * as Constants from "../../constants";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { BsArrowLeft } from "react-icons/bs";
import {FormControl, TextField} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import loading from '../../assets/images/icons/loading.gif';

import { inputLabelClasses } from "@mui/material/InputLabel";

function RegistrationStage2 () {
    const defaultValues = {
        company_name: "",
        phone_number: "",
        industry_id: "",
        employee_count : ""
    };

    const defaultErrorState = {
        company_name: false,
        phone_number: false,
        industry_id: false,
        employee_count: false
    };

    const defaultErrorMessages = {
        company_name: "Company name already registered",
        phone_number: "Please enter a valid phone number",
        industry_id : "Please select relevant industry for your company",
        employee_count : "Please select number of employees in your company"
    }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const data = useSelector(state => state.contentfulData.all['Company registration page (stage2)']);

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState(defaultValues)
    const [formErrors, setFormErrors] = useState(defaultErrorState)
    const [formErrorMessages, setFormErrorMessages] = useState(defaultErrorMessages)
    const [industries, setIndustries] = useState({})
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    let [customInputError, setCustomInputError] = useState(false);
    let [customInputErrorText, setCustomInputErrorText] = useState('');
    const [formValidate, setFormValidate] = useState(false);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });

        if(!value){
            updateFormErrorsObject(name, true)
        }
    };

    const handleInputBlur = (e) =>{
        const { name, value } = e.target;
        let valid = false;
        if(value !== undefined && value.length > 0){
            valid = validateFieldValues(name, value)
        }

        if(valid && name === 'company_name'){
            b2bServiceObj.CompanyByNameExist(value, (exist) => {
                updateFormErrorsObject('company_name', exist)
            })
        }

        if(valid && name === 'phone_number'){
            b2bServiceObj.PhoneNumberExist(value, (exist) => {
                updateFormErrorsObject('phone_number', exist)
                setFormErrorMessages({...formErrorMessages , phone_number : "Phone number already exists."})
            })
        }
    }

    const ddOnSelectJobIndustry = (event) => {
        for (let i = 0; i < industries.length; i++) {
            if (industries[i].title === event) {
                let regData = JSON.parse(localStorage.getItem(Constants.MAZHR_REG_DATA));

                regData.industry_id = industries[i].id.toString();
                localStorage.setItem(Constants.MAZHR_REG_DATA, JSON.stringify(regData))
            }
        }
    }

    const ddOnSelectEmployees = (value) => {
        let regData = JSON.parse(localStorage.getItem(Constants.MAZHR_REG_DATA));
        regData.employee_count = value;
        localStorage.setItem(Constants.MAZHR_REG_DATA, JSON.stringify(regData))
    }

    const updateFormErrorsObject = (name, value) =>{
        setFormErrors({
            ...formErrors,
            [name]: value,
        });
    }

    const validateFieldValues = (name, value) =>{
        let valid;
        switch (name){
            case 'phone_number':
                valid = Constants.VALID_PHONE.test(value);
                updateFormErrorsObject(name,!valid);
                if(!valid){
                    setError(true);
                    setFormErrorMessages({...formErrorMessages , phone_number : "Please enter a valid phone number"})
                }
                break;
            case 'company_name':
                valid = Constants.VALID_NAME.test(value);
                updateFormErrorsObject(name,!valid);
                if(!valid){
                    setError(true);
                    setFormErrorMessages({...formErrorMessages , company_name : "Please enter company name"})
                }
                break;
            default:
                valid = true;
                setError(false);
                break;
        }
        return valid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        let keys = Object.keys(formValues);
        let errorFound = true;
        for(let index = 0; index < keys.length; index++){
            errorFound = !validateFieldValues(keys[index], formValues[keys[index]]);
            if(errorFound){
                break;
            }
        }

        if(!errorFound){
            let regData = JSON.parse(localStorage.getItem(Constants.MAZHR_REG_DATA));
            setFormValidate(true)
            regData.company_name = formValues.company_name;
            regData.contact_phone = formValues.phone_number;

            let obj = new B2BApi();
            obj.apiCall('company/register/', 'post', JSON.stringify(regData)).then(response => {
                setFormValidate(true);
                if (response.httpStatus === 201) {
                    localStorage.removeItem(Constants.MAZHR_REG_DATA);
                    localStorage.setItem(Constants.MAZHR_JWT_KEY, JSON.stringify(response.json));
                    b2bServiceObj.LoadCompanyData()
                    setLoader(false);
                    navigate("/");
                    window.location.reload();
                } else {
                    setLoader(false);
                    setError(true);
                    setTimeout(() => setError(false), 5000);
                    setCustomInputError(customInputError);
                    if (response && response.json && response.json.errors) {
                        setError(true);
                        setCustomInputErrorText(JSON.stringify(response.json.errors.json));
                    }
                }
            });
        }
    };

    useEffect(() => {
        b2bServiceObj.LoadIndustriesList(setIndustries)
    }, [b2bServiceObj, setIndustries]);

    if (data !== undefined) {
        let images = data.images;
        let content = data.fields;

        return (
            <div className="row auth-page-wrapper p-0 m-0">
                <img className='company-logo' src={mazharLogo} alt="Apex Analytics"/>
                <MobileAuthHeader logo={true} subTitle={content.subTitleMobile} />
                <div className="col-12 col-lg-6 login-form">
                    <div className="section d-none d-lg-block">
                        <h3 className="title semi-bold">{content.titleDesktop}</h3>
                        <p className="grey s">{content.subTitleDesktop}</p>
                    </div>
                    <form className='form' onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } }}}
                                placeholder="Start typing..."
                                id="company_name"
                                label={content.companyName}
                                name="company_name"
                                type="text"
                                value={formValues.company_name}
                                error= {formErrors.company_name}
                                helperText={formErrors.company_name && formErrorMessages.company_name}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <BusinessIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br/>
                            <TextField
                                InputLabelProps={{sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } }}}
                                placeholder="Start typing..."
                                id="phone_number"
                                label={content.phoneNumber}
                                name="phone_number"
                                type="text"
                                value={formValues.phone_number}
                                error= {formErrors.phone_number}
                                helperText={formErrors.phone_number && formErrorMessages.phone_number}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIphoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br/>
                            {industries.length > 0 &&
                            <MazhrDD
                                id={'industry'}
                                options={industries}
                                errorContent={"Select business type"}
                                defaultValue={"Select business type"}
                                onSelectFunc={ddOnSelectJobIndustry}
                                doValidation={formValidate}
                            />
                            }

                            {content?.emploeesDropdownFields && <MazhrDD
                                options={content.emploeesDropdownFields}
                                errorContent={"Please select company size"}
                                defaultValue={'Select number of employees'}
                                onSelectFunc={ddOnSelectEmployees}
                                doValidation={formValidate}
                            />}
                        </FormControl>

                        {customInputErrorText && <InputError errorMessage={customInputErrorText} />}
                        <h1>{customInputErrorText}</h1>

                        <div className="authButtons">
                            <Button btnstyle={'mazhrBtn default-green white full-width'} type="submit">
                                {loader && !error ? <img className='loader' src={loading} alt="Loading..." /> : <p className='m-0 light'>{content.signUpButton}</p>}
                            </Button>
                            <Link to="/login" className="">
                                {content.signInButton}
                            </Link>
                        </div>
                        <Button btnstyle={'link grey mar-20 top'} type='button' onClick={() => navigate('/register')}>
                            <BsArrowLeft /><span className='mar-5 left'>Previous</span>
                        </Button>
                    </form>
                </div>
                <DesktopAuthDecoration illustration={images.illustration} />
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default RegistrationStage2;
