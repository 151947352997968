import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const TeamSimulatorVerticalCharts = ({labels, employeesData, candidatesData, chartTitle, leftChartLabel = 'Employees Averages', rightChartLabel = 'Candidates Averages'}) => {
    const [employeeChartData, setEmployeeChartData] = useState({});
    const [candidateChartData, setCandidateChartData] = useState({});
    const [employeeChartOptions, setEmployeeChartOptions] = useState({});
    const [candidateChartOptions, setCandidateChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const data = {
            labels: labels,
            datasets: [
                {
                    label: leftChartLabel,
                    categoryPercentage: 1.0,
                    barPercentage: 1.0,
                    barThickness: 15,
                    backgroundColor: (context) => {
                        const value = context.dataset.data[context.dataIndex];
                        return value > 4 ? '#00B0A0' : '#59287A';
                    },
                    borderColor: 'rgb(53, 196, 220)',
                    data: employeesData
                }
            ]
        };



        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: leftChartLabel,
                    fontColor: textColor
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        },
                        stepSize: 1 // You can adjust this value based on your data range and requirements
                    },
                    grid: {
                        display: true,
                        drawBorder: true
                    },
                    reverse: true,
                    beginAtZero: true,
                    min: 0,
                    max: 9,
                },
                y: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };


        const dataCandidates = {
            labels: labels,
            datasets: [
                {
                    label: rightChartLabel,
                    categoryPercentage: 1.0,
                    barPercentage: 1.0,
                    barThickness: 15,
                    backgroundColor: (context) => {
                        const value = context.dataset.data[context.dataIndex];
                        return value > 4 ? '#00B0A0' : '#59287A';
                    },
                    borderColor: 'rgb(53, 196, 220)',
                    data: candidatesData
                }
            ]
        };

        const optionsCandidates = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display : false
                },
                title: {
                    display: true,
                    text: rightChartLabel,
                    fontColor: textColor
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        },
                        stepSize: 1 // You can adjust this value based on your data range and requirements
                    },
                    grid: {
                        display: true,
                        drawBorder: true
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 9,
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        crossAlign: "center"
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    },
                }
            }
        };
        setEmployeeChartData(data);
        setEmployeeChartOptions(options);

        setCandidateChartData(dataCandidates)
        setCandidateChartOptions(optionsCandidates);
    }, [labels, employeesData, candidatesData, rightChartLabel, leftChartLabel]);

    return (
        <div style={{width:"100%"}}>
        <div className="title">
            <p className='m-0'>
                {chartTitle}
            </p>
        </div>
        <div className='vertical-chart-div'>
            <div style={{ display: 'flex' ,height: "300px", width: "100%"}}>
                <div style={{ flex: '30%', padding: '10px 0 10px 10px' }}>
                    <Chart height='300px' width='100%' type="bar" data={employeeChartData} options={employeeChartOptions} />
                </div>
                <div style={{ flex: '35%', padding: '10px 0px 10px 0px' }}>
                    <Chart height='300px' width='100%' type="bar" data={candidateChartData} options={candidateChartOptions} />
                </div>
            </div>
        </div>
        </div>
    )
}
export default TeamSimulatorVerticalCharts;
