import React, {useState} from 'react';
import TabsMenu from '../../components/reusableElementsComponents/TabsMenu';
import HelpCenter from './HelpCenter';
import Onboarding from './Onboarding';
import ServiceUse from './serviceUse';
import Coaching from './Coaching';

export default function FaqPage() {
    const [tabActiveKey, setTabActiveKey] = useState("0");
    const activeKey = (key) => {
        setTabActiveKey(key)
    }
    const HelpCenterMemoized = React.memo(HelpCenter);


    const template = {
        tabs: [
            {
                component: <Onboarding tabActiveKey={tabActiveKey}/>,
                sectionTitle: 'Getting Started'
            },
            {
                component: <ServiceUse tabActiveKey={tabActiveKey}/>,
                sectionTitle: 'Service Use'
            },
            {
                component: <Coaching tabActiveKey={tabActiveKey}/>,
                sectionTitle: 'Analytics'
            },
            {
                component: <HelpCenterMemoized tabActiveKey={tabActiveKey}/>,
                sectionTitle: 'Billing & Account'
            },

        ]
    }

    return (
        <div className="bg-cover" style={{width: "100%"}}>
            <div style={{height: "100%"}}>
                <div className='img-div'>
                    <div className='first-head'>
                        <span className='first-head-text'>Hello, how can we help?</span>
                    </div>
                    <div className="tabs-wrapper tabs-styling tabs-bottom-alignment">
                        <TabsMenu template={template} activeKey={activeKey} tabActiveKey={tabActiveKey}/>
                    </div>
                </div>
            </div>

        </div>
    )
}
