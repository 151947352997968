import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Dropdown from "./Dropdown";
import DropdownOptions from './DropdownOptions';
import UploadImage from './UploadImage';


function Form({ template, onSubmit, children, uploadedStateFunc}) {
    const [ checkValue, setCheckValue ] = useState(true);

    let { register, handleSubmit, formState: { errors } } = useForm();
    const [fileUploaded, setFileUploaded] = useState(false)
    let { fields } = template;

    function updatingUploadImageState(state){
        setFileUploaded(!fileUploaded)
        uploadedStateFunc(state)
    }



    const renderFields = (fields) => {
        return fields?.map((field, index) => {
            let {
                title,
                type,
                name,
                defaultValue,
                link,
                placeholder,
                icon,
                validationProps,
                dropdown,
                datalist,
                dropdownTemplate
            } = field;

            name = name.replace(/\s+/g, '').toLowerCase();

            const inputRegister = register(name, validationProps, dropdownTemplate);

            function handleChange(e) {
                if(icon) {
                    if (e.target.value !== '') {
                        document.getElementById('icon' + index).classList.add('active');
                    }else {
                        document.getElementById('icon' + index).classList.remove('active');
                    }
                }
            }

            function handleCheck() {
                setCheckValue(!checkValue);
            }

            switch(type){
                case 'text':
                case 'password':
                case 'email':
                case 'tel':
                    return (
                        <div key={index} className="form-field">
                            <label htmlFor={name}>{title}</label>
                            <div className="input-wrapper">
                                {dropdown ?
                                    <Dropdown
                                        id={name}
                                        dropdownTemplate={dropdownTemplate}
                                        defaultValue={defaultValue}
                                        onSelectOption={handleChange}
                                        refs={inputRegister}
                                    />
                                :
                                datalist ?
                                    <>
                                        <label htmlFor={name}>
                                            <input  type={type} placeholder={placeholder} list={name} name={name} />
                                        </label>
                                        <datalist type={type} name={name} id={name} {...inputRegister }>
                                            <DropdownOptions
                                                dropdownTemplate={dropdownTemplate}
                                                name={name}
                                                type={type}
                                                id={name}
                                                placeholder={placeholder}
                                            />
                                        </datalist>
                                    </>
                                :
                                <input
                                    type={type}
                                    name={name}
                                    id={name}
                                    placeholder={placeholder}
                                    defaultValue={defaultValue}
                                    autoComplete="on"
                                    {...inputRegister }
                                    onChange={e => {
                                        inputRegister.onChange(e);
                                        handleChange(e);
                                    }}
                                />
                                }
                                {icon ? <img id={'icon' + index} className="icon" src={icon} alt="icon"/> : ''}
                            </div>
                            { errors[name] && <p className='dark-pink'>{errors[name]['message']}</p>}
                        </div>
                    )
                case 'checkbox':
                    return (
                        <div key={index} className="form-field mazhrCheckbox">
                            <label className="checkbox-label">
                                <input className="checkbox"
                                    type="checkbox"
                                    name={name}
                                    id={name}
                                    {...inputRegister }
                                    onChange={e => {
                                        inputRegister.onChange(e);
                                        handleCheck(e);
                                    }}
                                />
                                {link ? <a className='grey underline' href={link} target='_blank' rel="noreferrer">{title}</a> : <p className='mar-5 left'>{title}</p>}

                                { errors[name] &&  <p className='dark-pink'>{errors[name]['message']}</p>}
                            </label>
                        </div>
                    )
                case 'url':
                    return (
                        <div key={index} className="form-field">
                            <label htmlFor={name}>{title}</label>
                            <input
                                type="url"
                                name={name}
                                id={name}
                                {...inputRegister }
                                onChange={e => {
                                    inputRegister.onChange(e);
                                    handleChange(e);
                                }}
                            />
                            { errors[name] && <p>{errors[name]['message']}</p>}
                        </div>
                    )
                case 'textArea':
                    return (
                        <div key={index} className="form-field">
                            <p key={name} className="m-0 editable"
                                contentEditable
                                type={type}
                                name = {name}
                                id={name}
                                placeholder={placeholder}
                                {...inputRegister }
                                onChange={e => {
                                    inputRegister.onChange(e);
                                    handleChange(e);
                                }}
                                suppressContentEditableWarning={true}
                            ></p>
                        </div>
                    )
                case 'file':
                    return (
                        <div key={index} className="form-field">
                            <UploadImage updatingUploadImageState={updatingUploadImageState} name={name} placeholder={placeholder} defaultValue={defaultValue} type='file'/>
                        </div>
                    )
                default:
                    return (
                        <div key={name}>
                            <p>Invalid field</p>
                        </div>
                    )

            }
        })
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            { renderFields(fields)}
            {children}
        </form>
    );
}

export default Form;

