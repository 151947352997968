import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { marked } from 'marked';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ServiceUse() {
  const contentfulData = useSelector(state => state?.contentfulData?.all['faq-service-use']);
  const [expanded, setExpanded] = useState(null);

  const handleChange = index => (event, isExpanded) => {
    setExpanded(isExpanded ? index : null);
  }

  const getMarkdownText = (unParsed) => {
    var rawMarkup = marked.parse(unParsed);
    return { __html: rawMarkup };
  }


  return (
    <div className="accordion-main-div">
      <div className="accordion-wrapper-div">

        {contentfulData &&
          contentfulData?.map((faqObject, index) => {
            return (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography>{<span className='question-accordion' dangerouslySetInnerHTML={getMarkdownText(faqObject?.question)}></span>}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{<span className='answer-accordion' dangerouslySetInnerHTML={getMarkdownText(faqObject?.answer)}></span>}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}
