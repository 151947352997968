// LoomEmbed.js
import React from 'react';

const LoomEmbed = ({ src, width, height }) => {
    const containerStyle = {
        position: 'relative',
        paddingBottom: `${(height / width) * 100}%`,
        height: 0,
    };

    const iframeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };

    return (
        <div style={containerStyle}>
            <iframe
                src={src}
                frameBorder="0"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                style={iframeStyle}
                title="Loom Video"
            />
        </div>
    );
};

export default LoomEmbed;
