function Button({ children, onClick, btnstyle, type, disabled, id }) {
    return (
        <>
            <button onClick={onClick} className={btnstyle} type={type} disabled={disabled} id={id}>
                {children}
            </button>
        </>
    )
}

export default Button;
