import React from 'react'

function Loader() {
  return (
    <div className="loader" id="loader-2">
        <span></span>
        <span></span>
        <span></span>
    </div>
  )
}

export default Loader





