import React, {useEffect, useState} from "react";
import * as Constants from '../../constants'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const HubspotContactForm = ({portalId, formId, target}) => {
    const [formLoaded, setFormLoaded] = useState(false)
    useEffect(() => {
        const script = document.createElement('script');
        script.src=Constants.HUBSPOT_FORMS_JS_URL;
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (!formLoaded && window.hbspt) {
                setFormLoaded(true)
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: '#' + target
                })
            }
        });
    }, [formLoaded, portalId, formId, target]);

    return (
        <div className="hubspot-form-div">
            <div id={target}>
                {!formLoaded && <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>}
            </div>
        </div>
    );

}

export default HubspotContactForm;
