import { Link } from 'react-router-dom';
import Form from '../../components/reusableElementsComponents/Form';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg';
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";

import person from '../../assets/images/icons/person.svg';
import letter from '../../assets/images/icons/letter.svg';

import MobileAuthHeader from '../../components/layoutComponents/authComponents/MobileAuthHeader';
import DesktopAuthDecoration from '../../components/layoutComponents/authComponents/DesktopAuthDecoration';

import Loading from '../../components/reusableElementsComponents/Loading';
import { useSelector } from 'react-redux';


function RegistrationStage2() {
    const data = useSelector( state => state.contentfulData.all['Company registration page (stage3)']);

    if(data !== undefined){
        let images = data.images;
        let content = data.fields;

        let template = {
            fields: [
                {
                    title: content.fullName,
                    type: 'text',
                    name: content.fullName,
                    placeholder: content.inputPlaceholder,
                    icon: person
                },
                {
                    title: content.email,
                    type: 'text',
                    name: content.email,
                    placeholder: content.inputPlaceholder,
                    icon: letter
                }
            ]
        }

        return (
            <div className="row auth-page-wrapper p-0 m-0">
                <img className='company-logo' src={mazharLogo} alt="Apex Analytics"/>
                <MobileAuthHeader illustration={images.register3illustration} subTitle={content.subTitleMobile}/>
                <div className="col-12 col-lg-6 login-form">
                    <div className="section d-none d-lg-block">
                        <h3 className="title semi-bold">{content.titleDesktop}</h3>
                        <p className="grey s">{content.subTitleDesktop}</p>
                    </div>
                    <div className="section">
                        <Form template={template} />
                        <div className="authButtons">
                            <Link className="mazhrBtn light-green" to="/">{content.inviteButton}</Link>
                            <Link className="mazhrBtn default-green white" to="/dashboard">{content.finishButton}</Link>
                        </div>
                    </div>
                </div>
                <DesktopAuthDecoration illustration={images.registration3desktopIllustration} />
            </div>
        )
    }else {
        return <Loading />
    }
}

export default RegistrationStage2;
