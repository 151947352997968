import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from '../../components/reusableElementsComponents/Loading';
import Button from '../../components/reusableElementsComponents/Button';


function ErrorPage() {
    const data = useSelector( state => state.contentfulData.all['404 page not found']);

    if(data !== undefined){
        let images = data.images;
        let content = data.fields;

        return (
            <div className="error-page center-content">
                <img className="error-img" src={images.error404} alt={content.errorStatusCode + content.errorTitle} />
                <h1 className="error-message font-rounded semi-bold">{content.errorTitle}</h1>
                <Button btnstyle={'mazhrBtn default-green-border'} >
                    <Link to="/">
                        {content.backHomeButton}
                    </Link>
                </Button>
            </div>
        )
        
    }else {
        return <Loading />
    } 
}
    
export default ErrorPage;