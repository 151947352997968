import Button from "../../components/reusableElementsComponents/Button";
function ComingSoon({heading,content,ilustration,videoLink,feedbackormHeading,feedbackForm}) {

    return (
        <div className="coming-soon-page center-content">
            <div className='copy-wrapper'>
                <div className='content'>
                    <p className='heading xl bold'>{heading}</p>
                    <p className='text-body'>{content}</p>
                    <div className='button-wrapper'>
                        {videoLink && <Button onClick={()=>window.open(videoLink, '_blank').focus()} btnstyle={"mazhrBtn default-green"}>Watch demo video</Button>}
                    </div>
                </div>
                <div className='illustration'>
                    <img className="comingSoon-img" src={ilustration} alt='page is under construction and coming soon' />
                </div>
            </div>


            <div className='form-wrapper'>
                <p className='heading'>{feedbackormHeading}</p>
                {feedbackForm}
            </div>
        </div>
    )
}

export default ComingSoon;
