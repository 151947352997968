import { useState, useRef, useEffect } from 'react';
import PageLayout from "../../components/layoutComponents/PageLayout";
import Button from "../../components/reusableElementsComponents/Button";
import listIcon from "../../assets/images/icons/list.svg";
import TabsMenu from "../../components/reusableElementsComponents/TabsMenu";
//import KeyAnalytics from "../../components/Analytics/keyAnalytics/KeyAnalytics";
//import BehaviourStrengths from '../../components/Analytics/behaviourStrengths/behaviourStrengths';
import CompetencePoints from '../../components/Analytics/competencePoints/competencePoints';
import MotivationStrengths from '../../components/Analytics/motivationStrengths/motivationStrengths';
import SalesReport from "../../components/Analytics/salesReportComponent/salesReport";
import MazhrModal from "../../components/reusableElementsComponents/MazhrModal";
import {Checkbox} from '../../components/reusableElementsComponents/Checkbox';
import TeamStrengths from "../../components/Analytics/teamStrengths/teamStrengths";
import B2BApi from '../../B2bApi'
import BehaviourStrengths from "../../components/Analytics/behaviourStrengths/behaviourStrengths";
import AdeptAspects from "../../components/Analytics/adeptBehaviours/adeptAspects";
import {useDispatch} from "react-redux";
import {fetchContentfulAnalyticsData} from "../../store/actions/contentfulAnalyticsDataActions";

function PoolAnalytics() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [tabActiveKey, setTabActiveKey] = useState("1");
  let [pools, setPools] = useState([]);

  const firstInputRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContentfulAnalyticsData());
  }, [dispatch]);

  useEffect(() => {
    const obj = new B2BApi();
    obj.apiCall('pool/', 'get').then((res) => {
      res = res.json?.data;
      if (res) {
        let newPools = [];
        for (let index = 0; index < res.length; index++) {
          newPools.push({ id: res[index]?.hash, name: res[index]?.title });
        }
        setPools([...newPools]);

      }
    })
  }, [])


  const activeKey = (key) => {
    key = (+key + 1).toString();
    setTabActiveKey(key)
  }



  const template = {
    tabs: [
      {
        component: (
            <BehaviourStrengths
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: 'BEHAVIOURS'
      },
      {
        component: (
            <CompetencePoints
                allTeams={selectedTeams}
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: "WORK STYLES",
      },
      {
        component: (
            <MotivationStrengths
                allTeams={selectedTeams}
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                setTabActiveKey={setTabActiveKey}
                activeKey={activeKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: "MOTIVATIONS",
      },
      {
        component: (
            <SalesReport
                allTeams={selectedTeams}
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: "SALES STRENGTHS",
      },
      {
        component: (
            <TeamStrengths
                allTeams={selectedTeams}
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: "TEAM STRENGTHS",
      },
      {
        component: (
            <AdeptAspects
                allTeams={selectedTeams}
                selectedTeams={selectedTeams}
                tabActiveKey={tabActiveKey}
                is_pool_list={true}
            />
        ),
        sectionTitle: "ADEPT-15",
      },
    ],
  };



  const toggleModalOpenState = () => {
    setModalIsOpen(state => !state);
  };

  const handleOnChange = e => {
    const { name, checked, value } = e.target;

    setSelectedItems(teams => ({
      ...teams,
      [name]: checked
    }));

    if (checked) {
      selectedTeams.push({ name: name, hash: value });
    } else {
      const index = selectedTeams.findIndex(item => item.hash === value);
      if (index !== -1) {
        selectedTeams.splice(index, 1);
      }
    }
    setSelectedTeams([...selectedTeams])
  };

  const handleClick = (e, team) => {
    const deletionObject = selectedTeams.find((obj) => obj.hash === team.hash)
    const { name } = deletionObject
    const deletionObjectIndex = selectedTeams.findIndex(obj => obj.hash === team.hash)
    selectedTeams.splice(deletionObjectIndex, 1)
    setSelectedTeams([...selectedTeams])

    const updatedDisplayTeams = displayTeams.map((obj) => {
      if (obj.key === deletionObject.hash) {
        return {
          ...obj,
          props: {
            ...obj.props,
            children: [
              {
                ...obj.props.children[0],
                props: {
                  ...obj.props.children[0].props,
                  isChecked: false
                }
              },
              ...obj.props.children.slice(1)
            ]
          }
        }
      }
      return obj
    })

    displayTeams = updatedDisplayTeams
    setSelectedItems({
      ...selectedItems,
      [name]: false
    })
  }

  const handleSubmit = () => {
    JSON.stringify(
      Object.keys(selectedItems).reduce((teams, key) => {
        if (selectedItems[key]) {
          return [...teams, key];
        }
        return teams;
      }, [])
    )
    toggleModalOpenState();
  }

  var displayTeams = pools && pools.map((team, index) => (

    <div key={team.id} className="team-card">
      <Checkbox
        isChecked={selectedItems[team.name] || false}
        onChange={handleOnChange}
        name={team.name}
        value={team?.id}
        innerRef={index === 0 ? firstInputRef : null}
      />
      <p className='m-10 left'>{team.name}</p>
    </div>
  ))


  return (
    <PageLayout>
      <div className="full-width p-20 desktop">
        <Button btnstyle={'mazhrBtn default-green upper'} onClick={toggleModalOpenState}>
          <img style={{ width: '15px', height: '15px' }} className="m-10 right" src={listIcon} alt="list" />
          All Positions
        </Button>
        <MazhrModal show={modalIsOpen} onHide={toggleModalOpenState}>
          <div className="team-card-wrapper">
            {displayTeams}
          </div>
          <Button btnstyle={'mazhrBtn default-green upper mar-25 top'} onClick={handleSubmit}>Select positions</Button>
        </MazhrModal>
        {selectedTeams.length > 0 &&
          <div className="selected-teams-wrapper flex mar-15 top">
            {selectedTeams.map((team, index) => {
              return (
                <Button btnstyle={'mazhrBtn light-green m-10 right upper'} key={index}>

                  <p className="regular m-0">{team.name}</p>
                  <img onClick={e => handleClick(e, team)} src="https://img.icons8.com/material-rounded/24/null/delete-sign.png" style={{ height: "18px", marginLeft: "10px", opacity: "0.5" }} alt="" />

                </Button>
              )
            })}
          </div>

        }
      </div>

      <div className="full-width p-20 mobile">
        <Button btnstyle={'mazhrBtn default-green upper'} onClick={toggleModalOpenState}>
          <img style={{ width: '15px', height: '15px' }} className="m-10 right" src={listIcon} alt="list" />
          All Positions
        </Button>
        <MazhrModal show={modalIsOpen} onHide={toggleModalOpenState}>
          <div className="team-card-wrapper">
            {displayTeams}
          </div>
          <Button btnstyle={'mazhrBtn default-green upper mar-25 top'} onClick={handleSubmit}>Select positions</Button>
        </MazhrModal>
        {selectedTeams.length > 0 &&
          <div className="selected-teams-wrapper flex mar-15 top">
            {selectedTeams.map((team, index) => {
              return (
                <Button btnstyle={'mazhrBtn light-green m-10 right upper'} key={index}>
                  <p className="regular m-0">{team?.name}</p>
                </Button>
              )
            })}
          </div>
        }
      </div>

      <div className="tabs-wrapper background-green">
        <TabsMenu template={template} activeKey={activeKey} />
      </div>
    </PageLayout>
  )
}

export default PoolAnalytics
