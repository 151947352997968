import { useState, useEffect, useMemo } from 'react'
import B2BApiService from "../../B2bApiService";
import TeamReviewGeneralTemplate from './TeamReviewGeneralTemplate';

function TeamReviewGeneral({ data }) {

    let [jobIndustries, setJobIndustries] = useState([]);
    let [, setEmailList] = useState([]);
    let [newTeamFormData, setNewTeamFormData] = useState(data);
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const handleTitleChange = (e) => {
        data['title'] = e.target.value
        setNewTeamFormData({ ...newTeamFormData, 'title': e.target.value });
    }

    const handleDescriptionChange = (e) => {
        data['description'] = e.target.value
        setNewTeamFormData({ ...newTeamFormData, 'description': e.target.value });
    }

    const handleChange = (value, key) => {
        data[key] = value
        setNewTeamFormData({ ...newTeamFormData, [key]: value });
    }

    const handleSelectIndustry = (e, id) => {
        if (e) {
            setSelectedDepartment(e.target.value)
            for (let i = 0; i < jobIndustries.length; i++) {
                if (jobIndustries[i].title === e.target.value) {
                    handleChange(jobIndustries[i].id.toString(), 'industry_id')
                    return;
                }
            }
        }
    }

    const handleEmailListChange = (value) => {
        setEmailList(value)
        data['email_list'] = value
        setNewTeamFormData({ ...newTeamFormData, 'email_list': value });
    }

    useEffect(() => {
        b2bServiceObj.LoadIndustriesList(setJobIndustries);
    }, [b2bServiceObj]);


    return (
        <TeamReviewGeneralTemplate
            handleTitleChange={handleTitleChange}
            jobIndustries={jobIndustries}
            handleSelectIndustry={handleSelectIndustry}
            selectedDepartment={selectedDepartment}
            handleDescriptionChange={handleDescriptionChange}
            handleEmailListChange={handleEmailListChange}
            create={true}
        />
    )
}

export default TeamReviewGeneral





