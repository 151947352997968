import B2BApi from "../../B2bApi";
import { useSelector } from 'react-redux';

import Loading from '../../components/reusableElementsComponents/Loading';
import Form from '../../components/reusableElementsComponents/Form';
import Button from '../../components/reusableElementsComponents/Button';

import lock from '../../assets/images/icons/lock.svg';

import { ToastContainer, toast } from 'react-toastify';

function ChangePassword() {
    const data = useSelector( state => state.contentfulData.all['change password']);

    if(data !== undefined){
        let template = {
            fields: [
                {
                    title: '',
                    type: 'password',
                    name: 'password',
                    placeholder: 'enter existing password',
                    icon: lock
                },
                {
                    title: '',
                    type: 'password',
                    name: 'new_password',
                    placeholder: 'enter new password',
                    icon: lock
                },
                {
                    title: '',
                    type: 'password',
                    name: 'confirm_password',
                    placeholder: 'confirm password',
                    icon: lock
                }
            ]
        }

        return (
            <div className='change-password-section wrapper-div'>
                <div className="change-password-inner-div">
                <ToastContainer />
                <Form
                template={template}
                watchFields={['password']}
                validate={validate}
                onSubmit={onSubmit}
                >
                     <Button type='submit' btnstyle={'mazhrBtn default-green white'} onSubmit={onSubmit}>
                        Change password
                    </Button>
                </Form>
                </div>
            </div>
        )
    }else {
        return (
            <Loading />
        )
    }

    function onSubmit(values) {
        let obj = new B2BApi();
        if(values.new_password !== values.confirm_password){
          toast.error("Password must be same",{hideProgressBar: true,closeOnClick:true});
          return;
          }
        const updatePasswordToast = toast.loading("Updating password...")
        obj.apiCall('user/password/','post',JSON.stringify({password:values.password,new_password:values.new_password})).then(data => {
            if(data.httpStatus === 200){
                if(data && !data.json){
                    if(data.error && data.error.code === 1002){
                        toast.update(updatePasswordToast, { render: "Invalid current password", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
                    }
                }else if(data.json.status){
                    toast.update(updatePasswordToast, { render: "Password updated successfully", type: "success", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
                }
            }else{
                toast.update(updatePasswordToast, { render: "Invalid values provided", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            }
        });
    }

      function validate(watchValues, errorMethods) {
          let { errors, setError, clearErrors } = errorMethods;

          // Password validation
            let validPassword = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

            if(watchValues['password'] && !validPassword.test(watchValues['password'])){
                if(!errors['password']) {
                    setError('password', {
                        type: 'manual',
                        message: 'Password must contain at least 8 characters, a capital letter, a number and a special symbol'
                    })
                }
            }else{
                if(errors['password'] && errors['password']['type'] === 'manual') {
                    clearErrors('password');
                }
            }
      }
}

export default ChangePassword
