import {Box, Checkbox, MenuItem, FormControlLabel, TextField} from "@mui/material";
import * as PropTypes from "prop-types";
import {useEffect, useMemo, useRef, useState} from "react";
import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import * as Constants from "../../constants";
import Button from "../reusableElementsComponents/Button";
import {Dialog} from 'primereact/dialog';
import FormControl from '@mui/material/FormControl';
import saveFilterIcon from '../../assets/images/icons/savedFilterIcon.svg'
import loading from '../../assets/images/icons/loading.gif'
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import B2BApiService from "../../B2bApiService";

function TalentProfileFilters(props) {

    const contentfulData = useSelector(state => state.contentfulData?.all['talent-data']?.fields);
    const b2bServiceObj = useMemo(() => {return new B2BApiService();}, []);
    const filterOptions = [
        { mainTitleId: 3, mainTitle: Constants.TALENT_SEARCH_FILTER_COMPETENCES },
        { mainTitleId: 0, mainTitle: Constants.TALENT_SEARCH_FILTER_TEAM_STRENGTHS },
        { mainTitleId: 1, mainTitle: Constants.TALENT_SEARCH_FILTER_SALES_STRENGTHS },
        {mainTitleId: 2, mainTitle: Constants.TALENT_SEARCH_FILTER_MOTIVATION_FACTORS}]

    const [selectedArray, setSelectedArray] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    let [eventualFilterArray, setEventualFilterArray] = useState([])

    const [saveFilterDialogeOpen, setSaveFilterDialogeOpen] = useState(false)
    const [filterName, setFilterName] = useState(''); // Initialize with an empty string
    const [visible, setVisible] = useState(false)
    const [savedFiltersList, setSavedFiltersList] = useState([])

    const [loader, setLoader] = useState(false);

    let [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null)

    const [tempArray, setTempArray] = useState([]);

    const [tempObject, setTempObject] = useState({
        mainTitleId: "",
        mainTitle: "",
        subTitleId: "",
        subTitle: "",
        isChecked: false,
        greaterVal: ""
    })

    const handleDropdownClick = () => {
        setTempArray([])
        selectedArray.map((obj) => {
            obj.isChecked = false
            return true
        })
        setIsOpen(!isOpen)
    }

    const handleFilterNameChange = (event) => {
        setFilterName(event.target.value); // Update the state when the input changes
    };

    const handleCheckboxCheck = (e, obj, index) => {
        let updatedItems = [...selectedArray];
        updatedItems[index].isChecked = !updatedItems[index].isChecked;
        setSelectedArray(updatedItems);
        setFilteredArray(updatedItems);
        const object = {
            mainTitleId: tempObject.mainTitleId, mainTitle: tempObject.mainTitle, subTitleId: obj.id, subTitle: obj.title, isChecked: e.target.checked, greaterVal: obj.greaterVal
        }
        if (e.target.checked) {
            setTempArray([...tempArray, object]);
        } else {
            setTempArray(tempArray.filter((o) => o.subTitleId !== obj.id));
        }
    }

    const handleDeleteChip = (index) => {
        let poppedObject = eventualFilterArray[index]
        let poppedObjectTalentSearchFilterKeyType = getTalentSearchFilterTypeKey(poppedObject)
        let subTitle = poppedObject.subTitle.toLowerCase().replace(/\s+/g, '_')
        eventualFilterArray.map((object) => {
            if(poppedObject.mainTitle === object.mainTitle && poppedObject.subTitle === object.subTitle){
                delete props.filtersKeyArray[poppedObjectTalentSearchFilterKeyType][poppedObject.mainTitle][subTitle]
                props.setFiltersKeyArray([...props.filtersKeyArray])
            }

            return true
        })
        eventualFilterArray.splice(index, 1)
        setEventualFilterArray([...eventualFilterArray])
        setSelectedFilter(null)
    }

    const handleGreaterTextChange = (e, obj) => {
        const inputValue = e.target.value;
        if (isNaN(inputValue)) {
            e.target.value = "";
        }
        let updatedObject = { ...tempObject, greaterVal: inputValue }
        setTempObject(updatedObject)
        tempArray.map((object) => {
            if (object.subTitleId === obj.id) {
                object.greaterVal = inputValue
            }
            return true
        })
    }

    const handleGreaterTextChangeSales = (e, obj) => {
        const inputValue = e.target.value;
        if (isNaN(inputValue)) {
            e.target.value = "";
        }
        let updatedObject = { ...tempObject, greaterVal: inputValue }
        setTempObject(updatedObject)
        tempArray.map((object) => {
            if (object.subTitleId === obj.id) {
                object.greaterVal = inputValue
            }
            return true
        })
    }

    const [teamStrengths, setTeamStrengths] = useState([])
    const [salesStrengths, setSalesStrengths] = useState([])
    const [motivationStrengths, setMotivationStrengths] = useState([])
    const [competencyStrengths, setCompetencyStrengths] = useState([])

    const closeDropdown = () => {
        setVisible(false)
    }

    const FilterSelection = (obj, index, isParent) => {

        setTempArray([])
        setVisible(true)
        selectedArray.map((object) => {
            object.isChecked = false
            return true
        })
        if (isParent) {
            setTempObject({
                mainTitleId: obj.mainTitleId,
                mainTitle: obj.mainTitle,
                subTitleId: "",
                subTitle: "",
                isChecked: false,
                greaterVal: ""
            })
        } else {

            setTempObject({
                mainTitleId: obj.mainTitleId,
                mainTitle: obj.mainTitle,
                subTitleId: obj.subTitleId,
                subTitle: obj.subTitle,
                isChecked: false,
                greaterVal: obj.greaterVal
            })

        }
        if (obj.mainTitleId === 0) {
            setSelectedArray(teamStrengths)
            setFilteredArray(teamStrengths)
        }
        if (obj.mainTitleId === 1) {
            setSelectedArray(salesStrengths)
            setFilteredArray(salesStrengths)
        }
        if (obj.mainTitleId === 2) {
            setSelectedArray(motivationStrengths)
            setFilteredArray(motivationStrengths)
        }
        if (obj.mainTitleId === 3) {
            setSelectedArray(competencyStrengths)
            setFilteredArray(competencyStrengths)
        }
    }

    const getTalentSearchFilterTypeKey = (object) => {
        let talentSearchFilterTypeKey;
        switch (object.mainTitle) {
            case Constants.TALENT_SEARCH_FILTER_TEAM_STRENGTHS:
                talentSearchFilterTypeKey = 0
                break;
            case Constants.TALENT_SEARCH_FILTER_SALES_STRENGTHS:
                talentSearchFilterTypeKey = 1
                break;
            case Constants.TALENT_SEARCH_FILTER_MOTIVATION_FACTORS:
                talentSearchFilterTypeKey = 2
                break;
            case Constants.TALENT_SEARCH_FILTER_COMPETENCES:
                talentSearchFilterTypeKey = 3
                break;
            default:
                talentSearchFilterTypeKey = 0
                break;
        }
        return talentSearchFilterTypeKey;
    }

    const handleSubmitButton = () => {
        const result = tempArray.filter(obj1 => {
            return eventualFilterArray.some(obj2 => {
                return obj1.mainTitleId === obj2.mainTitleId && obj1.subTitleId === obj2.subTitleId;
            });
        });
        if (result.length !== 0) {
            result.map((o) => {
                let value = tempArray.indexOf(o)
                tempArray.splice(value, 1)
                return true
            })
            eventualFilterArray.map((obj, ind) => {
                result.map((ob, inde) => {
                    if (obj.mainTitleId === ob.mainTitleId && obj.subTitleId === ob.subTitleId) {
                        if (ob.greaterVal !== null && ob.greaterVal !== undefined && ob.greaterVal !== "")
                            eventualFilterArray[ind] = ob
                    }
                    return true
                })
                return true
            })
        }
        tempArray.map((object) => {
            if (object.greaterVal !== null && object.greaterVal !== undefined && object.greaterVal !== ""){
                eventualFilterArray.push(object)

                props.filtersKeyArray[getTalentSearchFilterTypeKey(object)][object.mainTitle][object.subTitle.toLowerCase().replace(/\s+/g, '_')] = parseInt(object.greaterVal);
                props.setFiltersKeyArray([...props.filtersKeyArray])
            }

            return true
        })
        if(result) {
            result.map((object)=> {
                if (object.greaterVal !== null && object.greaterVal !== undefined && object.greaterVal !== "") {
                    props.filtersKeyArray[getTalentSearchFilterTypeKey(object)][object.mainTitle][object.subTitle.toLowerCase().replace(/\s+/g, '_')] = parseInt(object.greaterVal);
                    props.setFiltersKeyArray([...props.filtersKeyArray])
                }
                return true;
            })
        }
        setVisible(false)
    }

    const handleSearchChange = (e) => {
        if (e.target.value) {
            let a = selectedArray?.filter(item => item?.title?.toLowerCase().includes((e.target?.value)?.toLowerCase()));
            setSelectedArray([...a])
        } else {
            setSelectedArray([...filteredArray])
        }
    }

    const saveSearchFilter = () => {
        setLoader(true);
        let payload = {
            name: filterName,
            config : {
                "efa" : eventualFilterArray,
                "fka" : props.filtersKeyArray
            }
        }
        b2bServiceObj.SaveSearchFilter(payload, (status, response) => {
            setLoader(false)
            setSaveFilterDialogeOpen(false)
            if(status){
                b2bServiceObj.GetSearchFilters(setSavedFiltersList)
            }
        })
    }

    const [selectedFilter, setSelectedFilter] = useState(null);

    const handleSavedFilterSelect = (event) => {
        setSelectedFilter(event.target.value);
        b2bServiceObj.GetSearchFilterById(event.target.value, (config) => {
            setEventualFilterArray(config['efa'])
            props.setFiltersKeyArray(config['fka'])
        })
    };

    const deleteSearchFilter = (filterId) =>{
        b2bServiceObj.DeleteSearchFilter(filterId, (status) => {
            if(status){
                b2bServiceObj.GetSearchFilters(setSavedFiltersList)
            }
        })
    }

    const handleEnterKeyPress = () => {
          handleSubmitButton() // Call the same function as the button click
      };

    /**
     * Fetch all data for competence, motivation and strengths labels from contentful
     * and load into arrays
     */
    useEffect(() => {
        if (contentfulData) {
            if (competencyStrengths.length === 0) {
                contentfulData?.targetPositionCompetences?.map((obj) => {
                    setCompetencyStrengths((prevState) => [...prevState, { id: obj.id + 1, title: obj.title, isChecked: false }]);
                    return true
                })
            }

            if (motivationStrengths.length === 0) {
                contentfulData?.strongestMotivationalFactors?.map((obj) => {
                    setMotivationStrengths((prevState) => [...prevState, { id: obj.id + 1, title: obj.title, isChecked: false }]);
                    return true
                })
            }

            if (salesStrengths.length === 0) {
                contentfulData?.salesStrengthsLabels?.map((obj, index) => {
                    setSalesStrengths((prevState) => [...prevState, { id: index + 1, title: obj, isChecked: false }]);
                    return true
                })
            }

            if (teamStrengths.length === 0) {
                contentfulData?.teamStrengthsLabels?.map((obj, index) => {
                    setTeamStrengths((prevState) => [...prevState, { id: index + 1, title: obj, isChecked: false }]);
                    return true
                })
            }
        }
    }, [contentfulData, competencyStrengths, motivationStrengths, salesStrengths, teamStrengths])


    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        b2bServiceObj.GetSearchFilters(setSavedFiltersList)
    },[b2bServiceObj])

    return (
        <>
            <div className="filters-buttons-dd-container">
                <div style={{width : "100%"}}>
                    <button ref={dropdownRef} className="filters-bttn" onClick={handleDropdownClick}>
                        <AddIcon style={{marginTop : "-5px"}}/>Add Filter
                    </button>

                    {isOpen && <Box sx={{ position: "absolute" }}>
                        <ul className='add-filter-list'>
                            {filterOptions.map(
                                (obj, index) => {
                                    return (
                                        <MenuItem onClick={() => FilterSelection(obj, index, true)}
                                                  key={"profile_filter_"+ index}
                                                  value={obj.title}>{obj.mainTitle}
                                        </MenuItem>
                                    )
                                }
                            )}
                        </ul>
                    </Box>}

                    {eventualFilterArray.length > 0 &&

                        <button className="filters-bttn save-filter-bttn" onClick={() => setSaveFilterDialogeOpen(true)}>
                            <SaveIcon style={{marginTop : "-5px"}}/>Save Filter
                        </button>
                    }
                </div>
                {savedFiltersList.length > 0 && <div style={{ width: '100%' }}>
                    <FormControl style={{ minWidth: '300px', float: 'right' }}>
                            <TextField
                                label={"Saved Filters"}
                                value={selectedFilter ? selectedFilter : "Saved Filters"}
                                option={selectedFilter ? selectedFilter : "Saved Filters"}
                                select={true}
                                style={{backgroundColor: "#ffffff"}}
                                onChange={handleSavedFilterSelect}
                                fullWidth
                                size='small'
                                color='primary'

                                inputProps={{ style: { fontSize: '14px' } }} // font size of input text
                                InputLabelProps={{ style: { fontSize: '14px' } }} // font size of input label
                            >
                            {savedFiltersList.map((filter, index) => {
                                return(
                                    <MenuItem value={filter.id}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{filter.name}</span>
                                            {selectedFilter !== filter.id && (
                                                <IconButton size="small" onClick={(e) => {
                                                    e.stopPropagation();  // stop event from propagating up
                                                    deleteSearchFilter(filter.id);
                                                }}>
                                                    <DeleteForeverIcon className="delete-filter-icon" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </FormControl>
                </div>}

                <Dialog
                    showHeader={false}
                    visible={saveFilterDialogeOpen}
                    className="save-filter-dialog"
                >
                    <br/>
                    <div className="icon-div">
                        <img src={saveFilterIcon} alt="save filter sign"></img>
                    </div>

                    <p className="info-heading">Save Filter</p>
                    <p className="info-message">Please enter name of this filter</p>
                    <div className="text-field">
                        <TextField
                            placeholder="e.g. Company shared values"
                            id="filtername"
                            label="Filter name"
                            name="filtername"
                            type="text"
                            variant="outlined"
                            className="enter-filter-name"
                            value={filterName}
                            onChange={handleFilterNameChange}
                        />
                    </div>

                    {loader ?
                        <div>
                            <img width="40" height="40" src={loading} alt="Loading..."/>
                        </div>

                        :
                        null
                    }

                    <div className="d-flex justify-content-space-evenly my-4 w-100 pl-20 pr-20">
                        <Button btnstyle={'mazhrBtn default-white-border w-20 cancel-bttn'}
                                onClick={() => setSaveFilterDialogeOpen(false)}>Cancel</Button>
                        <Button btnstyle={'mazhrBtn default-green w-50 confirm-bttn'} onClick={() => saveSearchFilter()}>Confirm</Button>
                    </div>
                </Dialog>
            </div>

            {selectedArray.length !== 0 && visible === true && !isOpen && (
                <Box sx={{ position: 'absolute' }}>
                    <ul className="add-filter-list">
                        <div className="close-button-div">
                            <input className="search-input" type="text" id="digit-input" placeholder="Search..." onChange={(e) => handleSearchChange(e)} />
                            <button type="button" className="close-btn p-multiselect-close p-link" aria-label="Close">
                                <span className="p-multiselect-close-icon pi pi-times" onClick={closeDropdown}></span>
                            </button>
                        </div>

                            {// eslint-disable-next-line
                            selectedArray.some((obj) => {
                                if(obj.isChecked === true) {
                                    return true
                                }
                            }
                            ) && (tempObject.mainTitle === Constants.TALENT_SEARCH_FILTER_SALES_STRENGTHS? (
                                <span className="range-span"> Value between 1 - 5</span>
                            ) : (<span className="range-span"> Value between 1 - 9</span>))}
                        {selectedArray.map((obj, index) => (
                            <>
                                <MenuItem key={"my_arr_" + index} value={obj.id}>
                                    <FormControlLabel
                                        className="menu-item-label"
                                        label={`${obj.title}`}
                                        control={<Checkbox />}
                                        checked={obj.isChecked}
                                        onChange={(e) => handleCheckboxCheck(e, obj, index)}>

                                    </FormControlLabel>
                                    {obj.isChecked &&
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <span className="menu-item-label-selected"> {'>'} &nbsp;</span>
                                        {tempObject.mainTitle === Constants.TALENT_SEARCH_FILTER_SALES_STRENGTHS ? (
                                        <input
                                        className="chips-input"
                                        type="text"
                                        id="digit-input"
                                        name="digit"
                                        maxLength="1"
                                        pattern="[0-5]"
                                        onChange={(e)=> handleGreaterTextChangeSales(e,obj)}
                                        onKeyDown={(e) => {
                                            const allowedKeys = ["0", "1", "2", "3", "4", "5"];
                                            if (!(allowedKeys.includes(e.key) || e.key === "Backspace")) {
                                                e.preventDefault();
                                            }
                                            if (e.key === Constants.ENTER_KEY) {
                                                handleEnterKeyPress()
                                            }
                                        }}
                                        />
                                    ) : (
                                        <input
                                        className="chips-input"
                                        type="text"
                                        id="digit-input"
                                        name="digit"
                                        maxLength="1"
                                        pattern="[0-9]"
                                        onChange={(e) => handleGreaterTextChange(e, obj)}
                                        onKeyDown={(e) => {
                                            if (e.key === Constants.ENTER_KEY) {
                                                handleEnterKeyPress()
                                            }
                                        }}
                                        />
                                    )}
                                    </div>

                                    }
                                </MenuItem>
                            </>


                        ))}
                        <div style={{ margin: "15px", display: "flex", justifyContent: "center", height:"35px" }}>
                            <button className="mazhrBtn default-green" onClick={handleSubmitButton}> Apply
                            </button>
                        </div>

                    </ul>

                </Box>
            )}


            {eventualFilterArray.length !== 0 &&
                eventualFilterArray.map((object, index) => {

                    return (
                        <>
                            <Chip
                                style={{ margin: "5px 10px" }}
                                onClick={() => FilterSelection(object, index, false)}
                                onDelete={() => handleDeleteChip(index)}
                                label={`${object.mainTitle} ${object.subTitle} > ${object.greaterVal}`} />
                        </>
                    )

                })


            }
        </>
    );
}

TalentProfileFilters.propTypes = {
    filtersKeyArray: PropTypes.any,
    setFiltersKeyArray: PropTypes.func,
};

export default TalentProfileFilters
