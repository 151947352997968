import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg'
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";

import Button from '../../components/reusableElementsComponents/Button';
import MobileAuthHeader from '../../components/layoutComponents/authComponents/MobileAuthHeader';
import DesktopAuthDecoration from '../../components/layoutComponents/authComponents/DesktopAuthDecoration';

import { useNavigate } from "react-router-dom";

import Loading from '../../components/reusableElementsComponents/Loading';

import * as Constants from "../../constants";

import { FormControlLabel, Checkbox, FormControl, TextField } from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import B2BApiService from "../../B2bApiService";
import loading from '../../assets/images/icons/loading.gif';

import { inputLabelClasses } from "@mui/material/InputLabel";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import chrome from '../../assets/images/icons/chrome.png';
import { logLogin } from '../../analytics';

function Login() {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
    };

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    let userAgent = navigator.userAgent;

    useEffect(() => {
        if (!userAgent.match(/chrome|chromium|crios/i)) {
            setOpen(true);
        }
    }, [userAgent]);

    const data = useSelector(state => state.contentfulData.all['Company login page']);

    const defaultValues = {
        email: "",
        password: "",
        remember_me: false
    };

    const defaultErrorState = {
        email: false,
        password: false
    };

    const formErrorMessages = {
        email: "Email address must include . and @",
        password: "Password must contain at least 8 characters, a capital letter, a number and a special symbol"
    }

    const [formValues, setFormValues] = useState(defaultValues)
    const [formErrors, setFormErrors] = useState(defaultErrorState)
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const updateFormErrorsObject = (name, value) => {
        setFormErrors({
            ...formErrors,
            [name]: value,
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });

        if (!value) {
            updateFormErrorsObject(name, true)
        }

        if (invalidCredentials) {
            setInvalidCredentials(false)
        }
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        if (value !== undefined && value.length > 0) {
            validateFieldValues(name, value)
        }
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
        validateFieldValues(name, checked)
    };

    const validateFieldValues = (name, value) => {
        let valid = false;
        switch (name) {
            case 'email':
                valid = Constants.VALID_EMAIL.test(value);
                updateFormErrorsObject(name, !valid);
                if (!valid) {
                    setError(true);
                }
                break;
            case 'password':
                valid = Constants.VALID_PASSWORD.test(value);
                if (!valid) {
                    setError(true);
                }
                updateFormErrorsObject(name, !valid);
                break;
            default:
                break;
        }
        return valid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true)
        let keys = Object.keys(formValues);
        let fieldErrors = {};
        for (let index = 0; index < keys.length; index++) {
            fieldErrors[keys[index]] = !validateFieldValues(keys[index], formValues[keys[index]]);
        }

        setFormErrors(fieldErrors)

        b2bServiceObj.Login(formValues).then(response => {
            if (response.httpStatus === 200 && response.json.data) {
                localStorage.setItem(Constants.LOCAL_STORAGE_KEY_USER_ROLE_ID, response.json.data.role_id)
                logLogin()
                localStorage.setItem(Constants.MAZHR_JWT_KEY, JSON.stringify(response.json.data));
                b2bServiceObj.LoadCompanyData();
                setLoader(false);
                navigate("/");
            } else {
                if (response.json?.error?.code === 1002 || response.json?.error?.code === 1001) {
                    setInvalidCredentials(true);
                }
                setLoader(false)
            }
        });
    }

    if (data !== undefined) {

        let images = data.images;
        let content = data.fields;

        return (
            <div className="row auth-page-wrapper p-0 m-0">
                <img className='company-logo' src={mazharLogo} alt="Apex Analytics" />
                <MobileAuthHeader logo={true} title={content.titleMobile} subTitle={content.subTitleMobile} />

                <div className="col-12 col-lg-6 login-form">
                    <a href={Constants.MAZHR_APP_URL + "/#login"} className="reg-as-talent regular">
                        <span>{'<< '}</span>{content.loginAsTalent}
                    </a>
                    <div className="section">
                        <div className="d-none d-lg-block mar-45 bottom">
                            <h3 className="title semi-bold l mar-15 bottom">{content.titleDesktop}</h3>
                            <p className="grey light">{content.subTitleDesktop}</p>
                        </div>
                        <form className='form' onSubmit={handleSubmit}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                    placeholder="Start typing..."
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="text"
                                    autoComplete="on"
                                    value={formValues.email}
                                    helperText={formErrors.email && formErrorMessages.email}
                                    error={formErrors.email}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                />
                                <br />
                                <TextField
                                    InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                    id="password"
                                    placeholder="Start typing..."
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="on"
                                    value={formValues.password}
                                    error={formErrors.password}
                                    helperText={formErrors.password && formErrorMessages.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                />
                                <br />
                                <div className='rememberMeWrapper'>
                                    <FormControlLabel
                                        control={<Checkbox name="remember_me" onChange={handleCheckboxChange} />}
                                        label={content.rememberMe}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                    />
                                    <div className="recover-password">
                                        <Link to="/recover-password" className="bold green">
                                            {content.recoverPassword}
                                        </Link>
                                    </div>
                                </div>
                                {invalidCredentials && <Alert severity="error">
                                    <AlertTitle>{content.emailPasswordValidation}</AlertTitle>
                                </Alert>}
                            </FormControl>
                            <div className="authButtons">
                                <Button type='submit' btnstyle={'mazhrBtn default-green white'}>
                                    {loader && !error ? <img className='loader' src={loading} alt="Loading..." /> : <p className='m-0 light'>{content.signInButton}</p>}
                                </Button>
                                <Link className="mazhrBtn light-green" to="/register">
                                    <p className='m-0 light'>{content.signUpButton}</p>
                                </Link>
                            </div>
                        </form>
                    </div>


                    <div>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <p>For the best user experience we recommend using <a href="https://https://www.google.com/chrome/" className="link">Chrome</a> browser.</p>
                                    <a href="https://https://www.google.com/chrome/" className="link"><img style={{ width: '20px' }} src={chrome} alt="chrome" className="me-2" /> download Chrome</a>
                                </Typography>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <DesktopAuthDecoration blob={images.desktopBlob} illustration={images.loginImage} />
            </div>
        )
    } else {
        return <Loading />
    }
}

export default Login;


