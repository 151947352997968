import {Select} from "../formComponents/Inputs";
import * as PropTypes from "prop-types";
import {MultiSelect} from 'primereact/multiselect';
import {useEffect, useMemo, useState} from "react";
import B2BApiService from "../../B2bApiService";
import * as Constants from "../../constants";

function TalentTypeFilters(props) {
    const b2bServiceObj = useMemo(() => {return new B2BApiService();}, []);

    let [allTeams, setAllTeams] = useState([]);
    let [allPools, setAllPools] = useState([]);

    const userType = [
        {id: Constants.USER_FILTER_TYPE_ONLY_POOLS, title: "Candidate"},
        {id: Constants.USER_FILTER_TYPE_ONLY_TEAMS, title: "Employee"},
        {id: Constants.USER_FILTER_TYPE_ALL, title: "Both"}
    ]

    const customSelectedTeamsTemplate = () => {
        const length = props.selectedTeams ? props.selectedTeams.length : 0;
        return (
            <div className="px-3">
                <b>{length === 0? "no": length}</b> team{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const customSelectedItemsTemplate = () => {
        const length = props.selectedJobs ? props.selectedJobs.length : 0;
        return (
            <div className="px-3">
                <b>{length === 0? "no": length}</b> pool{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const onSelectType = (e) => {
        for (let i = 0; i < userType.length; i++) {
            if (userType[i].title === e.target.value) {
                props.setSelectedType(userType[i]);
            }
        }
    }

    const teamsSelection = (val) => {
        if (val.value) {
            val = val.value
            props.setSelectedTeams(val)

            let teamHashes = []
            allTeams.map((object) => {
                if(val.includes(object.title)){
                    teamHashes.push(object.hash)
                }
                return true
            })
            props.setSelectedTeamHashes(teamHashes)
        }
    }

    const poolsSelection = (val) => {
        if (val.value) {
            val = val.value
            props.setSelectedJobs(val)

            let jobHashes = []
            allPools.map((object) => {
                if(val.includes(object.title)){
                    jobHashes.push(object.hash)
                }
                return true
            })
            props.setGridOptionsCheck(jobHashes)
            props.setSelectedJobHashes(jobHashes)
        }
        
    }

    useEffect(() =>{
        b2bServiceObj.LoadTeamsWithAllData(setAllTeams, null)
        b2bServiceObj.LoadPoolsWithAllData(setAllPools, null)
    },[b2bServiceObj])

    return (
        <div style={{marginRight: "40px"}}>
            <div className="input" style={{
                marginRight: "10px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center"
            }}>

                <Select
                    id={"type"}
                    label="Type"
                    options={userType}
                    option={props.selectedType.title}
                    handleChange={onSelectType}
                    defaultValue={"Select Type"}
                />


            </div>

            <div style={{display: "flex", marginTop: "10px"}}>
                {(props.selectedType.title === "Employee" || props.selectedType.title === "Both") &&

                <div className="input" style={{marginRight: "10px"}}>
                    <MultiSelect
                        filter={true}
                        placeholder="Select Teams List"
                        selectedItemTemplate={customSelectedTeamsTemplate}
                        style={{width: "250px", height: "2.4rem", fontSize: "14px"}}
                        options={allTeams.map((obj) => {
                            return obj.title
                        })}
                        value={props.selectedTeams}
                        onChange={teamsSelection}
                    />
                </div>}


                {(props.selectedType.title === "Candidate" || props.selectedType.title === "Both") &&
                <div className="input">
                    <MultiSelect
                        filter={true}
                        placeholder="Select Job Position List"
                        selectedItemTemplate={customSelectedItemsTemplate}
                        style={{width: "250px", height: "2.4rem", fontSize: "14px"}}
                        options={allPools.map((obj) => {
                            return obj.title
                        })}
                        value={props.selectedJobs}
                        onChange={poolsSelection}
                    />
                </div>}
            </div>
        </div>
    )
}

TalentTypeFilters.propTypes = {
    selectedType: PropTypes.object,
    setSelectedType: PropTypes.func,
    selectedTeams: PropTypes.arrayOf(PropTypes.any),
    setSelectedTeams: PropTypes.func,
    selectedJobs: PropTypes.arrayOf(PropTypes.any),
    setSelectedJobs: PropTypes.func,
    selectedTeamHashes: PropTypes.arrayOf(PropTypes.any),
    setSelectedTeamHashes: PropTypes.func,
    selectedJobHashes: PropTypes.arrayOf(PropTypes.any),
    setSelectedJobsHashes: PropTypes.func,
};

export default TalentTypeFilters
