import {ActionTypes} from "../constants/action-types";
import { FAQCATEGORIES } from "../../constants";

const INITIAL_STATE = {
    all:{}
};

function faqReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case ActionTypes.FETCH_CONTENTFUL_DATA:
            let data = {};
            let rawFaqs = [];

            //put images in a separate object i.e imagesObj
            let assetsObj = action.payload.data.includes.Asset

            //loop through items
            let faqListData = {}
            action.payload.data.items.map(field => {
                let fieldData =  {
                    fields : field.fields,
                    images : {}
                };

                if (!fieldData?.fields?.pageTitleDoNotEdituse && fieldData?.fields?.question) {
                    faqListData = {
                        question : field.fields.question,
                        answer : field.fields.answer,
                        id: field?.sys?.id
                    }
                    rawFaqs.push(faqListData)
                }
                //check if items have images
                if(field.fields.images !== undefined) {
                    //loop through images
                    field.fields.images.forEach(image => {
                        //get image id.
                        const imgId = image.sys.id

                        //search in imagesObj
                        assetsObj.forEach(asset => {
                            if(asset.sys && asset.sys.id &&  imgId === asset.sys.id){
                                let imgTitle = String( asset.fields.title).trim();
                                fieldData.images[imgTitle] = asset.fields.file.url;
                            }

                        })
                    })
                }

                let formatedTitle = String(field.fields.pageTitleDoNotEdituse).trim();
                data[formatedTitle] = fieldData;
                return true
            })
            restructureFaqs(rawFaqs, data)
            return { ...state, all: data };

        default:
            return state;
    }
    function restructureFaqs(rawFaqs, data) {
        FAQCATEGORIES.forEach((faqCategory)=> {
            let structuredFaqs = []
            data[faqCategory]?.fields?.faqList.map((faqObj)=> {
                rawFaqs.forEach((rawFaqObj)=> {
                    if (rawFaqObj.id === faqObj.sys.id ) {
                        structuredFaqs.push(rawFaqObj)
                    }
                    return true
                })
                return true
            })
            data[faqCategory] = structuredFaqs
            return true
        })

    }
}

export default faqReducer;

