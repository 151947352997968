import { useSelector } from 'react-redux';
import Loading from '../../reusableElementsComponents/Loading';

function DesktopAuthDecoration({illustration}) {
    const data = useSelector( state => state.contentfulData.all['Auth desktop decoration']);

    if(data !== undefined) {
        let images = data.images;

        return (
            <div className="d-none d-lg-block col-lg-6 p-0 auth-desktop-decoration">
                <div className="decoration-wrapper">
                    <img className="blob lighter" src={images.desktopBlob} alt="blob" />
                    <img className="blob darker" src={images.desktopBlob} alt="blob" />
                    <div className="illustration-wrapper">
                        <img className="illustration" src={illustration} alt="login" />
                    </div>
                </div>
            </div>
        )
    }else {
        return (
            <Loading />
        )
    }
}

export default DesktopAuthDecoration;
