import Dropdown from 'react-bootstrap/Dropdown'
import {useState} from "react";
import InputError from "./InputError";

function MazhrDD({ options, defaultValue, onSelectFunc, errorContent, doValidation, label, id}) {

    if (!defaultValue){
        defaultValue = 'Select'
    }
    const [ isItemSelected, setIsItemSelected ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState(defaultValue);

    function onSelect(eventKey){
        if(!isItemSelected) {
            setIsItemSelected(true)
        }
        setSelectedOption(eventKey)
        onSelectFunc(eventKey, id)
    }

    return (
        <div className="form-field">
            {label ? <label>{label}</label> : ''}
            <div className="input-wrapper">
                <Dropdown onSelect={onSelect} className="dropdownWrapper full-width" >
                    <Dropdown.Toggle id="dropdown-autoclose-true" className='fullLengthDropdown'>
                        {selectedOption}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='options-bar'>
                        {options? options?.map((obj, index )=> (
                            <Dropdown.Item key={index} active={(obj?.title === selectedOption.toString()) || (obj?.name?.toString() === selectedOption.toString())} eventKey={obj.title ? obj.title : obj.name} href="#">
                                {obj.title !== undefined ? obj.title : obj.name}
                            </Dropdown.Item>
                        )):null}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {!isItemSelected && doValidation ? <InputError errors={!isItemSelected && doValidation} errorMessage={errorContent}/> : ''}
        </div>
    )
}

export default MazhrDD;



