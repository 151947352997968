import React, {useMemo, useState, useEffect} from 'react';
import B2BApiService from "../../B2bApiService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Text, DropdownSelect } from '../../components/formComponents/Inputs';
import {capitalizeFirstLowercaseRest} from '../../Utilities'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Constants from '../../constants'
import { ToastContainer, toast } from 'react-toastify';

export default function UserList() {
  const b2bServiceObj = useMemo(() => {
    return new B2BApiService();
  }, []);

  const [email, setEmail] = useState('');
  const [emailDoValidation, setEmailDoValidation] = useState(false);
  const [emailErrorContent, setEmailErrorContent] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleDoValidation, setSelectedRoleDoValidation] = useState(false);
  const [selectedRoleErrorContent, setSelectedRoleErrorContent] = useState('');
  const [jobRoles, setJobRoles] = useState([]); // State variable for job roles
  const [users, setUsers] = useState([]);

  const handleEmailChange = (e) => {
      setEmail(e.target.value);
      setEmailDoValidation(false);
  };

  const handleSelectRole = (e) => {
    setSelectedRoleDoValidation(false);
    for (const item of jobRoles) {
      if (item.title === e.target.value) {
        setSelectedRole(item.title);
        return;
      }
    }
    setSelectedRole(''); // Set to empty if the name is not found
    return; 
  };

  const revokeAccess = (userId) => {
    const revokeAccessToast = toast.loading("Removing user...")
    b2bServiceObj.DeleteUserAccount(userId,(response)=>{
      if(response.httpStatus === 200){
        const updatedUsers = users.filter((user) => user.id !== userId);
        setUsers(updatedUsers);
        toast.update(
              revokeAccessToast,
            {
                  render: "User removed successfully",
                  type: "success",
                  isLoading: false,
                  autoClose: '1500',
                  closeButton: true,
                  hideProgressBar: true
              }
            );
      }else{
        toast.update(
            revokeAccessToast,
            {
              render: "Unable to remove user, please try again",
              type: "error",
              isLoading: false,
              autoClose: '1500',
              closeButton: true,
              hideProgressBar: true
            }
        );
      }
    })

  };

  const sendInvite = () =>{
    if(!Constants.VALID_EMAIL.test(email)){
      setEmailDoValidation(true);
      setEmailErrorContent('Please enter a a valid email');
      return;
    }
    if(!selectedRole){
      setSelectedRoleDoValidation(true);
      setSelectedRoleErrorContent('Please select a role');
      return;
    }
    const selectedRoleId = jobRoles && selectedRole ? jobRoles.find(i => i.title === selectedRole)?.id : '';
    
    const inviteUserToast = toast.loading("Sending invite to new user...")
    b2bServiceObj.CreateNewUserAccount({email : email, role_id : selectedRoleId}, (response) => {
      if(response.httpStatus === 200){
          let newUser = {
              id : response.id,
              name: `${response.first} ${response.last}`,
              email: response.email,
              role: response.role_id,
              access: Constants.getUserRoleStringByRoleId(response.role_id), // Default to "Unknown" if role_id is not found
          }
          const updatedArray = [newUser, ...users];
          setUsers(updatedArray);

          setEmail('');

          setSelectedRole('');

          toast.update(
            inviteUserToast,
            {
              render: "Invite sent to new user successfully",
              type: "success",
              isLoading: false,
              autoClose: '1500',
              closeButton: true,
              hideProgressBar: true
            }
        );
      }else{
        toast.update(
            inviteUserToast,
            {
              render: "Unable to invite user, please try again",
              type: "error",
              isLoading: false,
              autoClose: '1500',
              closeButton: true,
              hideProgressBar: true
            }
        );
      }
    })
  }

  const actionTemplate = (rowData) => (
      <IconButton
          style={{ padding: '10px 10px' }}
          onClick={() => revokeAccess(rowData.id)}
      >
        <DeleteIcon />
      </IconButton>
  );

  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'access', header: 'Role' },
    { field: 'action', header: 'Remove User', body: actionTemplate },
  ];

  useEffect(() => {
    b2bServiceObj.GetCorporateUserRoles((data) => {
      const formattedJobRoles = data.map((jobRole) => ({
        id: jobRole.id,
        title: capitalizeFirstLowercaseRest(jobRole.name),
      }));
      setJobRoles(formattedJobRoles);
    })

    b2bServiceObj.GetCompanyAccounts((data) => {
      let transformedData = data.map((user) => ({
        id : user.id,
        name: `${user.first} ${user.last}`,
        email: user.email,
        role: user.role_id,
        access: Constants.getUserRoleStringByRoleId(user.role_id), // Default to "Unknown" if role_id is not found
      }));
      setUsers(transformedData)
    })
  }, [b2bServiceObj]); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <div className="user-list-wrapper-div">
      <ToastContainer />
      <div className="margin-left-div">
        <div className="user-invite-div">
          <div className='user-invite-flex-end-div'>
            <div style={{width: "250px", marginRight: "10px"}}>
              <Text
                  title="Enter Email To Invite"
                  value={email}
                  required={true}
                  fullWidth={true}
                  handleChange={handleEmailChange}
              />
              {emailDoValidation ? <div className="user-invite-error-div">{emailErrorContent}</div> : ''}
            </div>
            <div>
              <DropdownSelect
              options={jobRoles}
              label='User Roles'
              handleChange={handleSelectRole}
              option={selectedRole}
              />
              {selectedRoleDoValidation ? <div className="user-invite-error-div">{selectedRoleErrorContent}</div> : ''}
            </div>
            
            <button className="mazhrBtn default-green" onClick={sendInvite}>Send Invite</button>
          </div>
          {/* {inviteUserError && <div className="user-invite-error-div">{inviteUserError}</div>} */}
        </div>
        <div className="user-list-inner-div">
          <DataTable value={users} paginator rows={5} rowsPerPageOptions={[10, 15, 20]} emptyMessage="No users found.">
            {columns.map((column, i) => (
              <Column
                  key={i}
                  field={column.field}
                  header={column.header}
                  body={column.body}
                  filter
                  filterPlaceholder="Search"
              />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
}
