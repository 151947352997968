import speechBubble from '../../assets/images/icons/speechBubbleLightGrey.svg';
import Chip from '@mui/material/Chip';

import {useSelector} from "react-redux";

function TalentItem({data, selectedCandidateId, onSelectedFunction}) {
  const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);

  const disableOnTrue = (flag) => {
    return {
      opacity: flag ? 0.75 : 1,
      pointerEvents: flag ? "none" : "initial"
    }
  }

  return (
    <div
      id={data.id}
      className={selectedCandidateId === data.id ? 'talent-item active' : 'talent-item'}
      key={"talent_" + data.id}
      onClick={() => {onSelectedFunction(data.id)}}
      style={disableOnTrue(!!(!data.anonymous && data.pending_invite))}
    >



      <div className="initials">
        <div className={"initials-checkbox " + (data.match_percentage >= 70 ? 'green' : data.match_percentage >= 40 ? 'yellow' : 'purple')}>
          <p className='m-0'>
            {data.first[0]?.toUpperCase()}{data.last[0]?.toUpperCase()}
          </p>
        </div>
      </div>
      <div className="basic-info m-0">
        <div className="name-location">
          <b className="bold m-0 m">{data.first} {data.last}</b>
          <p className='m-0'>
            {data.city !== '' && <span>{data.city + ','}</span>}
            {data.country !== '' && <span>{data.country}</span>}
          </p>
        </div>
      </div>
      <div className="match">
        <p className={"m-0 " + (data.match_percentage >= 70 ? 'green' : data.match_percentage >= 40 ? 'yellow' : 'purple')}>{(data.match_percentage !== 0 && data.match_percentage !== undefined) ? `${data?.match_percentage}% match` : null}</p>
      </div>
      <div className="actions">
        {data.pending_invite && <span className="invite-pending">
              &nbsp; <Chip label={contentfulData?.tags.pending_invite} size="small" color="warning" />
            </span>}
        {data.hired && <span className="invite-pending">
              &nbsp; <Chip label={contentfulData?.tags.employee} size="small" color="success" />
            </span>}
        {data.team_invite_sent && <span className="invite-pending">
              &nbsp; <Chip label={contentfulData?.tags.team_invite_sent} size="small" color="secondary" />
            </span>}
        <a href={"mailto:" + data.email}><img src={speechBubble} alt="" /></a>
        {/*<img src={eye} alt="" />
        <img src={heart} alt="" />*/}
      </div>
    </div>
  )
}

export default TalentItem;
