import React, {memo, useEffect, useMemo, useState, useCallback, useRef} from "react";
import ControlPanel from "../../components/Analytics/groupAnalysis/ControlPanel"
import B2BApiService from "../../B2bApiService";
import {useSelector} from "react-redux";
import StrengthsRadarChart from "../../components/reusableElementsComponents/StrengthsRadarChart";
import {Select} from "../../components/formComponents/Inputs";
import TeamSimulatorVerticalCharts from "../../components/teamReviewComponents/teamSimulatorVerticalChart";
import {MotivationCharts} from "../../components/Analytics/keyAnalytics/MotivationCharts";
import {CommonCompetencies} from "../../components/Analytics/keyAnalytics/Charts";

function TeamSimulatorPage() {
    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const optionsSalesDropdown = useMemo(() => [
        { id: 1, title: "Competency" },
        { id: 2, title: "Attitude" }
    ], []);

    const [lightOptionsTeams] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom'
            }
        },
        scales: {
            r: {
                min: 0,
                max: 9,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                    circular: true
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });
    const [lightOptionsSales] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                    circular: true
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });

    const [groupOne, setGroupOne] = useState([]);
    const [groupTwo, setGroupTwo] = useState([]);
    const userIdToNameMapRef = useRef({}); // Use a ref to store userIdToNameMap
    const userColorMapRef = useRef({});// Mapping of user ID to color

    const [salesOption, setSalesOption] = useState('Competency')
    const [analysisData, setAnalysisData] = useState({});

    const [motivationsLabels, setMotivationsLabels] = useState([])
    const [competenciesLabels, setCompetenciesLabels] = useState([])
    const [employeesMotivationsData, setEmployeesMotivationsData ] = useState([])
    const [employeesCompetenciesData, setEmployeesCompetenciesData ] = useState([])
    const [candidatesMotivationsData, setCandidatesMotivationsData ] = useState([])
    const [candidatesCompetenciesData, setCandidatesCompetenciesData ] = useState([])

    let [commonComp, setCommonComp] = useState([]);
    let [topMotivations, setTopMotivations] = useState([]);
    let [lowMotivations, setLowMotivations] = useState([]);
    let [missingComp, setMissingComp] = useState([]);

    const [salesChartData, setSalesChartData] = useState({
        labels: contentfulData?.salesStrengthsLabels,
        datasets: [
            {
                label: 'Competence',
                backgroundColor: 'rgba(53, 196, 220, 0.2)',
                borderColor: 'rgb(53, 196, 220)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: []
            },
            {
                label: 'Attitude',
                backgroundColor: 'rgba(53, 196, 220, 0.2)',
                borderColor: 'rgb(53, 196, 220)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: []
            }
        ]
    });

    const [teamsChartData, setTeamsChartData] = useState({
        labels: contentfulData?.teamStrengthsLabels,
        datasets: [
            {
                label: 'Team Strengths',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: []
            }
        ]
    });

    const salesOptionChange = (e) => {
        setSalesOption(e.target.value)
    }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    },[]);

    const formulateBarChartData = (data, groupAKey, groupBKey) => {
        const groupA = data[groupAKey]; // e.g., employees or candidates
        const groupB = data[groupBKey]; // e.g., candidates or employees

        // Defensive checks: ensure that groupA and groupB are valid arrays
        if (!Array.isArray(groupA)) {
            return { top4GroupA: [], bottom4GroupA: [], columnNames: [], groupBValues: [] };
        }
        if (!Array.isArray(groupB)) {
            return { top4GroupA: [], bottom4GroupA: [], columnNames: [], groupBValues: [] };
        }

        // Ensure groupA has enough data to slice top and bottom 4
        const sortedGroupA = [...groupA].sort((a, b) => a.average - b.average);
        const top4GroupA = sortedGroupA.slice(-5); // Get last 5 elements
        const bottom4GroupA = sortedGroupA.slice(0, 5); // Get first 5 elements

        // Check if we successfully sliced the arrays
        if (!Array.isArray(top4GroupA) || !Array.isArray(bottom4GroupA)) {
            return { top4GroupA: [], bottom4GroupA: [], columnNames: [], groupBValues: [] };
        }

        // Extract column names from top and bottom of groupA
        const columnNames = [...top4GroupA, ...bottom4GroupA].map((item) => item.column_name);

        // Retrieve values from groupB for the corresponding column names
        const groupBValues = columnNames.map((columnName) => {
            const matchingItem = groupB.find((item) => item.column_name === columnName);
            return matchingItem ? matchingItem.average : null;
        });

        return { top4GroupA, bottom4GroupA, columnNames, groupBValues };
    }

    // Helper function to get unique IDs from a group
    const getUniqueIds = (group) => {
        return [...new Set(group.map(item => item.id))];
    };

    const updateUserIdToNameMap = useCallback((groupOne, groupTwo) => {
        const newMap = {};

        if(groupOne.length > 0){
            groupOne.forEach(user => {
                newMap[user.id] = user.name;
            });
        }

        if(groupTwo.length > 0){
            groupTwo.forEach(user => {
                newMap[user.id] = user.name;
            });
        }

        // Update the ref value instead of using setState
        userIdToNameMapRef.current = newMap;
    }, []);

    // Utility function to generate a random color
    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return { r, g, b }; // Return the RGB components as an object
    };

// Function to get or assign a color for a given user ID
    const getColorForUser = useCallback((userId) => {
        if (!userColorMapRef.current[userId]) {
            // Assign a new random color if the user doesn't already have one
            userColorMapRef.current[userId] = generateRandomColor();
        }
        return userColorMapRef.current[userId];
    },[]);

    // Update parseStrengthsData to use consistent colors
    const parseStrengthsData = useCallback((data) => {
        const teamStrengths = {
            labels: [],
            dataset: []
        };
        const keysToExclude = ['user_id'];

        data.forEach((item) => {
            const datasetRow = [];
            Object.keys(item).forEach((key) => {
                if (!keysToExclude.includes(key)) {
                    if (!teamStrengths.labels.includes(key)) {
                        teamStrengths.labels.push(key);
                    }
                    datasetRow.push(item[key]);
                }
            });
            if (datasetRow.length > 0) {
                teamStrengths.dataset.push({
                    data: datasetRow,
                    userId: item.user_id // Keep track of the user ID
                });
            }
        });

        return teamStrengths.dataset.map((datasetObj) => {
            const color = getColorForUser(datasetObj.userId);
            const rgbaColor = `rgba(${color.r},${color.g},${color.b}, 0.2)`;
            const borderColor = `rgb(${color.r},${color.g},${color.b})`;

            return {
                label: userIdToNameMapRef.current[datasetObj.userId] || 'Team Strength', // Use the ref
                backgroundColor: rgbaColor,
                borderColor: borderColor,
                pointBackgroundColor: borderColor,
                data: datasetObj.data
            };
        });
    }, [userIdToNameMapRef, getColorForUser]);

// Update parseSalesData to use consistent colors
    const parseSalesData = useCallback((data, selectedOption) => {
        const salesStrengths = {
            Competency: { dataset: [] },
            Attitude: { dataset: [] }
        };

        data.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (key === 'Competency' || key === 'Attitude') {
                    const datasetRow = Object.values(item[key]);
                    salesStrengths[key].dataset.push({
                        data: datasetRow,
                        userId: item.user_id // Include user ID to reference the name
                    });
                }
            });
        });

        return salesStrengths[selectedOption]?.dataset?.map((datasetObj) => {
            const color = getColorForUser(datasetObj.userId);
            const rgbaColor = `rgba(${color.r},${color.g},${color.b}, 0.2)`;
            const borderColor = `rgb(${color.r},${color.g},${color.b})`;

            return {
                label: userIdToNameMapRef.current[datasetObj.userId] || selectedOption, // Use the name from the map
                backgroundColor: rgbaColor,
                borderColor: borderColor,
                pointBackgroundColor: borderColor,
                data: datasetObj.data
            };
        }) || [];
    }, [userIdToNameMapRef, getColorForUser]);

    // Handle team strengths data and update the state
    const handleTeamStrengthsData = useCallback((teamStrengthsData) => {
        const teamStrengths = parseStrengthsData(teamStrengthsData);
        setTeamsChartData({
            labels: contentfulData?.teamStrengthsLabels,
            datasets: teamStrengths
        });
    },[contentfulData, parseStrengthsData]);

    // Handle sales strengths data and update the state
    const handleSalesStrengthsData = useCallback((salesStrengthsData) => {
        const salesDatasets = parseSalesData(salesStrengthsData, salesOption);
        setSalesChartData({
            labels: contentfulData?.salesStrengthsLabels,
            datasets: salesDatasets
        });
    },[contentfulData?.salesStrengthsLabels, parseSalesData, salesOption]);

    // Handle motivations comparative analysis
    const handleMotivationComparativeAnalysis = useCallback((motivationsData) => {
        const {
            top4GroupA,
            bottom4GroupA,
            columnNames,
            groupBValues
        } = formulateBarChartData(motivationsData, 'groupA', 'groupB');

        setMotivationsLabels(columnNames);
        setEmployeesMotivationsData([...top4GroupA, ...bottom4GroupA].map(item => item.average));
        setCandidatesMotivationsData(groupBValues);
    },[]);

    // Handle competencies comparative analysis
    const handleCompetencyComparativeAnalysis = useCallback((competenciesData) => {
        const {
            top4GroupA,
            bottom4GroupA,
            columnNames,
            groupBValues
        } = formulateBarChartData(competenciesData, 'groupA', 'groupB');

        setCompetenciesLabels(columnNames);
        setEmployeesCompetenciesData([...top4GroupA, ...bottom4GroupA].map(item => item.average));
        setCandidatesCompetenciesData(groupBValues);
    },[]);


    // Handle comparative analysis data for motivations and competencies
    const handleComparativeAnalysis = useCallback((data) => {
        if (data.motivations_avg) {
            handleMotivationComparativeAnalysis(data.motivations_avg);
        }
        if (data.competencies_avg) {
            handleCompetencyComparativeAnalysis(data.competencies_avg);
        }

        //ONLY ONE GROUP
        if(!data.motivations_avg.groupB){
            let sortedMotiv = data.motivations_avg.groupA.sort((a,b) => b.average - a.average)

            const top4Motivations = sortedMotiv.slice(0,4);
            const bottom4Motivations = sortedMotiv.slice(-4);
            setLowMotivations(bottom4Motivations)
            setTopMotivations(top4Motivations)

        }

        //ONLY ONE GROUP
        if(!data.competencies_avg.groupB){
            let sortedComp = data.competencies_avg.groupA.sort((a,b) => b.average - a.average)

            const highComp = sortedComp.slice(0,4);
            const lowComp = sortedComp.slice(-4);
            setMissingComp(lowComp);
            setCommonComp(highComp);
        }
    },[handleCompetencyComparativeAnalysis, handleMotivationComparativeAnalysis])


    useEffect(() => {
        const groupOneIds = getUniqueIds(groupOne);
        const groupTwoIds = getUniqueIds(groupTwo);

        updateUserIdToNameMap(groupOne, groupTwo)

        // If either group is empty, send all IDs as groupA, and groupB as an empty array
        const groupAIds = groupOneIds.length === 0 || groupTwoIds.length === 0 ? [...groupOneIds, ...groupTwoIds] : groupOneIds;
        const groupBIds = groupOneIds.length === 0 || groupTwoIds.length === 0 ? [] : groupTwoIds;

        // Fetch data from the API
        b2bServiceObj.GetTeamAnalysisData({ groupa: groupAIds, groupb: groupBIds }, (data) => {
            setAnalysisData(data);
            handleTeamStrengthsData(data.team_strengths);
            handleSalesStrengthsData(data.sales_strengths);
            handleComparativeAnalysis(data);
        });
    }, [b2bServiceObj, groupOne, groupTwo, contentfulData, salesOption, handleComparativeAnalysis, handleSalesStrengthsData, handleTeamStrengthsData, updateUserIdToNameMap]);


    return (
        <div className="page-wrapper">
            <div className="bg-cover">
                <div className='desktop-layout d-none d-lg-block m-0 mb-5'>
                    <div className="team-simulator-full-view">
                        <ControlPanel setGroupA={setGroupOne} setGroupB={setGroupTwo}></ControlPanel>
                    </div>



                    {(groupOne.length > 0 || groupTwo.length > 0) && (<div className='charts-div-background'>
                        {analysisData.sales_strengths?.length > 0 && analysisData.team_strengths?.length > 0 &&
                            <div className='charts-div'>
                                <div className='radar-charts-div mt-5' style={{flexDirection: "column"}}>
                                    <StrengthsRadarChart
                                        title="Team Strengths"
                                        data={teamsChartData}
                                        options={lightOptionsTeams}
                                    />
                                </div>
                                <div className='radar-charts-div mt-2' style={{flexDirection: "column"}}>
                                    <div style={{alignSelf: 'flex-end'}}>
                                        <Select
                                            options={optionsSalesDropdown}
                                            handleChange={(e) => {
                                                salesOptionChange(e)
                                            }}
                                            option={salesOption}
                                        />
                                    </div>

                                    <StrengthsRadarChart
                                        title="Sales Strengths"
                                        data={salesChartData}
                                        options={lightOptionsSales}
                                    />
                                </div>
                            </div>}
                        {analysisData?.competencies_avg?.groupA && !analysisData?.competencies_avg?.groupB &&
                            <div className='charts-div'>
                                <div className="mar-25 bottom" style={{width: '100%'}}>
                                    <p className="l bold">Values & Motivation</p>
                                    <div className="wrapper" style={{minHeight: '250px'}}>
                                        <MotivationCharts topMotivations={topMotivations} lowMotivations={lowMotivations}/>
                                    </div>
                                </div>
                            </div>
                        }

                        {analysisData?.motivations_avg?.groupA && !analysisData?.motivations_avg?.groupB &&
                            <div className='charts-div'>
                                <div className="mar-25 bottom" style={{width: '100%'}}>
                                    <p className="l bold">Work style preferences</p>
                                    <div className="wrapper" style={{minHeight: '250px'}}>
                                        <CommonCompetencies commonComp={commonComp} missingComp={missingComp}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {analysisData?.competencies_avg?.groupA && analysisData?.competencies_avg?.groupB &&
                            <div className='charts-div'>
                                <TeamSimulatorVerticalCharts
                                    labels={motivationsLabels}
                                    employeesData={employeesMotivationsData}
                                    candidatesData={candidatesMotivationsData}
                                    chartTitle='Values & Motivation Comparison (Group A Focused)'
                                    leftChartLabel={"Group A"}
                                    rightChartLabel={"Group B"}
                                />
                            </div>}
                        {analysisData?.motivations_avg?.groupA && analysisData?.motivations_avg?.groupB &&
                            <div className='charts-div'>
                                <TeamSimulatorVerticalCharts
                                    labels={competenciesLabels}
                                    employeesData={employeesCompetenciesData}
                                    candidatesData={candidatesCompetenciesData}
                                    chartTitle='Work Style Preferences Comparison (Group B Focused)'
                                    leftChartLabel={"Group A"}
                                    rightChartLabel={"Group B"}
                                />
                            </div>}
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default memo(TeamSimulatorPage)




