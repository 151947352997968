import {ActionTypes} from "../constants/action-types";

const INITIAL_STATE = {
    isActive : true,
    info : {}
};

// eslint-disable-next-line
export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case ActionTypes.SET_COMPANY_INFO:
            let data = [];
            if(action && action.payload){
                data = action.payload;
            }
            return {...state, info : data,};

        case ActionTypes.SET_COMPANY_STATUS:
            let isActive = false;
            if(action.payload){
                isActive = true;
            }
            return {...state, isActive : isActive};
        default:
            return state;
    }
}
