import MazhrModal from "../reusableElementsComponents/MazhrModal";
import {useEffect} from 'react'
import MazhrStepper from '../reusableElementsComponents/MazhrStepper';
//import EmailTemplates from './EmailTemplatesEdit';
import TeamReviewGeneral from './TeamReviewGeneral';
import TeamReviewAssessments from './TeamReviewAssessments';
import { useMemo, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import B2BApiService from "../../B2bApiService";

function InviteTeamModal({ modalShow, modalHide }) {
    let createNewTeamToast;
    const [formCompleted, setFormCompleted] = useState(false);
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line
      }, [formCompleted]);

      function handleBeforeUnload(event) {
        if (!formCompleted) {
          event.preventDefault();
          event.returnValue = ""

        }
      }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const [generalData] = useState({
        title: "",
        description: "",
        industry_id: "",
        email_list: []
    });

    /*const [emailData] = useState({
        invite_team_member_email: "",
    });*/

    const [assessmentData] = useState({
        assessments: []
    });

    const onNewTeamSave = (saved) => {
        if (saved) {
            modalHide()
            setFormCompleted(true);
            toast.update(createNewTeamToast,
                {
                    render: "Team created",
                    type: "success",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                }
            );
        } else {
            toast.update(createNewTeamToast,
                {
                    render: "Something went wrong",
                    type: "error",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                }
            );
        }
    }

    const saveTeam = () => {
        createNewTeamToast = toast.loading("Creating team " + generalData.title);
        b2bServiceObj.SaveTeam(
            /*{...generalData, ...emailData, ...assessmentData},*/
            { ...generalData, ...assessmentData },
            onNewTeamSave
        )
    }
    return (
        <>
            <MazhrModal
                show={modalShow}
                onHide={modalHide}
            >
                <ToastContainer />
                {/*<MazhrStepper
            step1={<TeamReviewGeneral data={generalData} />}
            step2={<EmailTemplates data={emailData}/>}
            step3={<TeamReviewAssessments data={assessmentData} />}
            optionalSteps={[1,2]}
            submitFunction={saveTeam}
        />*/}

                <MazhrStepper
                    step1={<TeamReviewGeneral data={generalData} />}
                    step2={<TeamReviewAssessments data={assessmentData} />}
                    optionalSteps={[2]}
                    submitFunction={saveTeam}
                />

            </MazhrModal>
        </>
    )
}

export default InviteTeamModal;
