import Button from "../reusableElementsComponents/Button";
import {Dialog} from 'primereact/dialog';
import warningIcon from '../../assets/images/icons/warningIcon.svg'
import loading from '../../assets/images/icons/loading.gif'

const Modal = ({visible, onHide, title, text, buttonLabel, loader, cancel, actionButton})=> {
    return (
        <Dialog
            showHeader={false}
            visible={visible}
            //style={{width: '25vw', textAlign: "center"}}
            style={{width: '575px', textAlign: "center"}}
            onHide={onHide}
            >
                <br/>
                <img src={warningIcon} alt="warning sign"></img>
                <br/>
                <br/>
                <h5 className="black-text">{title}</h5>
            <p className="grey-text">
                {text}
            </p>
            {loader ?
                <div>
                    <img width="40" height="40" src={loading} alt="Loading..."/>
                </div>

                :
                null
            }

            <div className="d-flex justify-content-space-evenly my-4 w-100">
                <Button btnstyle={'mazhrBtn default-white-border w-20'}
                        onClick={cancel}>Cancel</Button>
                <Button btnstyle={'mazhrBtn default-purple w-20'}
                        onClick={actionButton}>{buttonLabel}</Button>
            </div>
        </Dialog>
    )
}

export default Modal;