import itIcon from '../../assets/images/icons/industriesIcons/it.svg';
import marketingIcon from '../../assets/images/icons/industriesIcons/marketing.svg';
import educationIcon from '../../assets/images/icons/industriesIcons/education.svg';
import managementIcon from '../../assets/images/icons/industriesIcons/management.svg';
import financeIcon from '../../assets/images/icons/industriesIcons/finance.svg';
import customercareIcon from '../../assets/images/icons/industriesIcons/customercare.svg';
import supervisorsIcon from '../../assets/images/icons/industriesIcons/supervisors.svg';
import hrIcon from '../../assets/images/icons/industriesIcons/hr.svg';
import legalIcon from '../../assets/images/icons/industriesIcons/legal.svg';
import healthcareIcon from '../../assets/images/icons/industriesIcons/healthcare.svg';
import securityIcon from '../../assets/images/icons/industriesIcons/security.svg';
import logisticsIcon from '../../assets/images/icons/industriesIcons/logistics.svg';
import creativeIcon from '../../assets/images/icons/industriesIcons/creative.svg';
import hotelsIcon from '../../assets/images/icons/industriesIcons/hotels.svg';
import operativeIcon from '../../assets/images/icons/industriesIcons/operative.svg';

import {useEffect, useMemo, useState} from "react";
import ReactPaginate from "react-paginate";
import B2BApiService from "../../B2bApiService";

function OpenPosition ({ selectedPositionId, onSelectedFunction, poolList }) {
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    let [positionsData, setPositionsData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const itemsPerPage = 10;
    const pagesVisited = pageNumber * itemsPerPage;


    const pageCount = Math.ceil(positionsData.length / itemsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    useEffect(() => {
        setPositionsData(poolList);
        if (selectedPositionId) {
            setSelectedId(selectedPositionId);
            b2bServiceObj.LoadPoolByHash(selectedPositionId)
        }else {
            setSelectedId(poolList[0].hash);
        }
    }, [poolList, b2bServiceObj, selectedPositionId]);

    const onPoolChange = (hash) => {
        setSelectedId(hash);
        onSelectedFunction(hash);
    }

    const displayItems = positionsData.slice(pagesVisited, pagesVisited + itemsPerPage).map((position, index) => {
        let fullDate = new Date(position.deadline)
        if (position.deadline) {
            const options = { month: 'short', year: 'numeric', day: 'numeric' };
            var formatedDate = fullDate.toLocaleDateString('en-us', options).replaceAll(',', '');
        }
        else {
            formatedDate = "Open"
        }
        const currentDate = new Date();
        
        return (
            <button
                id={position.id}
                className={selectedId === position.hash ? 'open-position active' : 'open-position'}
                key={index}
                onClick={() => onPoolChange(position.hash)}
            >
                <div className={`position-status ${fullDate < currentDate ? 'inactive' : 'active'}`}>
                    {position.industry.id === 1 && <img src={itIcon} alt="Software, Tech, Product development" />}
                    {position.industry.id === 2 && <img src={marketingIcon} alt="Sales, Marketing, Communication" />}
                    {position.industry.id === 3 && <img src={educationIcon} alt="Education, Research" />}
                    {position.industry.id === 4 && <img src={managementIcon} alt="Management" />}
                    {position.industry.id === 5 && <img src={financeIcon} alt="Financial" />}
                    {position.industry.id === 6 && <img src={customercareIcon} alt="Customer care" />}
                    {position.industry.id === 7 && <img src={supervisorsIcon} alt="Supervisors" />}
                    {position.industry.id === 8 && <img src={hrIcon} alt="HR, Administration, Office" />}
                    {position.industry.id === 9 && <img src={legalIcon} alt="Legal" />}
                    {position.industry.id === 10 && <img src={healthcareIcon} alt="Health Care" />}
                    {position.industry.id === 11 && <img src={securityIcon} alt="Security, Protection, military" />}
                    {position.industry.id === 12 && <img src={logisticsIcon} alt="Transportation and logistics" />}
                    {position.industry.id === 13 && <img src={creativeIcon} alt="Jobs creativity needed" />}
                    {position.industry.id === 14 && <img src={hotelsIcon} alt="Hotel, restaurant, food" />}
                    {position.industry.id === 15 && <img src={operativeIcon} alt="Operative jobs" />}
                </div>
                <div className="position-desctription">
                    <p className="position-title m-0 bold m">{position.title}</p>
                    <p className="position-industry m-0 light">{position.department}</p>
                </div>
                <div className="mazhr-card-filled-candidates">
                    <p className="light m-0">{position.userCount} candidates</p>
                </div>
                <div className="deadline">
                    <div>
                        <p className="light m-0">{formatedDate}</p>
                    </div>
                </div>
            </button>
        )
    });

    return (
        <div id="top" className="position-items">
            <div className="position-items-wrapper">
                {displayItems}
            </div>
            {displayItems.length > 1 &&
                <ReactPaginate
                    previousLabel={"PREV"}
                    nextLabel={"NEXT"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    pageClassName={"pagination-item"}
                />
            }
        </div>
    )
}

export default OpenPosition;

