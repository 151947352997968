import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../reusableElementsComponents/Loading';


function NoDataOpenPositions() {
    const data = useSelector( state => state.contentfulData?.all['Open positions page no data']);

    if(data !== undefined){
        let content = data.fields;
        let images = data.images;

        return (
            <div className="open-positions-no-data">
                <h1>{content.noDataTitle}</h1>
                <img className="no-data-illustration" src={images.noDataOpenPositions} alt="No data" />
                <h4 className="semi-bold header-text header">{content.noDataTitle}</h4>
                <p className="grey s center action-text description">{content.noDataDescription}</p>
                <button className="mazhrBtn default-green">
                    <Link to="/new-open-position" className="semi-bold white">
                        {content.openNewPositionButton}
                    </Link>
                </button>
            </div>
        )
    }else {
        return  <Loading />
    }
}

export default NoDataOpenPositions;
