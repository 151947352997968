import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import InputError from '../formComponents/InputError';
import Form from '../reusableElementsComponents/Form';
import Tooltip from '@mui/material/Tooltip';
import * as Constants from '../../constants'
import * as Utils from '../../Utilities'

function RecruitmentProcess ({ recruitmentProcess, onChangeFunc }) {
    let template = {
        fields: [
            {
                title: 'title',
                type: 'text',
                name: 'title',
            },
            {
                title: 'description',
                type: 'text',
                name: 'description',
                textArea: true
            }
        ]
    }

    // eslint-disable-next-line
    const [options, setOptions] = useState();
    const [selectedOptions, setSelectedOptions] = useState(recruitmentProcess);

    const [hiddenOptions, setHiddenOptions] = useState([
        {
            title: 'Work related assessment',
            description: 'Hiring process includes a skill assessment relevant for the role.\n'
        },
        {
            title: 'Background check',
            description: 'A review of a potential employee\'s criminal & commercial records.This is to ensure the safety and security of the employees in our organisation'
        },
        {
            title: 'On boarding',
            description: 'Meets and sessions to help you understand our values, company culture, products and services.'
        }
    ]);

    const [error, setError] = useState(false);

    function moreOptions () {
        document.querySelector('.not-selected-list').classList.toggle('d-none');
        document.querySelector('.more-options-btn').classList.toggle('d-none');
    };

    function customOption () {
        document.querySelector('.custom-option-form').classList.toggle('d-none');
        document.querySelector('.custom-options-btn').classList.toggle('d-none');
        document.getElementById('title').value = '';
        document.getElementById('description').value = '';
    };


    let [customInputError, setCustomInputError] = useState(false);

    const addCustomOption = option => {
        if (!option.title || /^\s*$/.test(option.title)) {
            customInputError = true
            setCustomInputError(customInputError);
            setTimeout(() => setCustomInputError(false), 5000);
            return
        }

        if (selectedOptions.length < 5) {
            setOptions(option);
            selectedOptions.push(option);
            customOption();
            setOptions({ option: '' });
        } else {
            setError(true);
            setTimeout(() => setError(false), 5000);
        }
    };

    const getItemStyle = (isDragging) => ({
        background: isDragging ? "#ECECF2" : ''
    })

    const onDragEnd = (DropResult) => {
        const { source, destination } = DropResult;
        if (!destination) return;

        const items = Array.from(selectedOptions);
        const [newOrder] = items.splice(source.index, 1);
        items.splice(destination.index, 0, newOrder);

        setSelectedOptions(items);
        onChangeFunc(items);
    }

    const addSelectedItem = (item, index) => {
        let newItem = {
            title: item.title,
            description: item.description
        };

        if (selectedOptions.length < 5) {
            let newSelectedOptions = selectedOptions.concat(newItem);
            setSelectedOptions(newSelectedOptions);
            setHiddenOptions(
                hiddenOptions.slice(0, index).concat(hiddenOptions.slice(index + 1))
            );
            onChangeFunc(newSelectedOptions)
        } else {
            setError(true);
            setTimeout(() => setError(false), 5000);
        }
    }

    const removeSelectedItem = (item, index) => {
        let newHiddenItem = {
            title: item.title,
            description: item.description
        };
        let newHiddenOptions = hiddenOptions.concat(newHiddenItem);
        setHiddenOptions(newHiddenOptions);
        let newARR = selectedOptions.splice(index, 1);
        newARR = selectedOptions;
        setSelectedOptions(newARR);
        onChangeFunc(newARR);
        setError(false);
    }

    const notSelectedList = document.querySelector('.not-selected-list');

    if (hiddenOptions.length === 0 && notSelectedList !== null) {
        notSelectedList.classList.remove('show-border');
    }

    if (hiddenOptions.length > 0 && notSelectedList !== null) {
        notSelectedList.classList.add('show-border');
    }

    useEffect(() => {
        setSelectedOptions(recruitmentProcess)
    }, [recruitmentProcess])

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="item">
                    {(provided) => (
                        <ul className="selectedList grey p-0"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            onChange={() => {
                                localStorage.setItem("options", JSON.stringify(selectedOptions))
                            }}
                        >
                                {selectedOptions && selectedOptions.map((item, index) => {
                                return (
                                    <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={Utils.currentUserRole() === Constants.USER_ROLE_TEAMLEAD}>
                                        {(provided, snapshot) => (
                                            <li className='process-item selected'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <p className="item-number">{index + 1}</p>
                                                <Tooltip title={item.description} placement="top-end">
                                                <div className="item-content"
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                >
                                                    <p className="item">{item.title}</p>
                                                    { Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <p onClick={() => removeSelectedItem(item, index)} className="remove">
                                                        <span className="exit"><IoCloseOutline /></span>
                                                    </p>}
                                                </div>
                                                </Tooltip>
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                            {error ? <InputError errors={error} errorMessage={"Maximum number of items selected, please remove in order to add different ones"} /> : ''}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>

            { Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <button className="more-options-btn mazhrBtn dotted-line-border full-width" onClick={moreOptions}>
                <span className="plus-icon"><AiOutlinePlus /></span>
                <span className="regular">add another</span>
            </button>}

            <ul className="grey not-selected-list show-border d-none">
                <p className="exit" onClick={moreOptions}>
                    <IoCloseOutline />
                </p>
                {hiddenOptions.map((item, index) => {
                    return (
                        <li onClick={() => addSelectedItem(item, index)} key={index} className='process-item not-selected'>
                            <p className="item">{item.title}</p>
                        </li>
                    )
                })}
            </ul>

            { Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <button className="custom-options-btn mazhrBtn light-green full-width" onClick={customOption}>
                <span className="regular">add custom item</span>
            </button>}

            <div className="custom-option-form d-none">
                <p className="exit" onClick={customOption}>
                    <IoCloseOutline />
                </p>
                <Form
                    template={template}
                    onSubmit={addCustomOption}
                    errors={customInputError}
                    watchFields={[]}
                    validate={validate}
                >
                    <button type="submit" className="mazhrBtn light-green full-width">
                        <span className="regular">add option</span>
                    </button>
                    {customInputError ? <InputError errorMessage={"Title is required in ordert to add item"} /> : ''}
                </Form>
            </div>

            {Utils.currentUserRole() === Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && recruitmentProcess?.length === 0 && <h6 className="mar-25 left"> No defined hiring process</h6>}
        </>
    )

    function validate (watchValues, errorMethods) {
        // eslint-disable-next-line
        let { errors, setError, clearErrors } = errorMethods;

    }
}

export default RecruitmentProcess;
