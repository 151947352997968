function InputError({errorMessage}) {
    return (
        <div className="input-error">
            <p className="m-0 text-center">
                {errorMessage}
            </p>
        </div>
    )
}

export default InputError;
