import React, {useEffect, useMemo, useState} from "react";
import B2BApiService from "../../../B2bApiService";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box, Checkbox, Chip, Divider, FormControlLabel,
    Grid, Switch,
    TextField,
    Typography,
    Collapse,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const ControlPanel = ({setGroupA, setGroupB}) => {
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const [showTwoGroups, setShowTwoGroups] = useState(false);
    const [groupOne, setGroupOne] = useState([]);
    const [groupTwo, setGroupTwo] = useState([]);

    const [poolOptions, setPoolOptions] = useState([])
    const [teamOptions, setTeamOptions] = useState([])
    const [showDescription, setShowDescription] = useState(false); // New state for showing the description

    const handleToggleDescription = () => {
        setShowDescription((prev) => !prev);
    };

    const handleDelete = (candidateToDelete) => {
        setSelectedCandidates((prev) =>
            prev.filter((candidate) => candidate.id !== candidateToDelete.id)
        );

        // Remove candidate from both groups if present
        setGroupOne((prev) => prev.filter((candidate) => candidate.id !== candidateToDelete.id));
        setGroupTwo((prev) => prev.filter((candidate) => candidate.id !== candidateToDelete.id));
    };

    const handleCheckboxChange = (event, candidate) => {
        if (event.target.checked) {
            setSelectedCandidates((prev) => [
                ...prev,
                { ...candidate }, // Include the entire candidate object
            ]);
            setGroupOne((prev) => [
                ...prev,
                { ...candidate }, // Include the entire candidate object
            ]);
        } else {
            setSelectedCandidates((prev) =>
                prev.filter((c) => c.id !== candidate.id)
            );

            setGroupOne((prev) =>
                prev.filter((c) => c.id !== candidate.id)
            );
        }
    };

    const isChecked = (candidate) => {
        return selectedCandidates.some((c) => c.id === candidate.id);
    };

    const handleToggleGroups = () => {
        setShowTwoGroups((prev) => !prev);
        setGroupOne(selectedCandidates);
        setGroupTwo([]);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        // Determine the source and destination groups
        const sourceGroup = result.source.droppableId === "groupOne" ? groupOne : groupTwo;
        const destinationGroup = result.destination.droppableId === "groupOne" ? groupOne : groupTwo;

        // Clone the source group and remove the dragged item
        const sourceItems = Array.from(sourceGroup);
        const [movedItem] = sourceItems.splice(result.source.index, 1);

        // Clone the destination group and add the dragged item
        const destinationItems = Array.from(destinationGroup);
        destinationItems.splice(result.destination.index, 0, movedItem);

        // Update the state for both groups
        if (result.source.droppableId === "groupOne") {
            setGroupOne(sourceItems);
            setGroupTwo(destinationItems);
        } else {
            setGroupOne(destinationItems);
            setGroupTwo(sourceItems);
        }
    };

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    },[]);

    useEffect(() => {
        b2bServiceObj.LoadPoolsUsersWithAllData((setPoolOptions))
        b2bServiceObj.LoadTeamMembersByCompanyId('', (setTeamOptions))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [b2bServiceObj])

    useEffect(()=>{
        setGroupA(groupOne)
    },[groupOne, setGroupA])

    useEffect(()=>{
        setGroupB(groupTwo)
    },[groupTwo, setGroupB])

    return (
        <Box sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom sx={{color:'#00B0A0'}}>
                    Group Analyzer
                </Typography>
                <IconButton onClick={handleToggleDescription} gutterBottom sx={{ marginBottom : "6px" }}>
                    <InfoIcon sx={{ color: '#FFBB31' }} />
                </IconButton>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Collapse in={showDescription}>
                <Box sx={{ backgroundColor: '#F2FBFA', borderRadius: 2, padding: 2, }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" sx={{ color: 'black', lineHeight: 1.6 }}>
                            Group Analyzer enables you to visualize and compare the strengths, competencies, and motivations across groups or individuals. It offers valuable insights to identify differences, uncover commonalities, and understand key dynamics, helping you make data-driven decisions to optimize performance.
                        </Typography>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleToggleDescription}
                        >
                            <CloseIcon  sx={{color : 'red'}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Helpful Tips:
                        </Typography>
                        <Box component="ul" sx={{ marginTop: 0, paddingLeft: 2 }}>
                            <Box component="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <CheckCircleIcon sx={{ color: '#00B0A0', marginRight: 1 }} />
                                <Typography variant="body2">Using dropdowns below you can choose individuals from teams or pools</Typography>
                            </Box>
                            <Box component="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <CheckCircleIcon sx={{ color: '#00B0A0', marginRight: 1 }} />
                                <Typography variant="body2">Search by team, pool, or individual name is possible by typing text in the dropdown text area</Typography>
                            </Box>
                            <Box component="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <CheckCircleIcon sx={{ color: '#00B0A0', marginRight: 1 }} />
                                <Typography variant="body2">Click on the checkbox next to the name to select the individual</Typography>
                            </Box>
                            <Box component="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <CheckCircleIcon sx={{ color: '#00B0A0', marginRight: 1 }} />
                                <Typography variant="body2">If you wish to analyze two groups or compare individuals, turn on this option by using Comparison toggle at the bottom</Typography>
                            </Box>
                            <Box component="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <CheckCircleIcon sx={{ color: '#00B0A0', marginRight: 1 }} />
                                <Typography variant="body2">Once you have turned on the comparison toogle, you can drag drop individuals between Group A and Group B</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Collapse>
            {showDescription && <Divider sx={{ marginY: 3 }} />}

            <Grid container spacing={2} sx={{ display: 'flex', paddingTop:'20px' }}>
                <Grid item xs={12} md={5}>
                    <Typography variant="h6"><strong>Select From Pools:</strong></Typography>
                    <Autocomplete
                        options={[...new Set(poolOptions.map(candidate => candidate.pool_name))]} // Use pool_name from the new structure
                        filterOptions={(options, { inputValue }) => {
                            const lowercasedInput = inputValue.toLowerCase();
                            return options.filter(pool =>
                                pool.toLowerCase().includes(lowercasedInput) ||
                                poolOptions.some(candidate =>
                                    candidate.pool_name === pool &&
                                    `${candidate.first_name} ${candidate.last_name}`.toLowerCase().includes(lowercasedInput) // Filter by candidate names
                                )
                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Select From Pools" />}
                        renderOption={(props, pool) => {
                            const candidatesInPool = poolOptions.filter(candidate => candidate.pool_name === pool);
                            const candidateCount = candidatesInPool.length; // Get the number of candidates in the pool

                            return (
                                <Accordion key={pool}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {pool} ({candidateCount} {candidateCount === 1 ? 'candidate' : 'candidates'})
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} padding={2}>
                                            {candidatesInPool.map(candidate => (
                                                <Grid item xs={12} sm={6} md={4} key={candidate.candidate_id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked(candidate)}
                                                                onChange={(e) => handleCheckboxChange(e, candidate)} // Checkbox state handling
                                                            />
                                                        }
                                                        label={
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                onMouseDown={(e) => {
                                                                    e.preventDefault(); // Prevent dropdown from closing
                                                                    handleCheckboxChange({ target: { checked: !isChecked(candidate) } }, candidate); // Toggle checkbox
                                                                }}
                                                            >
                                                                {`${candidate.first_name} ${candidate.last_name}`}
                                                            </span>
                                                        }
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }}
                    />


                    <Typography variant="h6" sx={{ marginTop: 2 }}><strong>Select From Teams:</strong></Typography>
                    <Autocomplete
                        options={[...new Set(teamOptions.map(candidate => candidate.team_name))]} // Use pool_name from the new structure
                        filterOptions={(options, { inputValue }) => {
                            const lowercasedInput = inputValue.toLowerCase();
                            return options.filter(team =>
                                team.toLowerCase().includes(lowercasedInput) ||
                                teamOptions.some(candidate =>
                                    candidate.team_name === team &&
                                    `${candidate.first} ${candidate.last}`.toLowerCase().includes(lowercasedInput) // Filter by candidate names
                                )
                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Select From Teams" />}
                        renderOption={(props, team) => {
                            const candidatesInPool = teamOptions.filter(candidate => candidate.team_name === team);
                            const candidateCount = candidatesInPool.length; // Get the number of candidates in the pool

                            return (
                                <Accordion key={team}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {team} ({candidateCount} {candidateCount === 1 ? 'candidate' : 'candidates'})
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} padding={2}>
                                            {candidatesInPool.map(candidate => (
                                                <Grid item xs={12} sm={6} md={4} key={candidate.candidate_id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked(candidate)}
                                                                onChange={(e) => handleCheckboxChange(e, candidate)} // Checkbox state handling
                                                            />
                                                        }
                                                        label={
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                onMouseDown={(e) => {
                                                                    e.preventDefault(); // Prevent dropdown from closing
                                                                    handleCheckboxChange({ target: { checked: !isChecked(candidate) } }, candidate); // Toggle checkbox
                                                                }}
                                                            >
                                                                {`${candidate.first} ${candidate.last}`}
                                                            </span>
                                                        }
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }}
                    />

                    <Box sx={{ marginTop: 3 }}>
                        <FormControlLabel
                            label={
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Comparison
                                </Typography>
                            }
                            control={
                                <Switch
                                    checked={showTwoGroups}
                                    onChange={handleToggleGroups}
                                    color="primary"
                                />
                            }
                        />
                    </Box>
                </Grid>

                <Grid item xs={1} md={1}>
                    <Divider orientation="vertical" flexItem sx={{ height: '100%', margin: '0 16px' }} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Grid container spacing={2} sx={{ width: '100%' }}>
                                <Grid item xs={5} md={5}> {/* 45% width for Group A */}
                                    <Droppable droppableId="groupOne">
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={{ padding: 1, textAlign: 'center' }}
                                            >
                                                <Typography variant="h6" sx={{ width: '100%', textAlign: 'center', marginBottom: 2 }}>
                                                    <strong>Selected Group {showTwoGroups ? 'A' : ''}</strong>
                                                </Typography>
                                                {groupOne.map((candidate, index) => (
                                                    <Draggable key={candidate.id} draggableId={candidate.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <Chip
                                                                    label={`${candidate.name} (${candidate.pool_name ? candidate.pool_name : candidate.team_name})`}
                                                                    onDelete={() => handleDelete(candidate)}
                                                                    sx={{
                                                                        margin: '4px',
                                                                        backgroundColor: candidate.pool_name ? '#FFE4AD' : '#8FD3CB',
                                                                        color: '#575757'
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </Grid>

                                {showTwoGroups && <Grid item xs={2} md={2} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                    <Typography variant="h6">
                                        <strong>VS</strong>
                                    </Typography>
                                </Grid>}

                                {showTwoGroups && <Grid item xs={5} md={5}> {/* 45% width for Group B */}
                                    <Droppable droppableId="groupTwo">
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={{ padding: 1, textAlign: 'center' }}
                                            >
                                                <Typography variant="h6" sx={{ width: '100%', textAlign: 'center', marginBottom: 2 }}>
                                                    <strong>Selected Group B</strong>
                                                </Typography>
                                                {groupTwo.map((candidate, index) => (
                                                    <Draggable key={candidate.id} draggableId={candidate.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <Chip
                                                                    label={`${candidate.name} (${candidate.pool_name ? candidate.pool_name : candidate.team_name})`}
                                                                    onDelete={() => handleDelete(candidate)}
                                                                    sx={{
                                                                        margin: '4px',
                                                                        backgroundColor: candidate.pool_name ? '#FFE4AD' : '#8FD3CB',
                                                                        color: '#575757'
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </Grid>}
                            </Grid>
                        </DragDropContext>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
};

export default ControlPanel
