import { createTheme } from '@mui/material';
import RalewayWoff2 from './assets/fonts/raleway/raleway-light-webfont.woff2';

export const MUITheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: '#00B0A0'
      },
      error: {
        main: "#C13F8A"
      }
    },
    components: {
      MuiInputLabel: {
        defaultProps: {
          sx: {
            color: '#979797',
            fontSize: "14px",
            top: 2,
          },
        },
        styleOverrides: {
          shrink: ({ ownerState }) => ({
            ...(ownerState.shrink && {
              fontSize: "15px !important",
              top: "-1 !important",
            }),
          }),
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&&:hover::before": {
              borderBottom: '1px solid grey'
            },
            '&:after': {
              borderBottom: '1px solid grey',
            },
          }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            fontSize: "14px",
          }
        }
      },
      MuiAccordion: {
        

        styleOverrides: {
          root: {
            ".Mui-expanded": {
              background: "#E0F4F1",
              margin: "0px",
            }
          }
        }
      },
    },
    typography: {
      fontSize: 12.3,
      fontFamily: 'Raleway',
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'Raleway';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
        },
      }
    }
  });


