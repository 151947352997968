import { OverlayTrigger, Tooltip} from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";

function ExplanationTooltip(props) {
    const { children } = props;

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            { children }
        </Tooltip>
    );
    
    return (
        <span className="explanation-tooltip">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <span><AiOutlineQuestionCircle /></span>
            </OverlayTrigger>
        </span> 
    )
}

export default ExplanationTooltip;




