import {Select, Text, TextArea} from '../formComponents/Inputs';

//import Button from "../reusableElementsComponents/Button";
import {TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import * as Constants from "../../constants";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import React, {useState, useEffect} from "react";

function TeamReviewGeneralTemplate({formValidate, handleTitleChange, jobIndustries, handleSelectIndustry,selectedDepartment,handleDescriptionChange, create, handleEmailListChange})
    {
        const [candidateEmailError, setCandidateEmailError] = useState(false)
        // eslint-disable-next-line
        const [formCompleted, setFormCompleted] = useState(false);
        const [candidateEmailMessage, setCandidateEmailMessage] = useState('Press enter to add')
        const [emailList, setEmailList] = useState([]);

        const doEmailDelete = (toDelEmail) => {
            const newEmailList = [];
            emailList.forEach((email) => {
                if(toDelEmail !== email){
                    newEmailList.push(email)
                }
            })
            handleEmailListChange(newEmailList)
            setEmailList(newEmailList);
        }

        useEffect(() => {
            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
            };
            // eslint-disable-next-line
          }, [formCompleted]);


          function handleBeforeUnload(event) {
            if (!formCompleted) {
              event.preventDefault();
              event.returnValue = ""

            }
          }

        return (
            <>
                <div className="title-and-target-profile section">
                    <div className="title">
                        <div className="field-label">Team name <span className="mandatory">*</span></div>
                        <Text
                            title=''
                            required={true}
                            fullWidth={true}
                            doValidation={formValidate}
                            errorContent={"Please enter team name"}
                            handleChange={handleTitleChange}
                            // defaultValue={editTeamFormData.title}
                        />
                    </div>
                    <div className="department">
                        <div className="field-label">Department<span className="mandatory">*</span></div>
                        <Select
                            id={'industry_id'}
                            label='Departments List'
                            options={jobIndustries}
                            doValidation={formValidate}
                            errorContent={"Please select department"}
                            handleChange={handleSelectIndustry}
                            option={selectedDepartment.title ? selectedDepartment.title : ""}
                            defaultValue={"Select job department"}
                        />
                    </div>
                </div>

                <div className="description">
                    <div className="field-label">Description</div>
                    <TextArea
                        title=''
                        required={false}
                        fullWidth={true}
                        handleChange={handleDescriptionChange}
                    />
                </div>

                <div className="team-members-section section">
                    <div className="invite-emails-list">
                        <div className="field-label">Team members email list <span className="mandatory">*</span></div>
                        <TextField
                            class="emails-field"
                            variant="standard"
                            id="candidate_email"
                            name="candidate_email"
                            placeholder="Comma separated list of email addresses"
                            type="text"
                            error={candidateEmailError}
                            helperText={candidateEmailMessage}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    const inputEmail = e.target.value.trim();

                                    if (inputEmail.includes(",")) {
                                        // Multiple email addresses separated by comma
                                        const emailsArray = inputEmail.split(",").map(email => email.trim());

                                        let allValid = true;
                                        emailsArray.forEach(email => {
                                            if (!Constants.VALID_EMAIL.test(email)) {
                                                allValid = false;
                                            }
                                        });

                                        if (allValid) {
                                            const list = [...emailList];
                                            list.push(...emailsArray);
                                            setEmailList(list);
                                            handleEmailListChange(list);
                                            e.target.value = '';
                                        } else {
                                            setCandidateEmailError(true);
                                            setCandidateEmailMessage('Invalid email address(es)');
                                        }
                                    } else {
                                        // Single email address
                                        if (Constants.VALID_EMAIL.test(inputEmail)) {
                                            const list = [...emailList];
                                            list.push(inputEmail);
                                            setEmailList(list);
                                            handleEmailListChange(list);
                                            e.target.value = '';
                                        } else {
                                            setCandidateEmailError(true);
                                            setCandidateEmailMessage('Invalid email address');
                                        }
                                    }
                                }

                            }}
                        />
                        <br/>
                        <br/>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                            }}
                            component="ul"
                        >
                            {emailList && emailList.map((email, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={email}
                                        onDelete={()=>{doEmailDelete(email)}}
                                    />
                                )
                            })}
                            {(!emailList || emailList.length === 0) && <p className="m-0 bold">Team members list is empty</p>}
                            {emailList && emailList.length > 0 && !create &&
                            <ul className='teamMembers'>
                            </ul>
                            }
                        </Paper>
                    </div>
                </div>
            </>
        )
    }

export default TeamReviewGeneralTemplate





