import React, {useEffect, useMemo, useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import * as Constants from "../../constants";
import B2BApiService from "../../B2bApiService";
import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';

export default function AssignTeamsComp() {
  const b2bServiceObj = useMemo(() => {
    return new B2BApiService();
  }, []);

  const [assignTeams, setAssignTeams] = useState({});
  const [changedRows, setChangedRows] = useState({});
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);

  const handleMultiSelectTeams = (e, userId) => {
    setAssignTeams((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [userId]: e.value,
    }));
    setChangedRows((prevChangedRows) => ({
      ...prevChangedRows,
      [userId]: true,
    }));
  };


  const multiSelectTeamsTemplate = (rowData) => (
    <MultiSelect
      key={rowData?.id}
      value={assignTeams[rowData.id] || []}
      options={teams.map((team) => team.title)} // Update here
      onChange={(e) => handleMultiSelectTeams(e, rowData.id)}
      filter
      maxSelectedLabels={2}
    />
  );

  const saveAssignedTeams = (userId) => {
    let teamHashes = assignTeams[userId].map(title => {
      const item = teams.find(item => item.title === title);
      return item ? item.id : null;
    });

    b2bServiceObj.UpdateUserAccessToTeams(userId, teamHashes,(res) => {
      if(res?.status){
        //@todo : Show toast for teams assigned to user
        setChangedRows((prevChangedRows) => ({
          ...prevChangedRows,
          [userId]: false,
        }));
      }
    })
  }

  const actionTemplate = (rowData) => (
      <IconButton
          style={{ padding: '10px 10px'}}
          onClick={() => saveAssignedTeams(rowData.id)}
      >
        <SaveIcon className={changedRows[rowData.id] ? 'icon color-blink' : 'icon'}  style={{fontSize:"30" }}/>
      </IconButton>
  );

  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'multiSelectTeams', header: 'Assigned Teams', body: multiSelectTeamsTemplate },
    { field: 'action', header: 'Actions', body: actionTemplate },
  ];

  useEffect(() => {
    b2bServiceObj.LoadTeamsWithIdAndTitle((data) => {
      setTeams(data);
    })

    b2bServiceObj.GetCompanyAccountsByRoleId(Constants.USER_ROLE_TEAMLEAD,(data) => {
      let filteredData = data.filter((user) => user.role_id === Constants.USER_ROLE_TEAMLEAD);

      let transformedData = filteredData.map((user) => ({
        id : user.id,
        name: `${user.first} ${user.last}`,
        email: user.email,
        role: user.role_id,
        access: Constants.getUserRoleStringByRoleId(user.role_id), // Default to "Unknown" if role_id is not found
      }));
      setUsers(transformedData)
    })
  }, [b2bServiceObj]); // Empty dependency array ensures this effect runs only once on component mount


  return (
    <div className="user-list-wrapper-div">
      <div className="margin-left-div">
        <div className="user-list-inner-div">
          <DataTable value={users} paginator rows={5} rowsPerPageOptions={[10, 15, 20]}>
            {columns.map((column, i) => (
              <Column key={i} field={column.field} header={column.header} body={column.body} />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
}
