import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid";
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Toast } from 'primereact/toast';
import ReactPaginate from "react-paginate";
import * as PropTypes from "prop-types";
import { scroll } from '../../Utilities';
// import { Button  } from 'primereact/button';
import { Menu } from 'primereact/menu';
MazhrDataGrid.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.any),
    pageSize: PropTypes.number,
    columns: PropTypes.any,
    onRowSelectionModelChange: PropTypes.func,
    checkboxSelection: PropTypes.bool
};
function MazhrDataGrid (props) {
    const [emailsText, setEmailsText] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(props?.rows[0]?.id);
    const [userIds, setUserIds] = useState([]);
    const [moreOptions, setMoreOptions] = useState(false)
        // eslint-disable-next-line
    const [rowsSelected, setRowsSelected] = useState()
    const toast = useRef(null);
    const dataGridApiRef = useGridApiRef();
    const pageCount = Math.ceil(props.rows.length / props.pageSize);
    const menuLeft = useRef(null);
    const changePage = ({ selected }) => {
        dataGridApiRef.current.setPage(selected)
        scroll()
    }

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [gridHeight, setGridHeight] = useState(0);

    useEffect(() => {
        // Function to update viewport height when window is resized
        const handleWindowResize = () => {
            setViewportHeight(window.innerHeight);
        };

        // Attach the event listener
        window.addEventListener('resize', handleWindowResize);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(()=> {
        setSelectedRowId(props?.rows[0]?.id)
    },[props?.rows])

    const setDivHeight = useCallback(() => {
        // Calculate the desired height
        const fixedValue = 220;
        const desiredHeight = viewportHeight - fixedValue;

        // Set the height of the target div
        setGridHeight(desiredHeight);
    }, [viewportHeight]);

    useEffect(() => {
        setDivHeight();
    }, [setDivHeight]);

    const handleDeleteModal = (rows) => {
        props?.setDeleteModalState(true)

        props?.setPathCheck(true)
        props?.setMultipleIds(rows)
    }
    const handleMoveModal = (rows) => {
        props?.setPathCheck(true)
        props?.setMoveModalState(true)
        props?.setMultipleIds(rows)
    }
    const handleClick = (emails) => {
        navigator.clipboard
          .writeText(emailsText) // Use the emailsText state here
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Emails Copied to clipboard!', life: 1000 });
      };
    useEffect(() => {
        if (props?.gridOptionsCheck) {
          // Do nothing, keep the original items array
          setItems([
            {
              label: 'Actions',
              items: [
                {
                  label: 'Copy all email',
                  icon: 'pi pi-clone',
                  command: () => handleClick(emailsText),
                },
                {
                  label: 'Delete from pool',
                  icon: 'pi pi-trash',
                  command: () => handleDeleteModal(userIds)
                },
                {
                  label: 'Move to another pool',
                  icon: 'pi pi-arrows-h',
                  command: () => handleMoveModal(userIds)
                },
              ],
            },
          ])
        } else {
          // Update the items array to only include the "Copy all email" option
          setItems([
            {
              label: 'Actions',
              items: [
                {
                  label: 'Copy all email',
                  icon: 'pi pi-clone',
                  command: () => handleClick(emailsText),
                },
              ],
            },
          ]);
        }
               // eslint-disable-next-line
      }, [props?.gridOptionsCheck, emailsText]);

      const [items, setItems] = useState([
      ]);
      const handleSelectionModelChange = (newSelectionModel) => {
        if(Array.isArray(newSelectionModel)) {
          const selectedRows = props.rows.filter((row) =>
          newSelectionModel.includes(row.id)
        );
        setRowsSelected([...selectedRows]);
        props.onRowSelectionModelChange(selectedRows);

        if (selectedRows.length > 0) {
          const selectedIds = selectedRows.map((row) => row.id);
          setUserIds(selectedIds.length > 1 ? selectedIds : [selectedIds[0]]);
          const copiedEmails = selectedRows.map((row) => row.email);
          setEmailsText(copiedEmails.join('\n'));
          setMoreOptions(true);
        } else {
          setUserIds([]); // or setUserIds('')
          setMoreOptions(false);
        }
        }
      };


    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <div className="toolbar">
                    <GridToolbarQuickFilter />
                    {moreOptions && <div >
                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                        <i class="pi pi-ellipsis-v more-options" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup></i>
                        {/* <Button  icon="pi pi-ellipsis-v" rounded text className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup /> */}
                    </div>}
                </div>
            </GridToolbarContainer>
        );
    }
    /**
     * Update rows to add additional styling
     */
    const updatedColumns = props.columns.map((element) => (
        {
            ...element, flex: 1,
            headerAlign: "center",
            align: "center",
        }));
    const handleRowSelected = (params) => {
        props.onRowSelectionModelChange(params.row)
        handleSelectionModelChange(params.row.id)
        setSelectedRowId(params.row.id)
    };
    return <div id="top" className="talent-items" style={{ height: gridHeight }}>
        <div>
            <Toast ref={toast} />
        </div>
        <DataGrid
            autoHeight
            apiRef={dataGridApiRef}
            checkboxSelection={props.checkboxSelection}
            rowHeight={70}
            rows={props.rows}
            disableRowSelectionOnClick
            getRowClassName={(params) => {
                return params?.row?.id === selectedRowId ? 'custom-row-class' : '';
              }}
            // rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            base
            sx={{
                "& .MuiDataGrid-columnHeader--moving": {
                    backgroundColor: "transparent",
                },
                '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                },
                '.MuiDataGrid-row:not(.Mui-selected)': {
                    borderRight: "1px solid",
                },
                '.MuiDataGrid-row.Mui-selected': {
                    backgroundColor: "#f0f4f4",
                },
                '.MuiDataGrid-row.Mui-selected:hover': {
                    backgroundColor: "#f0f4f4",
                },
                '& .MuiDataGrid-root': {
                    borderRight: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                    overflowX: "hidden",
                    overflowY: "scroll !important",
                    height: "500px"
                },
                "& .talent-item:first-child:hover": {
                    backgroundColor: "transparent",
                    border: "none"
                },
                "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
                    borderTopLeftRadius: "0px !important",
                    borderTopRightRadius: "0px !important"
                },
                "& .MuiDataGrid-root.MuiDataGrid-autoHeight": {
                    border: "none !important"
                },
                // "& .MuiDataGrid-iconButtonContainer.css-ltf0zy-MuiDataGrid-iconButtonContainer": {
                //     display: "none"
                // }
            }}
            initialState={{
                ...props.rows.initialState,
                pagination: { paginationModel: { pageSize: props.pageSize, page: 0 } },
            }}
            pageSizeOptions={[10, 15, 30, 50]}
            columns={updatedColumns}
            slots={{
                toolbar: CustomToolbar,
            }}
            onRowClick={handleRowSelected}
            style={{ height: gridHeight - 80 }}
        />
        {
            <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination"}
                previousLinkClassName={"prevBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                pageClassName={"pagination-item"}
            />
        }
        <br />
    </div>;
}
export default MazhrDataGrid
