import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter as Router} from 'react-router-dom';

import './styles/index.scss';
import { initGA4 } from './analytics';
import App from './App';
import { store } from './store/store';

initGA4(); // Initialize Google Analytics 4

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
  <Provider store={store}>
    <App />
  </Provider>
</Router>
);
