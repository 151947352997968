import { useState } from 'react';
import { EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import { stateToHTML } from "draft-js-export-html"
import ExplanationTooltip from "../../components/reusableElementsComponents/ExplanationTooltip";


function EmailTemplates({data}) {

    const [sendToAssessmentsEmail, setSendToAssessmentsEmail] = useState(EditorState.createEmpty());
    const [rejectEmail, setRejectEmail] = useState(EditorState.createEmpty());
    const [qualifyEmail, setQualifyEmail] = useState(EditorState.createEmpty());

    function handleChange(value, key) {
        data[key] = value;
        setEmailData({ ...newPositionFormData, [key]: value });
    }

    let [newPositionFormData, setEmailData] = useState(data);

    return (
        <div className="email-templates">
            <p className='m bold'>
                Email Templates
                <ExplanationTooltip>
                    <p>From here you can send email invitations to people considering assessments or information on how the process continues.</p>
                </ExplanationTooltip>
            </p>
            <div className="email-editor">
                <p className="sub-title grey regular">Send to assessments</p>
                <Editor
                    editorState={sendToAssessmentsEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setSendToAssessmentsEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(sendToAssessmentsEmail.getCurrentContent()), 'send_assessment_email');
                    }}
                />
            </div>
            <div className="email-editor">
                <p className="sub-title grey regular">Reject</p>
                <Editor
                    editorState={rejectEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setRejectEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(rejectEmail.getCurrentContent()), 'qualify_email');
                    }}
                />
            </div>
            <div className="email-editor">
                <p className="sub-title grey regular">
                    Qualify
                    <ExplanationTooltip>
                        <p>This is how you know if person qualifies</p>
                    </ExplanationTooltip>
                </p>
                <Editor
                    editorState={qualifyEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setQualifyEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(qualifyEmail.getCurrentContent()), 'reject_email');
                    }}
                />
            </div>
        </div>
    )
}

export default EmailTemplates
