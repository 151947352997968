import { combineReducers } from 'redux';

import contentfulDataReducer from './contentfulDataReducer';
import contentfulAnalyticsDataReducer from './contentfulAnalyticsDataReducer';
import positionDataReducer from './positionDataReducer';
import companyReducer from './companyReducer';
import teamDataReducer from './teamDataReducer';

const reducers = combineReducers({
  contentfulData: contentfulDataReducer,
  contentfulAnalyticsData: contentfulAnalyticsDataReducer,
  positionData: positionDataReducer,
  teamData: teamDataReducer,
  company: companyReducer,
});

export default reducers;
