import React, {useState, useEffect, useMemo} from 'react';
//import {Text} from '../../components/formComponents/Inputs';
import {Select} from '../../components/formComponents/Inputs';
import Checkbox from '@mui/material/Checkbox';
import B2BApiService from "../../B2bApiService";
import {capitalizeFirstLowercaseRest} from "../../Utilities";


export default function DefineRoles() {
    const [selectedRole, setSelectedRole] = useState("")
    const [, setSelectedRoleId] = useState(null)
    const [jobRoles, setJobRoles] = useState([]); // State variable for job roles
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);
    //const [inputRole, setInputRole] = useState("")
    const [initialRolesForm, setInitialRolesForm] = useState({});

    const roleConfigFormMapping = (config) => {
        return {
            "User Management": [
                {
                    title: "Roles",
                    items: [
                        {id: "createRoles", title: "Create", isChecked: config.role_permissions?.create},
                        {id: "editRoles", title: "Edit", isChecked: config.role_permissions?.edit},
                        {id: "deleteRoles", title: "Delete", isChecked: config.role_permissions?.delete},
                    ],
                },
                {
                    title: "Users",
                    items: [
                        {id: "createUser", title: "Create", isChecked: config.user_permissions?.create},
                        {id: "EditUser", title: "Edit", isChecked: config.user_permissions?.edit},
                        {id: "deleteUser", title: "Delete", isChecked: config.user_permissions?.delete},
                        {id: "assignRole", title: "Assign Role To Other Users", isChecked: config.user_permissions?.assign_role},
                    ],
                }
                /*,
                {
                    title: "Company",
                    items: [{id: "deleteAccount", title: "Delete", isChecked: false}],
                },*/
            ],
            /*"Billing": [
                {
                    title: "Billing",
                    items: [
                        {id: "addBillingDetails", title: "Add", isChecked: false},
                        {id: "editBillingDetails", title: "Edit", isChecked: false}]
                },
                {
                    title: "Subscriptions",
                    items: [{id: "editSubscriptions", title: "Edit", isChecked: false}]
                }
            ],*/
            "Team Management": [
                {
                    title: "Teams",
                    items: [
                        {id: "createTeams", title: "Create New", isChecked: config.team_permissions?.create},
                        {id: "editTeams", title: "Edit Team", isChecked: config.team_permissions?.edit},
                        {id: "viewTeams", title: "View All", isChecked: config.team_permissions?.view_all},
                        {id: "assignTeams", title: "Assign Teams To Other Users", isChecked: config.team_permissions?.can_assign},]
                },
                {
                    title: "Team Members",
                    items: [
                        {id: "addToTeam", title: "Add To Team", isChecked: config.team_members_permissions?.add_to_team},
                        {id: "remove", title: "Remove From Team", isChecked: config.team_members_permissions?.remove},
                        {id: "viewDetails", title: "View Details", isChecked: config.team_members_permissions?.view_details},
                        {id: "canAssign", title: "Assign Team Member", isChecked: config.team_members_permissions?.can_assign},]
                }
            ],
            "Recruitment": [
                {
                    title: "Talent Pools (Job Positions)",
                    items: [
                        {id: "createOpenPositions", title: "Create New", isChecked: config.pool_permissions?.create},
                        {id: "editOpenPositions", title: "Edit Pool", isChecked: config.pool_permissions?.edit},
                        {id: "viewOpenPositions", title: "View All", isChecked: config.pool_permissions?.view_all},
                        {id: "assignOpenPositions", title: "Assign Pool To Other User", isChecked: config.pool_permissions?.can_assign},
                    ]
                },
                {
                    title: "Talent Status",
                    items: [
                        {id: "canView", title: "View Details", isChecked: config.pool_members_permissions?.view_details},
                        {id: "canRemove", title: "Remove From Pool", isChecked: config.pool_members_permissions?.remove},
                        {id: "canAddToTeam", title: "Add To Team", isChecked: config.pool_members_permissions?.add_to_team},
                    ]
                },
            ],
            "Analytics": [
                {
                    title: "View Analytics",
                    items: [
                        {id: "viewTeamAnalytics", title: "Teams", isChecked: config.analytics_permissions?.team_analytics, },
                        {id: "viewPositionsAnalytics", title: "Talent Pools (Job Positions)", isChecked: config.analytics_permissions?.pool_analytics}
                    ]
                }
            ],
        }
    }

    const handleSelectRole = (e) => {
        for (const item of jobRoles) {
            if (item.title === e.target.value) {
                setSelectedRoleId(item.id);
                setSelectedRole(item.title)
                if (item.id === 0) {
                    //setInputRole("")
                } else {
                    b2bServiceObj.GetRoleConfigById(item.id,(apiData) => {
                        setInitialRolesForm(roleConfigFormMapping(apiData))
                    })
                }
                return item.id;
            }
        }
    }

    useEffect(() => {
        b2bServiceObj.GetCorporateUserRoles((data) => {
            let formattedJobRoles = data.map((jobRole) => ({
                id: jobRole.id,
                title: capitalizeFirstLowercaseRest(jobRole.name),
            }));
            /*formattedJobRoles = [
                ...formattedJobRoles,
                {
                    id: 0,
                    title: "Create New Role",
                },
            ];*/
            setJobRoles(formattedJobRoles);
        })
    },[b2bServiceObj])

    /*const handleNewRole = (e) => {
        setInputRole(e.target.value)
    }*/

    const handleChange = (key, sectionIndex, itemIndex) => {
        setInitialRolesForm((prevState) => {
            const updatedForm = {...prevState};
            const section = updatedForm[key][sectionIndex];
            const item = section.items[itemIndex];
            item.isChecked = !item.isChecked; // Toggle the checkbox state
            return updatedForm;
        });
    };

    return (
        <div className='role-wrapper-div'>
            <div className='custom-margin-div'>

                <div className='role-invite-div'>
                    <div className='config-note'>
                        <p>Select role from dropdown to see its available permissions. Permission are <b>non editable</b>. Editable roles coming soon</p>
                    </div>
                    <div className='role-dropdown'>
                        <div className='flex-end-div'>
                            <Select
                                options={jobRoles}
                                label='Select User Role'
                                handleChange={handleSelectRole}
                                option={selectedRole ? selectedRole : ''}
                            />
                        </div>
                        {/*<div className='resize-textfield'>
                <Text
                    title="Enter Role"
                    value={inputRole ? inputRole : ''}
                    required={true}
                    fullWidth={true}
                    handleChange={e => handleNewRole(e)}
                />
                </div>*/}
                    </div>
                    {/*<button className="mazhrBtn default-green">Create Role</button>*/}
                </div>
                <div className='user-list-inner-div disable-pointer'>
                    {Object.keys(initialRolesForm).map((key) => (
                        <div className='roleUpdationForm' key={key}>
                            <div className='role-title-div'>
                                <span>{key}</span>
                            </div>
                            <div className='grid-container'>
                                {initialRolesForm[key].map((section, sectionIndex) => (
                                    <div key={sectionIndex} className="permissions-grid-view">
                                        <div className="section-title"><strong>{section.title}</strong></div>
                                        <div className="checkboxes-grid-view">
                                            {section.items.map((item, itemIndex) => (
                                                <div key={item.id}>
                                                    <Checkbox
                                                        checked={item.isChecked}
                                                        onChange={() => handleChange(key, sectionIndex, itemIndex)}
                                                        inputProps={{"aria-label": "controlled"}}
                                                    />
                                                    <span>{item.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    {/*<div className='role-button-div'>
                        <button className='mazhrBtn default-green'>Update Role</button>
                    </div>*/}
                </div>
            </div>
        </div>
    );
};

