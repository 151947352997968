import {ActionTypes} from "../constants/action-types";

export const setCompanyInfo = (info) => {
    return {
        type: ActionTypes.SET_COMPANY_INFO,
        payload: info
    };
}

export const setCompanyStatus = (status) => {
    return {
        type: ActionTypes.SET_COMPANY_STATUS,
        payload: status
    };
}
