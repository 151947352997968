import React, { useState, useEffect, useMemo } from 'react'
import B2BApiService from "../../B2bApiService";
import { Select, Text, TextArea } from "../formComponents/Inputs";
import { TextField } from "@mui/material";
import * as Constants from "../../constants";
import {transformIndustryData, currentUserRole} from '../../Utilities'
import email from "../../assets/images/icons/email.svg";
import Button from "../reusableElementsComponents/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EmailIcon from '@mui/icons-material/Email';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Tooltip } from 'react-tooltip'
import Modal from '../reusableElementsComponents/Modal';
import { ToastContainer, toast } from 'react-toastify';
import * as Utils from "../../Utilities";


function TeamReviewGeneralEdit({ data, updateData, formValidate, teamHash, dataEditedFlag, setDataEditedFlag }) {
    const [memberList, setMemberList] = useState(data?.members);

    let [jobIndustries, setJobIndustries] = useState([]);

    let [editTeamFormData, setEditTeamFormData] = useState(data);
    const [selectedDepartment, setSelectedDepartment] = useState(() => {
        return transformIndustryData(data?.industry);
    });

    const [candidateEmailError, setCandidateEmailError] = useState(false)
    const [resendingInvite, setResendingInvite] = useState(false)
    const [candidateEmailMessage, setCandidateEmailMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [selectedInviteUrl, setSelectedInviteUrl] = useState("")

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const handleTitleChange = (e) => {
        setDataEditedFlag(true)
        data['title'] = e.target.value
        setEditTeamFormData({ ...editTeamFormData, 'title': e.target.value });
    }

    const handleDescriptionChange = (e) => {
        setDataEditedFlag(true)
        data['description'] = e.target.value
        setEditTeamFormData({ ...editTeamFormData, 'description': e.target.value });
    }

    const handleChange = (value, key) => {
        setDataEditedFlag(true)
        data[key] = value
        setEditTeamFormData({ ...editTeamFormData, [key]: value });
    }

    const handleSelectIndustry = (e) => {
        if (e) {
            for (let i = 0; i < jobIndustries.length; i++) {
                if (jobIndustries[i].title === e.target.value) {
                    handleChange(jobIndustries[i].id.toString(), 'industry_id')
                    setSelectedDepartment( transformIndustryData(jobIndustries[i]));
                    return;
                }
            }
        }
    }

    const removeMemberFromTeam = (toDelMember) => {
        setMemberList(prevMemberList => {
            const newMemberList = prevMemberList.filter(member => member.email !== toDelMember.email);
            handleChange(newMemberList, 'members');
            return newMemberList;
        });
    }

    const addNewTeamMember = (inputEmails) => {
        if (inputEmails === undefined) {
            let inputObj = document.getElementById('candidate_email');
            if (inputObj) {
                inputEmails = inputObj.value;
            }
        }

        const emails = inputEmails.split(',').map(email => email.trim());

        const validEmails = emails.filter(email => Constants.VALID_EMAIL.test(email));
        const invalidEmails = emails.filter(email => !Constants.VALID_EMAIL.test(email));

        const list = memberList;

        validEmails.forEach(email => {
            const existingEmail = list.find(member => member.email.toLowerCase() === email.toLowerCase());
            if (!existingEmail) {
                list.push({
                    email: email,
                    first: "",
                    last: "",
                    user_status: 10,
                    invite_status: 1,
                    invited: true,
                    invite_accepted: false,
                    invite_rejected: false
                });
            }
        });

        setMemberList(list);
        handleChange(list, 'members');
        document.getElementById('candidate_email').value = '';

        if (invalidEmails.length > 0) {
            setCandidateEmailError(true);
            setCandidateEmailMessage('Invalid email address(es): ' + invalidEmails.join(', '));
        } else {
            setCandidateEmailError(false);
            setCandidateEmailMessage('');
        }
    };

    const resendInvite = (member) => {
        if (resendingInvite) {
            return;
        }

        let resendInviteToast = toast.loading("Sending...");
        setResendingInvite(true)

        let payload = { "user-id": member.id, "team-hash": teamHash };
        b2bServiceObj.ResendTeamInvite(payload, (res) => {
            if(res.status){
                toast.update(resendInviteToast, {
                    render: "Invite resent.",
                    type: "success",
                    isLoading: false,
                    autoClose: '1000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }else{
                toast.update(resendInviteToast, {
                    render: "Unable to send invite, try again later",
                    type: "error",
                    isLoading: false,
                    autoClose: '1000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }
            setResendingInvite(false);
        });
    };

    const copyUrlToClipboard = () =>{
        navigator.clipboard.writeText(selectedInviteUrl);
        let copyUrlClipBoardToast = toast.loading("Sending...");
        toast.update(copyUrlClipBoardToast, {
            render: "Url copied to clipboard",
            type: "success",
            isLoading: false,
            autoClose: '1000',
            closeButton: true,
            hideProgressBar: true
        });
        setVisible(false);
    }

    useEffect(() => {
        b2bServiceObj.LoadIndustriesList(setJobIndustries);
    }, [b2bServiceObj, setJobIndustries]);

    useEffect(() => {
        let members = [];
        if (data && data.members) {
            members = data.members;
        }

        setMemberList(members)
        setEditTeamFormData(data)
        if (data?.industry) {
            setSelectedDepartment(transformIndustryData(data.industry));
        }
    }, [data])

    useEffect(() => {
        updateData(editTeamFormData)
    }, [updateData, editTeamFormData])


    return (
        <>
            <ToastContainer />
            <div className="title-and-target-profile section">
                <div className="title">
                    <div className="field-label">Team name <span className="mandatory">*</span></div>
                    <Text
                        title=''
                        required={true}
                        fullWidth={true}
                        doValidation={formValidate}
                        errorContent={"Please enter position title"}
                        handleChange={handleTitleChange}
                        value={editTeamFormData?.title}
                    />
                </div>
                {<div className="department">
                    <div className="field-label">Department <span className="mandatory">*</span></div>
                    <Select
                        id={'industry_id'}
                        label='Departments List'
                        options={jobIndustries}
                        doValidation={formValidate}
                        errorContent={"Select Job Department"}
                        handleChange={handleSelectIndustry}
                        option={selectedDepartment.title ? selectedDepartment.title : ""}
                        defaultValue={"Select job department"}
                    />
                </div>}
            </div>

            <div className="description">
                <div className="field-label">Description</div>
                <TextArea
                    title=''
                    required={false}
                    fullWidth={true}
                    handleChange={handleDescriptionChange}
                    value={data?.description}
                />
            </div>

            <div className="team-members-section section">
                <div className="field-label">Team members email list <span className="mandatory">*</span></div>
                {(!memberList || memberList.length === 0) && <div><br /><p className="m-0">No members found</p></div>}
                <ul className='teamMembers'>
                    {memberList && memberList.map((member, index) => {
                        return (
                            <li
                                className={`teamMember ${member.invited ? "pending" : ""} ${member.invite_rejected ? "rejected" : ""}`}
                                key={index}>
                                <div className="title">
                                    <p className='name'>{member.first} {member.last}</p>
                                    <p className='email m-0'>{member.email}</p>
                                </div>
                                <div className="actions">
                                    {
                                        member?.invited &&
                                        <EmailIcon
                                            className='icon email button-tooltip-element'
                                            src={email} alt=""
                                            data-tooltip-content="Resend Invite"
                                            onClick={() => {
                                                resendInvite(member)
                                            }}
                                        />
                                    }
                                    {
                                        member?.invite_url &&
                                        (<>
                                        <ContentCopyIcon
                                            className='icon copy-url button-tooltip-element'
                                            data-tooltip-content="Copy Invite Url"
                                            onClick={() => {
                                                setSelectedInviteUrl(member?.invite_url)
                                                setVisible(true)
                                            }}
                                        />

                                        </>)

                                    }
                                    {currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <DeleteForeverIcon
                                        className='icon bin button-tooltip-element'
                                        data-tooltip-content="Remove Member"
                                        onClick={() => {
                                            removeMemberFromTeam(member)
                                        }}
                                    />}
                                </div>
                            </li>
                        )
                    })}
                </ul>
                {currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <div className="inviteteamMember">
                    <TextField
                        className="text-field"
                        variant="standard"
                        id="candidate_email"
                        label="Invite a new team member by email (comma separated if more then one)"
                        name="candidate_email"
                        type="text"
                        fullWidth
                        error={candidateEmailError}
                        helperText={candidateEmailMessage}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                addNewTeamMember(e.target.value)
                            }
                        }}
                    />
                    <Button btnstyle={'mazhrBtn light-green invite-button'} onClick={() => addNewTeamMember()}> <PersonAddAlt1Icon /> &nbsp; Invite</Button>
                </div>}
                <Tooltip anchorSelect=".button-tooltip-element" />
                <Modal visible={visible} onHide={()=> setVisible(false)}
                    buttonLabel={'Copy'}
                    title='Invite Url Is User Specific'
                    text={selectedInviteUrl}
                    actionButton={()=> copyUrlToClipboard()}
                    cancel={()=> setVisible(false)} />
            </div>
            {dataEditedFlag && <div className="unsaved-changes">unsaved changes <span className="cta-txt">(Click Save Button)</span></div>}
        </>
    )
}

export default TeamReviewGeneralEdit





