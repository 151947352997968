import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MobilePrimaryMenu , MobileSecondaryMenu } from './NavData';
import hamburgerMenu from '../../../assets/images/icons/hamburgerMenu.svg';
import exit from '../../../assets/images/icons/exit.svg';
import Logout from '../navigation/Logout';
import logout from '../../../assets/images/icons/logout.png';
import * as Constants from '../../../constants';


function MobileNav() {
    const [hiddenMenu, setHiddenMenu] = useState(false);
    const showHiddenMenu = () => setHiddenMenu(!hiddenMenu);

    const [userRole, setUserRole] = useState(parseInt(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_USER_ROLE_ID)) || null);
    useEffect(()=>{
        setUserRole(parseInt(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_USER_ROLE_ID)));
    },[userRole]);

    function disableLink(event) {
        event.preventDefault();
    }

    return (
        <div className="mobile-nav">
            <ul className="primary-menu">
                {MobilePrimaryMenu(userRole).map((item, index) => {
                    return (
                        <li key={index} className='nav-item'>
                            <NavLink activeclassname="active" className="link" to={item.path}>
                                {item.icon}
                            </NavLink>
                        </li>
                    )
                })}
                <li className='nav-item'>
                    <button className="link hamburger" onClick={showHiddenMenu}>
                        <img src={hiddenMenu ? exit : hamburgerMenu } alt="menu" />
                    </button>
                </li>

            </ul>

            {hiddenMenu ? <div className="overlay active"></div> : ''}
            <ul className={hiddenMenu ? 'secondary-menu active m-0' : 'secondary-menu m-0'}>
                {MobileSecondaryMenu.map((item, index) => {
                    return (
                        <li key={index} className='nav-item' onClick={showHiddenMenu}>
                            <NavLink activeclassname="active" className="link" to={item.path ? item.path : disableLink}>
                                <span>
                                    {item.icon}
                                    {item.title}
                                </span>
                            </NavLink>
                        </li>
                    )
                })}
                <li className='nav-item'>
                    <div className="link">
                        <span className="logout-wrapper">
                            <img src={logout} alt="logout" />
                            <Logout />
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default MobileNav
