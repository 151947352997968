import checkGreen from '../../assets/images/icons/checkGreen.svg';
import checklistGreen from '../../assets/images/icons/checklistGreen.svg';
import calendarGreen from '../../assets/images/icons/calendarGreen.svg';
import personWithSuitcaseGreen from '../../assets/images/icons/personWithSuitcaseGreen.svg';

import Loading from '../reusableElementsComponents/Loading';
import { useSelector } from 'react-redux';
import {capitalizeFirstLowercaseRest} from '../../Utilities'




function HomeQuickStart() {
    const data = useSelector( state => state.contentfulData.all['home page no data']);
    const companyData = useSelector( state => state.company.info);

    let stepCompleted = false;

    if(data !== undefined) {
        let content = data.fields;

        return (
            <div className="onboarding">
                <h3 className="extra-light m-0">{content.quickStartTitle}</h3>
                <h2 className="company-name bold">{capitalizeFirstLowercaseRest(companyData?.name)}</h2>
                <h4 className="quickstart extra-light">{content.quickStartGuide}</h4>
                <ul className="steps-wrapper p-0 m-0">
                    {/*<li className="step">
                        <img src={stepCompleted ? checkGreen : checklistGreen} alt="" />
                        <p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep1}</p>
                    </li>*/}
                    <li className="step">
                        <img src={stepCompleted ? checkGreen : checklistGreen} alt="" />
                        <a href="/settings"><p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep2}</p></a>
                    </li>
                    <li className="step">
                        <img src={stepCompleted ? checkGreen : checklistGreen} alt="" />
                        <a href="/team-review"><p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep3}</p></a>
                    </li>
                    <li className="step">
                        <img src={stepCompleted ? checkGreen : calendarGreen} alt="" />
                        <a href="/team-analytics"><p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep4}</p></a>
                    </li>
                    {/*<li className="step">
                        <img src={stepCompleted ? checkGreen : personWithSuitcaseGreen} alt="" />
                        <p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep5}</p>
                    </li>*/}
                    <li className="step">
                        <img src={stepCompleted ? checkGreen : personWithSuitcaseGreen} alt="" />
                        <a href="/open-positions"><p className={stepCompleted ? 'm-0 s medium grey' : 'm-0 s medium'}>{content.quickStartStep6}</p></a>
                    </li>
                </ul>
            </div>
        )
    }else {
        return (
            <Loading />
        )
    }
}

export default HomeQuickStart;
