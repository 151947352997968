import React, {useEffect, useMemo, useState} from 'react';
import B2BApiService from "../../B2bApiService";
import Settings from "../auth/Settings";
import ChangePassword from './ChangePassword';
import UserList from './usersList';
import DefineRoles from './DefineRoles';
import AssignTeamsComp from './AssignTeamsComp';
import AssignTeamMembersComp from './AssignTeamMembersComp';
import AssignPoolsComp from './AssignPoolsComp'

import * as Constants from "../../constants";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import cog from "../../assets/images/icons/cog.svg"
import editIcon from "../../assets/images/icons/editIcon.png"
import users from "../../assets/images/icons/users.png"
import roles from "../../assets/images/icons/roles.png"
import assignTeamsImg from "../../assets/images/icons/assignTeams.png"
import assignPoolsImg from "../../assets/images/icons/assignPools.png"
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

export default function UserRoles() {
  const [userRole, setUserRole] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const b2bServiceObj = useMemo(() => {
    return new B2BApiService();
  }, []);

  useEffect(() => {
    b2bServiceObj.LoadUserData((userData) => {
      localStorage.setItem(Constants.LOCAL_STORAGE_KEY_USER_ROLE_ID, userData.userRole)

      if(JSON.parse(localStorage.getItem(Constants.MAZHR_JWT_KEY))){
        let jwtObj = JSON.parse(localStorage.getItem(Constants.MAZHR_JWT_KEY));
        setCompanyId(jwtObj.company_id)
      }

      setUserRole(userData.userRole)
    })
  }, [b2bServiceObj]);


  return (
    <>
      <div className='page-layout-wrapper'>
        <div className='inner-layout'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row className='w-100'>
        <Col sm={12} className='d-flex vp-1200'>
          <div>
          <Nav variant="pills" className="flex-column bg-white py-3 px-2 mb-3 rounded" style={{width: "300px"}}>
            <strong className='m-2'>
              Settings
            </strong>
            <hr style={{ margin: '0px 0px 10px 0px' }} />
            <Nav.Item>
              <Nav.Link eventKey="first"> <img className='m-2' src={cog} alt='settings'/> General </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second"> <img height="20px" className='m-2' src={editIcon} alt='change-password'/> Change Password</Nav.Link>
            </Nav.Item>
          </Nav>
            {Constants.ALLOWED_USER_ROLES_COMPANIES.includes(companyId) && userRole === Constants.USER_ROLE_ADMIN && <Nav variant="pills" className="flex-column bg-white py-3 px-2 mb-3 rounded" style={{width: "300px"}}>
          <strong className='m-2'>
              User & Roles
            </strong>
            <hr style={{ margin: '0px 0px 10px 0px' }} />
            <Nav.Item>
              <Nav.Link eventKey="third"> <img height="20px" className='m-2' src={users} alt='users'/> Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth"> <img height="20px" className='m-2' src={roles} alt='roles'/> Roles</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fifth"> <img height="20px" className='m-2' src={assignTeamsImg} alt='assign-teams'/> Assign Teams</Nav.Link>
            </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sixth"> <SupervisorAccountOutlinedIcon className='m-2' style={{ color: 'black' }} /> Assign Team Members</Nav.Link>
              </Nav.Item>
            {<Nav.Item>
              <Nav.Link eventKey="seventh"> <img height="20px" className='m-2' src={assignPoolsImg} alt='assign-pools'/> Assign Pools</Nav.Link>
            </Nav.Item>}
          </Nav>}
          </div>
          <Tab.Content className='w-100'>
            <Tab.Pane eventKey="first"><Settings/></Tab.Pane>
            <Tab.Pane eventKey="second"><ChangePassword/></Tab.Pane>
            {userRole === Constants.USER_ROLE_ADMIN && <Tab.Pane eventKey="third"><UserList/></Tab.Pane>}
            {<Tab.Pane eventKey="fourth"><DefineRoles/></Tab.Pane>}
            {userRole === Constants.USER_ROLE_ADMIN && <Tab.Pane eventKey="fifth"><AssignTeamsComp/></Tab.Pane>}
            {userRole === Constants.USER_ROLE_ADMIN && <Tab.Pane eventKey="sixth"><AssignTeamMembersComp/></Tab.Pane>}
            {userRole === Constants.USER_ROLE_ADMIN && <Tab.Pane eventKey="seventh"><AssignPoolsComp/></Tab.Pane>}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
        </div>
      </div>
    </>
  );
}
