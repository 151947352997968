import {useState, useEffect, useMemo} from 'react';
import {Checkbox} from '../reusableElementsComponents/Checkbox';
import B2BApiService from "../../B2bApiService";
import ExplanationTooltip from "./ExplanationTooltip";
import {useSelector} from "react-redux";


function Assessments({data}) {
    const contentfulData = useSelector(state => state.contentfulData.all['General definations']?.fields);
    const [updateAssessments, setUpdateAssessments] = useState([]);
    const [assessments, setAssessments] = useState( []);
    const invalidInstruments = [111,357,315];

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    },[]);

    useEffect(() => {
        if (!assessments.length) {
            b2bServiceObj.LoadAssessments(setAssessments);
        }
        if (data.assessments && assessments.length) {
            let newAssessment = [...assessments];
            newAssessment.map((val) => {
                val['isChecked'] = data.assessments.includes(val.id);
                return val;
            });
            setUpdateAssessments([...newAssessment]);
        }
        else {
            setUpdateAssessments([...assessments]);
        }
    }, [b2bServiceObj, assessments, data]);

    function handleChange(e, value) {
        if (e.target.checked) {
            data.assessments.push(value.id);
            value.isChecked = true;
            setUpdateAssessments([...updateAssessments]);
        } else {
            data.assessments.splice(data.assessments.indexOf(value), 1)
            value.isChecked = false;
            setUpdateAssessments([...updateAssessments]);
        }
    }

    return (
        <div className='assessment-card-wrapper'>
            {updateAssessments &&
            updateAssessments.map((assessment, index) => {
                if (!invalidInstruments.includes(assessment.instrument)) {
                    return (
                        !assessment['is-custom-test'] && assessment['instrument'] !== 111 &&
                        <div key={index} className='assessment-card'>
                            <Checkbox isChecked={assessment.isChecked} onChange={(e) => { handleChange(e, assessment) }} />
                            <p className='m m-10 left'>
                                {assessment.instrument === 102 && 'Personality and Behaviour'}
                                {assessment.instrument === 201 && 'Values and Motivation'}
                                {assessment.instrument === 302 && 'Verbal Reasoning'}
                                {assessment.instrument === 301 && 'Numerical Reasoning'}
                                {assessment.instrument === 344 && 'Logical Reasoning'}
                                {assessment.instrument === 370 && 'English'}
                                {assessment.instrument === 390 && 'Adept'}
                                {assessment.instrument === 357 && assessment.name}
                                {assessment.instrument === 315 && assessment.name}
                                <ExplanationTooltip>
                                    <p>
                                        {assessment.instrument === 102 && contentfulData?.personalityAssessmentInfo}
                                        {assessment.instrument === 201 && contentfulData?.motivationAssessmentInfo}
                                        {assessment.instrument === 302 && contentfulData?.verbalReasoningAssessmentInfo}
                                        {assessment.instrument === 301 && contentfulData?.numericalReasoningAssessmentInfo}
                                        {assessment.instrument === 344 && contentfulData?.logicalReasoningAssessmentInfo}
                                        {assessment.instrument === 370 && contentfulData?.englishAssessmentInfo}
                                        {assessment.instrument === 390 && contentfulData?.adeptAssessmentInfo}
                                        {assessment.instrument === 357 && assessment.name}
                                        {assessment.instrument === 315 && assessment.name}
                                    </p>
                                </ExplanationTooltip>
                            </p>
                        </div>
                    )
                } else {
                    return ""
                }
            })
            }
        </div>
    )
}

export default Assessments
