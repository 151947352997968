import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

function TeamList({teamsData, selectedTeamHash, onSelectedFunction}) {
    const [searchTerm, setSearchTerm] = useState("");

    const [cachedSelectedTeamData, setCachedSelectedTeamData] = useState(null);

    const [loading, setLoading] = React.useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;
    const pagesVisited = pageNumber * itemsPerPage;

    const pageCount = Math.ceil(teamsData.length / itemsPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    const reduxTeamData = useSelector(state => state.teamData.data);
    useEffect(() => {
        setCachedSelectedTeamData(reduxTeamData);
    }, [reduxTeamData]);

    useEffect(() => {
        setLoading(true)
    },[selectedTeamHash])

    useEffect(() => {
        setLoading(false)
    },[cachedSelectedTeamData])

    const filteredTeams = teamsData.filter((team) => {
        if(team.members && team.members.length > 0){
            return team.members.some((member) =>
                member.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }else{
            return team;
        }
    });

    const displayItems = filteredTeams.slice(pagesVisited, pagesVisited + itemsPerPage).map((team, index) => {
        if(selectedTeamHash === team.hash && cachedSelectedTeamData){
            return (
                <button
                    id={cachedSelectedTeamData.id}
                    className={selectedTeamHash === cachedSelectedTeamData.hash ? 'team active' : 'team'}
                    key={index}
                    onClick={()=> {onSelectedFunction(cachedSelectedTeamData.hash)}}
                >
                    <p className="team-title m-0 bottom bold m">{cachedSelectedTeamData.title}</p>

                    <div className="team-count">
                        <p className="light m-0">{cachedSelectedTeamData.memberCount} members</p>
                    </div>
                    {loading && <div>
                        <CircularProgress color="success" size="25px" />
                    </div>}
                </button>
            )
        }else{
            return (
                <button
                    id={team.id}
                    className={selectedTeamHash === team.hash ? 'team active' : 'team'}
                    key={index}
                    onClick={()=> {onSelectedFunction(team.hash, index)}}
                >
                    <p className="team-title m-0 bottom bold m">{team.title}</p>

                    <div className="team-count">
                        <p className="light m-0">{team.memberCount} members</p>
                    </div>
                </button>
            )
        }
    });

    return (
        <div id="top" className="teams-items">
            <AppBar position="static" color="default" sx={{ boxShadow: "none" }}>
            <Toolbar>
                <SearchIcon />
                <InputBase
                    placeholder="Search team member by email"
                    inputProps={{ "aria-label": "search" }}
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Toolbar>
            </AppBar>
            <div className="teams-wrapper">{displayItems}</div>
            {displayItems.length > 1 && (
            <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination"}
                previousLinkClassName={"prevBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                pageClassName={"pagination-item"}
            />
            )}
        </div>
    )
}

export default TeamList;
