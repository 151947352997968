import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  export function MotivationCharts ({ topMotivations, lowMotivations }) {

    const options = {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        }
      },
      scales: {
          x: {
          beginAtZero: true,
          min: 1,
          max: 9,
          ticks: {
            font: { size: 14 },
          },
        },
        y: {
          ticks: {
            font: { size: 14 },
            crossAlign: "far",
          },
        }
      }
    };

    const labels = [];
    let newData = [];
    topMotivations.length > 0 && topMotivations
      .sort((a, b) => b.average - a.average)
      .forEach((data) => {
        labels.push(data.column_name);
        newData.push(data.average);
      });
    lowMotivations.length > 0 && lowMotivations
    .forEach((data) => {
        labels.push(data.column_name);
        newData.push(data.average);
    })


    const data = {
      labels,
      datasets: [
        {
          categoryPercentage: 1.0,
          barPercentage: 1.0,
          barThickness: 15,
          data: newData,
          backgroundColor: ['#00B0A0','#00B0A0','#00B0A0','#00B0A0','#8049B0','#8049B0','#8049B0','#8049B0']
        }
      ],
    };


    return (
      <Bar data={data} options={options} />
    )
  }
