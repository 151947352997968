import {ActionTypes} from "../constants/action-types";

const INITIAL_STATE = {
};

// eslint-disable-next-line
export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case ActionTypes.SET_JOB_POSITION_DATA:
            // check that data does exist
            let data = [];
            if(action && action.payload && action.payload){
                data = action.payload;
            }
            return { ...state, data: data };
        default:
            return state;
    }
}
