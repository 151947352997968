import React, {useEffect, useMemo, useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import * as Constants from "../../constants";
import B2BApiService from "../../B2bApiService";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";

export default function AssignPoolsComp() {
  const b2bServiceObj = useMemo(() => {
    return new B2BApiService();
  }, []);

  const [assignPools, setAssignPools] = useState({});
  const [changedRows, setChangedRows] = useState({});
  const [pools, setPools] = useState([]);
  const [users, setUsers] = useState([]);

  const handleMultiSelectPools = (e, userId) => {
    setAssignPools((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [userId]: e.value,
    }));

    setChangedRows((prevChangedRows) => ({
      ...prevChangedRows,
      [userId]: true,
    }));

  };

  const multiSelectPoolsTemplate = (rowData) => (
    <MultiSelect
      key={rowData?.id}
      value={assignPools[rowData.id] || []}
      options={pools.map((pool) => pool.title)} // Update here
      onChange={(e) => handleMultiSelectPools(e, rowData.id)}
      filter
      maxSelectedLabels={2}
    />
  );

  const saveAssignedPools = (userId) => {
    let poolHashes = assignPools[userId].map(title => {
      const item = pools.find(item => item.title === title);
      return item ? item.id : null;
    });

    b2bServiceObj.UpdateUserAccessToPools(userId, poolHashes,(res) => {
      if(res?.status){
        //@todo : Show toast for pools assigned to user
        setChangedRows((prevChangedRows) => ({
          ...prevChangedRows,
          [userId]: false,
        }));
      }
    })
  }

  const actionTemplate = (rowData) => (
      <IconButton
          style={{ padding: '10px 10px'}}
          onClick={() => saveAssignedPools(rowData.id)}
      >
        <SaveIcon className={changedRows[rowData.id] ? 'icon color-blink' : 'icon'}  style={{fontSize:"30" }}/>
      </IconButton>
  );
  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'multiSelectTeams', header: 'Assigned Pools', body: multiSelectPoolsTemplate },
    { field: 'action', header: 'Actions', body: actionTemplate },
  ];

  useEffect(() => {
    b2bServiceObj.LoadPoolsWithIdAndTitle((data) => {
      setPools(data);
    })

    b2bServiceObj.GetCompanyAccountsByRoleId(Constants.USER_ROLE_TEAMLEAD,(data) => {
      let filteredData = data.filter((user) => user.role_id === Constants.USER_ROLE_TEAMLEAD);

      let transformedData = filteredData.map((user) => ({
        id : user.id,
        name: `${user.first} ${user.last}`,
        email: user.email,
        role: user.role_id,
        access: Constants.getUserRoleStringByRoleId(user.role_id), // Default to "Unknown" if role_id is not found
      }));
      setUsers(transformedData)
    })
  }, [b2bServiceObj]);


  return (
    <div className="user-list-wrapper-div">
      <div className="margin-left-div">
        <div className="user-list-inner-div">
          <DataTable value={users} paginator rows={5} rowsPerPageOptions={[10, 15, 20]}>
            {columns.map((column, i) => (
              <Column key={i} field={column.field} header={column.header} body={column.body} />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
}
