import loading from '../../assets/images/icons/loading.gif';

function Loading() {
    return (
        <div className="loading">
           <img className="gif" src={loading} alt="Loading..." />
        </div>
    )
}

export default Loading;