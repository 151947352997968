import { useSelector } from 'react-redux';
import React, {useMemo, useState} from "react";
import ExplanationTooltip from "../reusableElementsComponents/ExplanationTooltip";
import Accordion from "react-bootstrap/Accordion";
import ScoreLine from "../reusableElementsComponents/ScoreLine";
import * as PropTypes from "prop-types";
import B2BApiService from "../../B2bApiService";
import {toast, ToastContainer} from "react-toastify";

function CandidateCompetences(props) {
    const contentfulDate = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const [competanciesVisible, setCompetanciesVisible] = useState(3);

    const sortCompetencies = () => {
        contentfulDate.targetPositionCompetences.map((object) => {
            return object.value = props.data.competence_points[object.id]
        })
        return contentfulDate.targetPositionCompetences.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
    }

    const showMoreCompetancies = () => {
        setCompetanciesVisible(props.data.competence_points.length);

        document.getElementById('showMoreCompetancies').classList.remove('show', 'flex');
        document.getElementById('showMoreCompetancies').classList.add('hide');
        document.getElementById('showLessCompetancies').classList.add('show', 'flex');
    }

    const showLessCompetancies = () => {
        setCompetanciesVisible(3);

        document.getElementById('showLessCompetancies').classList.remove('show', 'flex');
        document.getElementById('showLessCompetancies').classList.add('hide');
        document.getElementById('showMoreCompetancies').classList.add('show', 'flex');
    }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const sendAssessmentReminder = () => {
        const updateTeamToast = toast.loading(
            "Sending...",
            {containerId: 'candidate-competences'}
        );
        b2bServiceObj.SendAssessmentReminder(props.data.info.id, (status, response) => {
            if (status && response.ok && response.httpStatus === 200) {
                toast.update(updateTeamToast, {
                    render: "Reminder email sent",
                    type: "success",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }else{
                toast.update(updateTeamToast, {
                    render: "Unable to reminder email",
                    type: "error",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }

        })
    }

    return <div className="competences" id="boxStyleComp">
        <ToastContainer containerId="candidate-competences"/>
        <div className="title">
            <p className='m-0'>
                {contentfulDate.competenciesSectionTitle}
                <ExplanationTooltip>
                    {contentfulDate.targetProfileHelpLink}
                </ExplanationTooltip>
            </p>
            <p className='xs grey'>{contentfulDate.verifiedByAon}</p>
        </div>
        <div className="stats">
            <Accordion>
                {props.data && props.data.competence_points.length > 0 ? props.data.competence_points.slice(0, competanciesVisible).map(
                    (point, index) => (
                        sortCompetencies().map((pointCopy, index2) => {
                            if (index === index2) {
                                return (
                                    <Accordion.Item eventKey={index} key={index}>
                                        {index === 0 &&
                                        <Accordion.Header>
                                            <div className="accordion-header-wrapper">
                                                <p className='mar-5 right s'>{pointCopy.title}</p>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between"
                                                    }}>
                                                                        <span style={{
                                                                            fontSize: "10px",
                                                                            color: "grey"
                                                                        }}>Low</span>
                                                        <span style={{
                                                            fontSize: "10px",
                                                            color: "grey"
                                                        }}>Average</span>
                                                        <span style={{
                                                            fontSize: "10px",
                                                            color: "grey"
                                                        }}>High</span>
                                                    </div>
                                                    <ScoreLine val={pointCopy.value}/>
                                                </div>
                                            </div>
                                        </Accordion.Header>}
                                        {index !== 0 &&
                                        <Accordion.Header>
                                            <div className="accordion-header-wrapper">
                                                <p className='mar-5 right s'>{pointCopy.title}</p>
                                                <ScoreLine val={pointCopy.value}/>
                                            </div>
                                        </Accordion.Header>

                                        }
                                        <Accordion.Body>
                                            <div className="description">
                                                <p className="s">{pointCopy.description}</p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            } else return ''
                        })

                    )
                    )
                    :
                    <div className="sendToTests">
                        <p>No competences at the moment</p>
                        <button className="mazhrBtn default-pink" onClick={sendAssessmentReminder}>Send assessments reminder</button>
                    </div>
                }
            </Accordion>
        </div>
        {props.data.competence_points.length > 0 &&
        <>
            <button id="showMoreCompetancies" className='show-more-link link'
                    onClick={showMoreCompetancies}>
                <p className="xs bold">Show more</p>
            </button>
            <button id="showLessCompetancies" className='show-more-link link hide'
                    onClick={showLessCompetancies}>
                <p className="xs bold">Show less</p>
            </button>
        </>
        }
    </div>;
}

CandidateCompetences.propTypes = {
    data: PropTypes.any,
};

export default CandidateCompetences;
