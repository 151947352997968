import { useSelector } from 'react-redux';
import {CircularProgressbar} from "react-circular-progressbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import * as PropTypes from "prop-types";

function TargetPositionComposition(props) {
    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);
    const chartColor = props?.matchPercentage >= 70 ? '#0F9B8F' : props?.matchPercentage >= 40 ? '#FFC247' : '#8049B0'
    return <>
        <div className="circularProgress">
            <CircularProgressbar
                value={props?.matchPercentage}
                text={`${props?.matchPercentage}%`}
                strokeWidth={3}
                styles={{
                    root: {height: "80%"},
                    path: {stroke: chartColor},
                    text: {fill: chartColor, fontSize: "20px"},
                }}
            />
        </div>
        <div style={{width: "100%", padding: "10px"}}>
            <div className="title">
                <p className='m-0'>
                    Target Position Competencies
                </p>
                <p className='xs grey'>{contentfulData?.verifiedByAon}</p>
            </div>
            {
                props.data?.target_position_competencies_distribution.length !== 0 &&
                props.data?.target_position_competencies_distribution.map((obj1) => (
                    contentfulData?.targetPositionCompetences.map((obj2) => {
                        if (obj1.competence_index === (obj2.id)) {
                            return (
                                <div className="progressDiv">
                                    <span style={{width: "35%", textAlign: "left"}}>{obj2?.title}</span>
                                    <ProgressBar style={{
                                        width: "45%",
                                        backgroundColor: 'transparent',
                                        backgroundImage: 'none'
                                    }} now={obj1?.percentage}></ProgressBar>
                                    <span style={{
                                        width: "20%",
                                        textAlign: "right",
                                        color: "#00B0A0"
                                    }}>{`${obj1?.percentage}% Match`}</span>
                                </div>
                            )

                        }
                        return true

                    })
                ))
            }

        </div>
    </>;
}

TargetPositionComposition.propTypes = {
    data: PropTypes.any
};

export default TargetPositionComposition;
