import React from 'react';
import TeamReviewLayoutDesktop from '../../components/teamReviewComponents/TeamReviewLayoutDesktop';

function TeamReview() {
  let openPositionsData = true;

  return (
      <div className="team-review-page">
          {openPositionsData ?
          <div className="team-review-page-wrapper">
              <TeamReviewLayoutDesktop />
              {/*{<TeamReviewLayoutMobile />}*/}
          </div>
          :
          // <NoDataTeamReview />
          'no data'}
      </div>
  )


}

export default TeamReview
