function ScoreLine({val}) {
  return (
    <div id="Folder_A" className='score-line-wrapper'>
        <div className ={`point xxs weak    ${val === 1 && 'active'}`}></div>
        <div className = {`point xs weak    ${val === 2 && 'active'}`}></div>
        <div className = {`point s weak     ${val === 3 && 'active'}`}></div>
        <div className = {`point md medium  ${val === 4 && 'active'}`}></div>
        <div className = {`point lg medium  ${val === 5 && 'active'}`}></div>
        <div className = {`point md medium  ${val === 6 && 'active'}`}></div>
        <div className = {`point s strong   ${val === 7 && 'active'}`}></div>
        <div className = {`point xs strong  ${val === 8 && 'active'}`}></div>
        <div className = {`point xxs strong ${val === 9 && 'active'}`}></div>
    </div>
  )
}

export default ScoreLine




