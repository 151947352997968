import NoDataOpenPositions from '../../components/openPositionsComponents/NoDataOpenPositions';
import OpenPositionLayoutDesktop from '../../components/openPositionsComponents/OpenPositionLayoutDesktop';
// import OpenPositionLayoutMobile from '../../components/openPositionsComponents/OpenPositionLayoutMobile';
import { useEffect, useMemo, useState, memo } from "react";
import B2BApiService from "../../B2bApiService";
import loading from '../../assets/images/icons/loading.gif';
import PageLayout from '../../components/layoutComponents/PageLayout';

function OpenPositions () {
    const [existingPoolsList, setExistingPoolsList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loader, setLoader] = useState(true);


    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    },[]);

    useEffect(() => {
            b2bServiceObj.LoadPoolsWithAllData(setExistingPoolsList,setLoader);
    }, [b2bServiceObj])

    window.onresize = (e) => {
        setWindowWidth(e.target.innerWidth);
    }

    return (
        <>
            {loader &&
                <div className='page-loading'>
                    <img src={loading} alt="Loading..." />
                </div>
            }
            
            {!loader && existingPoolsList.length > 0 &&
                <PageLayout>
                    {windowWidth > 1400 ? <OpenPositionLayoutDesktop existingPoolsList={existingPoolsList} /> :
                        // <OpenPositionLayoutMobile existingPoolsList={existingPoolsList} />
                        <OpenPositionLayoutDesktop existingPoolsList={existingPoolsList} />
                        }
                </PageLayout>
            }
            {!loader && existingPoolsList.length === 0 && <NoDataOpenPositions />}
        </>
    )
}

export default memo(OpenPositions);



