import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
//import mazharLogo from '../../assets/images/logos/mazhr-beta-logo-green.svg'
import mazharLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";
import loading from '../../assets/images/icons/loading.gif';

import { useNavigate } from "react-router-dom";

import Button from '../../components/reusableElementsComponents/Button';
import MobileAuthHeader from '../../components/layoutComponents/authComponents/MobileAuthHeader';
import DesktopAuthDecoration from '../../components/layoutComponents/authComponents/DesktopAuthDecoration';
import Loading from '../../components/reusableElementsComponents/Loading';

import { FormControlLabel, Checkbox, FormControl, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import * as Constants from './../../constants'
import B2BApiService from "../../B2bApiService";

import { inputLabelClasses } from "@mui/material/InputLabel";



function RegistrationStage1() {

    const registerationData = localStorage.getItem(Constants.MAZHR_REG_DATA) ? JSON.parse(localStorage.getItem(Constants.MAZHR_REG_DATA)) : null;
    let defaultValues = {};

    if (registerationData) {
        defaultValues = {
            first: registerationData.first,
            last: registerationData.last,
            email: registerationData.email,
            password: registerationData.password,
            password2: registerationData.password,
            termsconditions: true
        };
    } else {
        defaultValues = {
            first: '',
            last: '',
            email: '',
            password: '',
            password2: '',
            termsconditions: true
        };
    }


    const defaultErrorState = {
        first: false,
        last: false,
        email: false,
        password: false,
        password2: false,
        termsconditions: false
    };

    const defaultErrorMessages = {
        first: "Name should only contain letters",
        last: "Name should only contain letters",
        email: "Email address must include . and @",
        password: "Password must contain at least 8 characters, a capital letter, a number and a special symbol",
        password2: "Password must contain at least 8 characters, a capital letter, a number and a special symbol",
        termsconditions: "Please agree to our Terms of Service and Privacy policy."
    }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const data = useSelector(state => state.contentfulData.all['Company registration page (stage1)']);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(defaultValues)
    const [formErrors, setFormErrors] = useState(defaultErrorState)
    const [formErrorMessages, setFormErrorMessages] = useState(defaultErrorMessages)
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const emailAddressExist = (exist) => {
        if (exist) {
            setFormErrors({ ...formErrors, email: true })
            setFormErrorMessages({ ...formErrorMessages, email: "Email address already exist" })
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });

        if (!value) {
            updateFormErrorsObject(name, true)
        }
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        let valid = false;
        if (value !== undefined && value.length > 0) {
            valid = validateFieldValues(name, value)
        }

        if (valid && name === 'email') {
            b2bServiceObj.EmailExist(value, emailAddressExist)
        }
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
        validateFieldValues(name, checked)
    };

    const updateFormErrorsObject = (name, value) => {
        setFormErrors({
            ...formErrors,
            [name]: value,
        });
    }

    const validateFieldValues = (name, value) => {
        let valid = false;
        switch (name) {
            case 'first':
            case 'last':
                valid = Constants.VALID_NAME.test(value);
                if (!valid) {
                    setError(true);
                }
                updateFormErrorsObject(name, !valid);
                break;
            case 'email':
                valid = Constants.VALID_EMAIL.test(value);
                if (!valid) {
                    setError(true);
                }
                updateFormErrorsObject(name, !valid);
                if (!valid) {
                    setError(true);
                }
                break;
            case 'password':
                valid = Constants.VALID_PASSWORD.test(value);
                if (!valid) {
                    setError(true);
                }
                updateFormErrorsObject(name, !valid);
                break;
            case 'password2':
                valid = Constants.VALID_PASSWORD.test(value);
                if (!valid || formValues.password !== value) {
                    setError(true);
                    updateFormErrorsObject('password2', true)
                    setFormErrorMessages({
                        ...formErrorMessages,
                        "password2": "Passwords must match",
                    })
                } else {
                    updateFormErrorsObject('password2', false)
                }
                break;
            case 'termsconditions':
                valid = value
                updateFormErrorsObject('termsconditions', !value)
                break;
            default:
                break;
        }

        return valid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        let keys = Object.keys(formValues);
        let errorFound = true;
        for (let index = 0; index < keys.length; index++) {
            errorFound = !validateFieldValues(keys[index], formValues[keys[index]]);
            if (errorFound) {
                break;
            }
        }

        if (!errorFound) {
            let regData = JSON.stringify({
                first: formValues.first,
                last: formValues.last,
                email: formValues.email,
                password: formValues.password
            });

            localStorage.setItem(Constants.MAZHR_REG_DATA, regData);
            navigate("/register-2");
        }
    };

    if (data !== undefined) {
        let images = data.images;
        let content = data.fields;

        return (
            <div className="row auth-page-wrapper p-0 m-0">
                <img className='company-logo' src={mazharLogo} alt="Apex Analytics" />
                <MobileAuthHeader logo={true} title={content.titleMobile} subTitle={content.subTitleMobile} />
                <div className="col-12 col-lg-6 login-form">
                    <a href={Constants.MAZHR_APP_URL + "/#register"} className="reg-as-talent">{"<< Register as talent"}</a>
                    <div className="section d-none d-lg-block">
                        <h3 className="title semi-bold">{content.titleDesktop}</h3>
                        <p className="grey s">{content.subTitleDesktop}</p>
                    </div>
                    <form className='form' onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                placeholder="Start typing..."
                                id="first"
                                label="First Name"
                                name="first"
                                type="text"
                                autoComplete="on"
                                value={formValues.first}
                                error={formErrors.first}
                                helperText={formErrors.first && formErrorMessages.first}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br />
                            <TextField
                                InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                placeholder="Start typing..."
                                id="last"
                                label="Last Name"
                                name="last"
                                type="text"
                                autoComplete="on"
                                value={formValues.last}
                                error={formErrors.last}
                                helperText={formErrors.last && formErrorMessages.last}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br />
                            <TextField
                                InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                placeholder="Start typing..."
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="on"
                                value={formValues.email}
                                error={formErrors.email}
                                helperText={formErrors.email && formErrorMessages.email}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br />
                            <TextField
                                InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                placeholder="Start typing..."
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="on"
                                value={formValues.password}
                                error={formErrors.password}
                                helperText={formErrors.password && formErrorMessages.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br />
                            <TextField
                                InputLabelProps={{ sx: { [`&.${inputLabelClasses.shrink}`]: { color: "#979797" } } }}
                                placeholder="Start typing..."
                                id="password2"
                                label="Repeat Password"
                                name="password2"
                                type="password"
                                autoComplete="on"
                                value={formValues.password2}
                                error={formErrors.password2}
                                helperText={formErrors.password2 && formErrorMessages.password2}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        name="termsconditions"
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={<span>{content.termsAndConditions} <a href={Constants.TERMS_CONDITIONS_URL} target="_blank" rel="noreferrer">{content.termsAndConditionsLink}</a></span>}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            />
                            {formErrors.termsconditions &&
                                <div className="input-error">
                                    <p className="m-0">{formErrorMessages.termsconditions}</p>
                                </div>
                            }
                        </FormControl>

                        <div className="authButtons">
                            <Button btnstyle={'mazhrBtn default-green white'} type="submit">
                                {loader && !error ?
                                    <img className='loader' src={loading} alt="Loading..." />
                                    :
                                    <p className='m-0 light'>{content.continueRegistration}</p>
                                }
                            </Button>
                            <Link className="mazhrBtn light-green" to="/login">
                                <p className="m-0 light">{content.signInButton}</p>
                            </Link>
                        </div>
                    </form>
                </div>
                <DesktopAuthDecoration illustration={images.registerIllustration} />
            </div>
        );
    } else {
        return (
            <Loading />
        )
    }
}

export default RegistrationStage1;

