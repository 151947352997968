import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState, useMemo, useCallback,memo } from "react";
import * as Constants from "../../constants";
import * as Utils from '../../Utilities'
import OpenPosition from "./OpenPosition";
import OpenPositionEdit from "./OpenPositionEdit";
import Button from "../reusableElementsComponents/Button";

import filterAZ from '../../assets/images/icons/filterAZ.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import B2BApiService from "../../B2bApiService";
import loading from '../../assets/images/icons/loading.gif';

function OpenPositionLayoutDesktop ({existingPoolsList}) {
    const data = useSelector(state => state.contentfulData.all['edit open position']);
    const positionData = useSelector(state => state.positionData.data);
    let [selectedPositionId, setSelectedPositionId] = useState(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_POSITION_ID) ? localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_POSITION_ID) : 0);
    const [poolList, setPoolList] = useState(existingPoolsList);
    const [loader, setLoader] = useState(false);

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const positionSelected = useCallback((id) => {
        setSelectedPositionId(id);
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SELECTED_POSITION_ID, id);
        b2bServiceObj.LoadPoolByHash(id,null,setLoader);
    }, [b2bServiceObj])


    const onSave = () => {
        b2bServiceObj.LoadPoolsWithAllData(setPoolList,setLoader);
    }

    return (
        <div className="pool-review-desktop">
                {loader &&  <img width="40" height="40" src={loading} alt="Loading..." /> }
                <div className="add-pool">
                    {Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <Button btnstyle={'mazhrBtn default-green'} >
                        <Link to="/new-open-position"><AddCircleOutlineIcon></AddCircleOutlineIcon> &nbsp; Add new open position</Link>
                    </Button>}
                </div>

                <div className="open-position-filters">
                    <div className="filter p-0 positions">
                        <p className="filter-title p-0 xs bold">Open positions</p>
                        <img src={filterAZ} alt="filter a-z" />
                    </div>
                    <div className="filter p-0 candidates">
                        <p className="filter-title p-0 xs bold">Registered Candidates</p>
                        <img src={filterAZ} alt="filter a-z" />
                    </div>
                    {/*<div className="filter p-0">
                        <p className="filter-title p-0 xs bold">Assessments taken</p>
                        <img src={filterAZ} alt="filter a-z" />
                    </div>*/}
                    <div className="filter p-0 deadline">
                        <p className="filter-title p-0 xs bold">Deadline</p>
                        <img src={filterAZ} alt="filter a-z" />
                    </div>
                    <div className="spacer"></div>
                </div>
                <div className="open-positions p-0">
                    {selectedPositionId === 0 ?
                        <OpenPosition poolList={poolList} selectedPositionId={poolList[0].hash} onSelectedFunction={positionSelected} />
                    :
                        <OpenPosition poolList={poolList} selectedPositionId={selectedPositionId} onSelectedFunction={positionSelected} />
                    }

                    <div className="edit-field">
                        {positionData && <OpenPositionEdit data={data} positionData={positionData} onSave={onSave} />}
                    </div>
                </div>
        </div>
    )
}

export default memo(OpenPositionLayoutDesktop);
