import React, { useRef} from 'react';
import { Toast } from 'primereact/toast';
import Chip from "@mui/material/Chip";
import speechBubble from "../../assets/images/icons/speechBubbleLightGrey.svg";
import copyIcon from "../../assets/images/icons/copyIcon.png";
import {useSelector} from "react-redux";

// NameCell component

const NameCell = ({ id, first, last, city, country, match_percentage }) => {

    return (
        <div className='talent-item'>
            <div className="initials">
            <div className={"initials-checkbox " + (match_percentage >= 70 ? 'green' : match_percentage >= 40 ? 'yellow' : 'purple')}>
                <p className='m-0'>
                    {first[0]?.toUpperCase()}{last[0]?.toUpperCase()}
                </p>
            </div>
        </div>
        <div className="basic-info m-0">
            <div className="name-location">
                <p className="bold m-0 m">{first} {last}</p>
                <p className='m-0'>
                    {city !== '' && <span>{city + ','}</span>}
                    {country !== '' && <span>{country}</span>}
                </p>
            </div>
        </div>
        </div>
    );
};

export default NameCell;


// MatchCell component
const MatchCell = ({ match_percentage }) => (
    <div className="match">
        <p className={"m-0 " + (match_percentage >= 70 ? 'green' : match_percentage >= 40 ? 'yellow' : 'purple')}>{(match_percentage !== 0 && match_percentage !== undefined) ? `${match_percentage}% match` : null}</p>
    </div>
);

// StatusCell component
const StatusCell = ({ pending_invite, hired, team_invite_sent }) => {
    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);
    const getStatus = () => {
        if (pending_invite) return contentfulData?.tags.pending_invite;
        if (hired) return contentfulData?.tags.employee;
        if (team_invite_sent) return contentfulData?.tags.team_invite_sent;
        return "";
    };

    return (
        <div className="actions">
            {getStatus() !== "" && <span className="invite-pending">
                 &nbsp;<Chip label={getStatus()} size="small" color={pending_invite ? "warning" : hired ? "success" : "secondary"} />
            </span>}
        </div>

    )
};

// ActionsCell component
const ActionsCell = ({ email }) => {

    const handleClick = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Email Copied to clipboard!', life: 500 });
        let requiredEmail = email
        navigator.clipboard.writeText(requiredEmail)
        window.Clipboard = email
    }
    const toast = useRef(null);
    return (
        <div className="actions">
        <a href={"mailto:" + email}><img src={speechBubble} alt="" /></a>
        <img className='copy-icon' id='copyEmail' src={copyIcon} onClick={handleClick} alt="" />
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
        </div>
    </div>
    )

}



export const tableColumns = [

    {
        field: 'fullName',
        headerName: 'Name',
        minWidth: 230,
        cellClassName: 'initials basic-info',
        valueGetter: (params) =>
            `${params.row.first || ''} ${params.row.last || ''}`,
        renderCell: (params) =>
        <>
                <NameCell {...params.row} />
        </>

    },
    {
        field: 'match_percentage',
        headerName: 'Match',
        type: 'number',
        minWidth: 130,
        renderCell: (params) => <MatchCell {...params.row} />
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 120,
        renderCell: (params) => <StatusCell {...params.row} />
    },
    {
        field: '',
        headerName: 'Actions',
        minWidth: 20,
        renderCell: (params) => <ActionsCell {...params.row} />
    },
];
