//import bullet from '../../assets/images/placeholders/bulletpoint.svg';
import Button from '../../components/reusableElementsComponents/Button';
import HomeQuickStart from '../../components/homeComponents/HomeQuickStart';
import { useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import {useSelector} from "react-redux";
import LoomEmbed from './../../Loomed';
import * as Constants from "../../constants";


function Onboarding() {
    const companyIsActive = useSelector(state => state.company.isActive);
    //const loomSrc = 'https://www.loom.com/embed/910cd3f86abb4c98bbc2bcf721a1d5ab?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
    const loomSrc = 'https://www.loom.com/embed/1d9e9a3c680a4eb989d56274870edf39?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
    const location = useLocation();
    const currentURL = location.pathname;
    const userRole = parseInt(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_USER_ROLE_ID))

    return (
        <div className='page-with-notification'>
            <div className="notification">
                <Box>
                    <Collapse in={!companyIsActive}>
                        <Alert
                            className='inactive-alert'
                            variant="standard"
                            severity="info"

                            sx={{mb: 2}}
                        >
                            <strong>Thank you for successfully registering your corporate account. We will contact you
                                within 1-2 business day via phone or email for a demo and the activation of your
                                account.</strong>
                        </Alert>
                    </Collapse>
                </Box>
            </div>

            {currentURL !== "/faq-page" ? (            <div className='onboarding-page'>
                <div className="onboarding-page-wrapper">

                    <div className="copy">
                        <h3>How to use Apex Analytics?</h3>
                        <LoomEmbed src={loomSrc} width={640} height={360}/>
                    </div>

                    {/*<div className="steps-wrapper">
                    <div className="step">
                        <div className="number-wrapper">
                            <h3 className='number mar-2 bottom dark-green'>1</h3>
                            <img src={bullet} alt="1" />
                        </div>
                        <div className="text">
                            <p className="title m">Review your <span className="bold">current team or organization</span></p>
                            <p className="description m">
                                Understand your team dynamics and match your future talent to the team. If you miss relevant competences in your team, the team work might not roll smoothly. Right team members make the difference!
                            </p>
                        </div>
                    </div>
                    <div className="step">
                    <div className="number-wrapper">
                            <h3 className='number mar-2 bottom dark-green'>2</h3>
                            <img src={bullet} alt="2" />
                        </div>
                        <div className="text">
                            <p className="title m">Review your <span className="bold">sales team competencies</span></p>
                            <p className="description m">
                                Did you know that investing in the right people in your sales team boosts sales twice compared to investing in the right data based sales technology? (20 % vs. 5-10 %).With Mazhr Sales Team Analytics you can boost your sales by having an efficient and balanced sales team and recruit new salespersons to match the weak spots or duplicate your most successfull team members.
                            </p>
                        </div>
                    </div>
                    <div className="step">
                    <div className="number-wrapper">
                            <h3 className='number mar-5 bottom dark-green'>3</h3>
                            <img src={bullet} alt="3" />
                        </div>
                        <div className="text">
                            <p className="title m"><span className="bold">Open a new position</span> and invite candidates to apply</p>
                            <p className="description m">
                                Looking for a talent for your competence need? Looking to duplicate a well performing candidate? Open a new position where candidates can apply to your organization pool. Invite the candidates to take the assessments.
                            </p>
                        </div>
                    </div>
                    <div className="step">
                    <div className="number-wrapper">
                            <h3 className='number mar-7 bottom dark-green'>4</h3>
                            <img src={bullet} alt="4" />
                        </div>
                        <div className="text">
                            <p className="title m"><span className="bold">Search matching talent</span> Search matching talent from the candidates</p>
                            <p className="description m">
                                You'll get a view of all the candidates who have applied. Easy and fast screening based on the competencies and values. See also if the talent can be found within the organization.
                            </p>
                        </div>
                    </div>
                </div>*/}

                    <div className="button-wrapper">

                        <Button btnstyle={'mazhrBtn default-green'}>
                            <Link to="/help-center">Any questions? Contact us.</Link>
                        </Button>
                    </div>

                    {/* <div className="copy">
                        <h3>Why trust our assessments?</h3>
                        <p className="m">
                            We have designed and developed Mazhr based on the online assessments by world-leading
                            recruitment and human resource development company AON. Their high quality tools are built
                            on a solid foundation of research and psychometrics. AON handles over 30 million talent
                            assessments each year. AON career assessments are used in more than 90 countries and in 40
                            languages.
                        </p>
                    </div> */}
                </div>
                {userRole !== Constants.USER_ROLE_SUPERVISOR && userRole !== Constants.USER_ROLE_TEAMLEAD && <HomeQuickStart/>}
            </div>): (
                <div className='onboarding-page-help-center'>
                <div className='onboarding-page-wrapper'>
                    <div className='copy'>
                    <LoomEmbed src={loomSrc} width={640} height={360}/>
                    </div>

                </div>
                </div>

            )}
        </div>
    )
}

export default Onboarding;
