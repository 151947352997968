import { useState } from 'react';
//import { BsChevronDoubleLeft } from 'react-icons/bs';

function DropdownOptions ({ dropdownTemplate, defaultValue, onSelectOption }) {

    // eslint-disable-next-line
    const [ option, setOption ] = useState(defaultValue);

    const handleChange = (e) => {
      //var lang = e.target.value;
    }


    const renderFields = ( dropdownTemplate ) => {
        return dropdownTemplate.map((dropdownField, index) => {
            let {title} = dropdownField;

            return (
                <option
                    key={index}
                    value={defaultValue}
                    onClick={ handleChange }
                >
                    {title}
                </option>
            )
        })
    }

  return (
    <>
        { renderFields(dropdownTemplate) }
    </>
  )
}

export default DropdownOptions;

